import Phaser from "phaser";
import {preload} from "./preload"
import {update} from "./update"
import {gameStore} from "./store";
import store from "../store/store";
import {cacheBars} from "./interface/status_layer";
import {resetEquipBarState} from "./interface/equip_bar";
import {battleStateReset} from "./interface/battle_state";
import {siegeStateReset} from "./interface/siege_state";
import OutlinePipelinePlugin from 'phaser3-rex-plugins/plugins/outlinepipeline-plugin.js';
import EaseMovePlugin from './plugins/easemove-plugin.js';
import RotateToPlugin from 'phaser3-rex-plugins/plugins/rotateto-plugin.js';
import DropShadowPipelinePlugin from 'phaser3-rex-plugins/plugins/dropshadowpipeline-plugin.js';
import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin.js';
import EffectPropertiesPlugin from 'phaser3-rex-plugins/plugins/effectproperties-plugin.js';
import {drawFrameAndBackground, SetMinMaxZoom} from "./interface/mini_map_input";

let Game = {};
let Scene = null;

const maxWidth = 1920
const maxHeight = 1080

function calculateNewResolution() {

  let inputWidth = window.innerWidth * window.devicePixelRatio;
  let inputHeight = window.innerHeight * window.devicePixelRatio;

  // Определяем соотношение сторон входного разрешения
  let inputAspectRatio = inputWidth / inputHeight;

  // Определяем соотношение сторон максимального разрешения
  let maxAspectRatio = maxWidth / maxHeight;

  // Если соотношение сторон одинаковое, то коэффициент k равен 1
  let k = 1;
  if (inputAspectRatio !== maxAspectRatio) {
    // В противном случае вычисляем коэффициент изменения разрешения
    k = Math.min(maxWidth / inputWidth, maxHeight / inputHeight);
  }

  // Вычисляем новое разрешение экрана
  let newWidth = Math.round(inputWidth * k);
  let newHeight = Math.round(inputHeight * k);

  SetMinMaxZoom(inputWidth / maxWidth)
  return {
    width: inputWidth < newWidth ? inputWidth : newWidth,
    height: inputHeight < newHeight ? inputHeight : newHeight,
    realWidth: inputWidth,
    realHeight: inputHeight,
  };
}

function CreateGame() {
  try {
    if (!gameStore.gameInit) {

      if (!gameStore.mapEditor) {
        store.commit({
          type: 'setVisibleLoader',
          visible: true,
          text: {
            'RU': 'Загружаем картинки...',
            'EN': 'We are uploading pictures...',
          }
        });
      }

      gameStore.gameInit = true;

      let resolution = calculateNewResolution()
      console.log(resolution)

      let cv = document.createElement('canvas')
      let gl2 = cv.getContext('webgl')
      let lightsCount = (Math.floor(gl2.getParameter(gl2.MAX_FRAGMENT_UNIFORM_VECTORS) / 5)) - 5
      if (lightsCount < 10) lightsCount = 10
      console.log('mv', gl2.getParameter(gl2.MAX_FRAGMENT_UNIFORM_VECTORS), 'lc', lightsCount)

      let config = {
        type: Phaser.WEBGL,
        scene: {
          preload: preload,
          create: create,
          update: update
        },
        scale: {
          // autoRound: true,
          mode: Phaser.Scale.RESIZE,
          width: resolution.width,
          height: resolution.height,
          parent: 'game-container',
          canvas: cv,
          min: {
            width: 0,
            height: 0,
          },
          max: {
            width: 9999,
            height: 9999,
          },
        },
        render: {
          //pixelArt: true,
          antialias: true,
          antialiasGL: true,
          //desynchronized: false,
          batchSize: 512,//256,
          roundPixels: false,
          clearBeforeRender: false,
          //failIfMajorPerformanceCaveat: true,
          powerPreference: "high-performance",
          //mipmapFilter: 'LINEAR_MIPMAP_LINEAR',
          maxLights: lightsCount
        },
        disablePreFX: false,
        disablePostFX: false,
        fps: {},
        physics: {
          default: false  // no physics system enabled
        },
        // audio: {
        //   disableWebAudio: false
        // }
        plugins: {
          global: [
            {
              key: 'rexoutlinepipelineplugin',
              plugin: OutlinePipelinePlugin,
              start: true
            },
            {
              key: 'rexeasemoveplugin',
              plugin: EaseMovePlugin,
              start: true
            },
            {
              key: 'rexrotatetoplugin',
              plugin: RotateToPlugin,
              start: true
            },
            {
              key: 'rexdropshadowpipelineplugin',
              plugin: DropShadowPipelinePlugin,
              start: true
            },
            {
              key: 'rexvirtualjoystickplugin',
              plugin: VirtualJoystickPlugin,
              start: true
            },
            {
              key: 'rexeffectpropertiesplugin',
              plugin: EffectPropertiesPlugin,
              start: true
            }
          ]
        }
      };

      Game = new Phaser.Game(config);
    } else {
      gameStore.noLoader = false;
      gameStore.gameReady = false;
      gameStore.unitReady = false;
    }

    gameStore.reload = false
  } catch (e) {
    store.commit({
      type: 'addSystemError',
      error: e + '<br>' + e.stack,
    });

    throw e
  }
}

function create() {
  try {
    Scene = this;

    // параметры смещения тени игры
    this.shadowXOffset = 6;
    this.shadowYOffset = 8;

    // воспроизведение звука даже в неактивной вкладке
    this.sound.pauseOnBlur = false;

    this.cameras.main.setBackgroundColor('#000000');
    this.input.topOnly = false; // нажатия по всем обьектам под мышкой

    let resolution = calculateNewResolution()
    Scene.scale.setParentSize(resolution.width, resolution.height);

    this.scale.on('resize', function (gameSize, baseSize, displaySize, previousWidth, previousHeight) {
      let resolution = calculateNewResolution()
      if (!Scene.scale.OldValue || resolution.realWidth !== Scene.scale.OldValue.realWidth || resolution.realHeight !== Scene.scale.OldValue.realHeight) {

        Scene.scale.OldValue = resolution;
        Scene.scale.setGameSize(resolution.width, resolution.height);
        Scene.scale.setParentSize(resolution.width, resolution.height);

        resetEquipBarState();
        battleStateReset();
        siegeStateReset();
        drawFrameAndBackground();

        store.commit({
          type: 'addCheckViewPort',
        })
      }
    });

    this.wasd = this.input.keyboard.addKeys({
      up: Phaser.Input.Keyboard.KeyCodes.W,
      up2: Phaser.Input.Keyboard.KeyCodes.UP,
      down: Phaser.Input.Keyboard.KeyCodes.S,
      down2: Phaser.Input.Keyboard.KeyCodes.DOWN,
      left: Phaser.Input.Keyboard.KeyCodes.A,
      left2: Phaser.Input.Keyboard.KeyCodes.LEFT,
      right: Phaser.Input.Keyboard.KeyCodes.D,
      right2: Phaser.Input.Keyboard.KeyCodes.RIGHT,
      sp: Phaser.Input.Keyboard.KeyCodes.SPACE,
      st: Phaser.Input.Keyboard.KeyCodes.SHIFT,
      z: Phaser.Input.Keyboard.KeyCodes.Z,
      ctrl: Phaser.Input.Keyboard.KeyCodes.CTRL,
      i: Phaser.Input.Keyboard.KeyCodes.I,
      c: Phaser.Input.Keyboard.KeyCodes.C,
      m: Phaser.Input.Keyboard.KeyCodes.M,
      l: Phaser.Input.Keyboard.KeyCodes.L,
    }, false, false);

    this.input.keyboard.on('keydown-R', function () {
      if (gameStore.gameReady && !gameStore.HoldAttackMouse && !gameStore.MouseMoveInterface && !gameStore.HoldKeys) reloadWeapon()
    });

    this.input.keyboard.on('keydown-E', function () {
      if (gameStore.gameReady && !gameStore.HoldAttackMouse && !gameStore.MouseMoveInterface && !gameStore.HoldKeys) selectEquipSlot(1)
    });

    this.input.keyboard.on('keydown-F', function () {
      if (gameStore.gameReady && !gameStore.HoldAttackMouse && !gameStore.MouseMoveInterface && !gameStore.HoldKeys) selectEquipSlot(2)
    });

    this.input.keyboard.on('keydown-Q', function () {
      if (gameStore.gameReady && !gameStore.HoldAttackMouse && !gameStore.MouseMoveInterface && !gameStore.HoldKeys) selectEquipSlot(3)
    });

    this.input.keyboard.on('keydown-L', function () {
      if (gameStore.HoldKeys) return;

      store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "ChangeLights",
        }
      }));
    });

    this.input.keyboard.on('keydown-I', function () {
      if (gameStore.HoldKeys) return;

      store.commit({
        type: 'toggleWindow',
        id: 'wrapperInventoryAndStorage',
      });
    });

    this.input.keyboard.on('keydown-C', function () {
      if (gameStore.HoldKeys) return;

      store.commit({
        type: 'toggleWindow',
        id: 'userStat' + store.getters.getPlayer.id,
        component: 'userStat',
        meta: {id: store.getters.getPlayer.id},
      });
    });

    this.input.keyboard.on('keydown-M', function () {
      if (gameStore.HoldKeys) return;

      store.commit({
        type: 'toggleWindow',
        id: 'GlobalViewMap',
      });
    });

    let numbers = [
      {num: 1, key: Phaser.Input.Keyboard.KeyCodes.ONE},
      {num: 2, key: Phaser.Input.Keyboard.KeyCodes.TWO},
      {num: 3, key: Phaser.Input.Keyboard.KeyCodes.THREE},
      {num: 4, key: Phaser.Input.Keyboard.KeyCodes.FOUR},
      {num: 5, key: Phaser.Input.Keyboard.KeyCodes.FIVE},
      {num: 6, key: Phaser.Input.Keyboard.KeyCodes.SIX},
      {num: 7, key: Phaser.Input.Keyboard.KeyCodes.SEVEN},
      {num: 8, key: Phaser.Input.Keyboard.KeyCodes.EIGHT},
      {num: 9, key: Phaser.Input.Keyboard.KeyCodes.NINE},
    ]

    for (let key of numbers) {
      let keyObj = this.input.keyboard.addKey(key.key, false);
      keyObj.on('down', function (event) {
        if (gameStore.HoldKeys) return;

        if (gameStore.gameReady && !gameStore.HoldAttackMouse && !gameStore.MouseMoveInterface) {
          selectEquipSlot(key.num - 1)
        }
      });
    }

    createSelectSprite(this);

    // подчищаем список т.к. метод destroy() почему отуда их не удаляет и в итоге разрастается до бесконечности
    Scene.time.addEvent({
      delay: 1000,
      callback: function () {
        clear()
      },
      loop: true
    });

    cacheBars()
  } catch (e) {

    store.commit({
      type: 'addSystemError',
      error: e + '<br>' + e.stack,
    });

    throw e
  }
}

function selectEquipSlot(slot) {
  store.dispatch('playSound', {
    sound: 'button_press.mp3',
    k: 0.2,
  });

  store.dispatch("sendSocketData", JSON.stringify({
    service: "global",
    data: {
      event: "ses",
      slot: Number(slot),
    }
  }));
}

function reloadWeapon() {
  store.dispatch("sendSocketData", JSON.stringify({
    service: "global",
    data: {
      event: "rw",
    }
  }));
}

function clear() {
  for (let obj of Scene.children.systems.updateList._active) {
    if (!obj.active) Scene.children.systems.updateList.remove(obj)
  }
}

function createSelectSprite(scene) {

  let graphics = scene.add.graphics();
  graphics.setDefaultStyles({
    lineStyle: {
      width: 12,
      color: 0xFFFFFF,
      alpha: 0.5
    },
    fillStyle: {
      color: 0xFFFFFF,
      alpha: 0.1
    }
  });

  let circle = {x: 312, y: 312, radius: 300};
  graphics.fillCircleShape(circle);
  graphics.strokeCircleShape(circle);
  graphics.generateTexture("select_sprite", 624, 624);
  graphics.destroy();

  let select_rect = scene.add.graphics();
  select_rect.setDefaultStyles({
    lineStyle: {
      width: 6,
      color: 0xFFFFFF,
      alpha: 0.5
    },
    fillStyle: {
      color: 0xFFFFFF,
      alpha: 0.1
    }
  });

  select_rect.fillRoundedRect(0, 0, 128, 128, 16);
  select_rect.strokeRoundedRect(3, 3, 122, 122, 16);
  select_rect.generateTexture("select_rect", 128, 128);
  select_rect.destroy();

  let deniedSprite = scene.add.graphics();
  deniedSprite.setDefaultStyles({
    lineStyle: {
      width: 60,
      color: 0xFF0000,
      alpha: 0.8
    },
    fillStyle: {
      color: 0x000000,
      alpha: 0.3
    }
  });

  let deniedCircle = {x: 300, y: 300, radius: 300 - 60};
  deniedSprite.fillCircleShape(deniedCircle);
  deniedSprite.strokeCircleShape(deniedCircle);
  deniedSprite.lineBetween(100, 400, 500, 200);
  deniedSprite.generateTexture("denied_rect", 600, 600);
  deniedSprite.destroy();
}

export {CreateGame, Game, Scene, selectEquipSlot}
