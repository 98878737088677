<template>
  <div class="gate" id="gateBlock" ref="gateBlock" v-if="texts">

    <div class="gateBlock">

      <div class="language">
        <div :class="{disable_language: settings.Language !=='RU'}"
             class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeLanguage('RU')"/>
        <div class="EN cursor_hover" :class="{disable_language: settings.Language !=='EN'}"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeLanguage('EN')"/>
      </div>

      <app-control v-bind:head="texts['window_name'][lang]"
                   v-bind:move="false"
                   v-bind:close="false"
                   v-bind:no-pos="true"
                   v-bind:no-width="true"
                   v-bind:no-height="true"
                   v-bind:refWindow="'gateBlock'"
                   v-bind:texture="'none'"/>

      <div v-if="!checkCardName()" v-html="texts['attention_1'][lang]"></div>

      <div v-if="lang === 'RU'">
        <h3 class="not_card_notify">Внимание!</h3>
        <p class="not_card_notify_text">Игра не является законченной и поэтому в ней могут быть баги, лаги недоработки и
          тд.</p>
      </div>

      <div v-if="lang === 'EN'">
        <h3 class="not_card_notify">Attention!</h3>
        <p class="not_card_notify_text">The game is not complete and, therefore, it may contain bugs, lags,
          imperfections, etc.</p>
      </div>

      <template v-if="selectGameMode===''">
        <div class="entryButton cursor_hover" id="world"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="selectMode('open_world')">
          {{ texts['play'][lang] }}
        </div>

        <div v-if="steam.steam" class="entryButton cursor_hover" id="world"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="close">
          {{ texts['close'][lang] }}
        </div>

        <div class="entryButton cursor_hover" id="mapEditor" style="margin-top: 15px"
             v-if="currentPlayer.user_role === 'admin'" @click="to('/map_editor')">
          <span>Map Editor</span>
        </div>

        <div class="entryButton cursor_hover" id="dialogEditor" v-if="currentPlayer.user_role === 'admin'"
             @click="to('/dialog_editor')">
          <span>Dialog Editor</span>
        </div>

        <div class="entryButton cursor_hover" id="missionEditor" v-if="currentPlayer.user_role === 'admin'"
             @click="to('/mission_editor')">
          <span>Mission Editor</span>
        </div>

        <div class="entryButton cursor_hover" v-if="currentPlayer.user_role === 'admin'" @click="to('/server_state')">
          <span>Server State</span>
        </div>
      </template>

      <app-open-world v-if="selectGameMode==='open_world'"/>
      <div class="Failed" id="error"
           v-if="createPlayerLarchName || createPlayerNameNotAvailable || createPlayerSmallName">
        {{ createPlayerLarchName ? texts['createPlayerLarchName'][lang] : '' }}
        {{ createPlayerNameNotAvailable ? texts['createPlayerNameNotAvailable'][lang] : '' }}
        {{ createPlayerSmallName ? texts['createPlayerSmallName'][lang] : '' }}
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import UserLine from '../Chat/UserLine';
import OpenWorld from './OpenWorld';
import {gameStore} from "../../game/store";

export default {
  name: "Gate",
  data() {
    return {
      selectGameMode: '',
      error: '',
    }
  },
  created() {
    this.$store.commit({
      type: 'setVisibleLoader',
      visible: true,
      text: {
        'RU': '<span>Получаем информацию...</span>',
        'EN': '<span>We are gathering information...</span>',
      }
    });
  },
  mounted() {
    this.getPlayers();
    gameStore.reload = true;
    gameStore.unitReady = false;
    gameStore.gameReady = false;
    gameStore.gameDataInit.sendRequest = false;
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    close() {
      window.close()
    },
    getPlayers() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetPlayers",
        },
      }))
    },
    changeLanguage(language) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "SetLanguage",
          data: language,
        }
      }));
    },
    to(url) {
      let app = this;

      if (url === '/lobby') {
        app.$store.commit({
          type: 'setVisibleLoader',
          visible: true,
          text: {
            'RU': 'Пытаемся понять что происходит...',
            'EN': 'We are trying to figure out what is going on...',
          }
        });
      }

      setTimeout(function () {
        app.$router.push({path: url});
      }, 1000);
    },
    selectMode(mode) {
      this.playSound('button_press.mp3', 0.3)
      this.selectGameMode = mode;
    },
    checkCardName() {
      let canvas = document.createElement("canvas");
      let gl = canvas.getContext("experimental-webgl") || canvas.getContext("webgl");

      if (!gl) {
        return false;
      }

      let ext = gl.getExtension("WEBGL_debug_renderer_info");
      if (!ext) {
        return false;
      }

      let valid = ["amd", "nvidia", "intel", "ati"]
      let cardName = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL)
      canvas.remove()

      for (let name of valid) {
        if (cardName.toLowerCase().includes(name)) return true;
      }

      return false;
    },
  },
  computed: {
    currentPlayer() {
      let currentPlayer = this.$store.getters.getGameUser

      if (currentPlayer.hasOwnProperty('id')) {
        this.$store.commit({
          type: 'setVisibleLoader',
          visible: false,
        });
      }

      return currentPlayer
    },
    createPlayerLarchName() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('create_player_large_name') && errors['create_player_large_name'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'create_player_large_name',
            active: false,
          });
        }.bind(this), 5000)

        return true
      }

      return false
    },
    createPlayerSmallName() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('create_player_small_name') && errors['create_player_small_name'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'create_player_small_name',
            active: false,
          });
        }.bind(this), 5000)

        return true
      }

      return false
    },
    createPlayerNameNotAvailable() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('create_player_name_not_available') && errors['create_player_name_not_available'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'create_player_name_not_available',
            active: false,
          });
        }.bind(this), 5000)

        return true
      }

      return false
    },
    settings() {
      return this.$store.getters.getSettings
    },
    lang() {
      return this.settings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Gate')
    },
    steam() {
      return this.$store.getters.getSteam
    }
  },
  components: {
    AppControl: Control,
    AppUserLine: UserLine,
    AppOpenWorld: OpenWorld,
  }
}
</script>

<style scoped>
.gate {
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #7f7f7f;
  background-image: url('../../assets/bases/base.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  pointer-events: auto;
}

.gateBlock {
  position: fixed;
  left: calc(50% - 123px);
  top: 20%;
  display: block;
  border-radius: 5px 5px 0 5px;
  width: 246px;
  min-height: 30px;
  z-index: 11;
  padding: 22px 4px 4px 4px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

.entryButton {
  height: 20px;
  width: calc(100% - 4px);
  margin: 6px 2px 2px 2px;
  border-radius: 5px;
  color: white;
  line-height: 20px;
  box-shadow: 0 0 2px black;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.75);
}

.entryButton:hover {
  background: rgba(255, 129, 0, 1);
}

.entryButton:active {
  transform: scale(0.98);
}

#battle {
  background-image: url("../../assets/indexImg/gate2.jpg");

}

.disable {
  filter: grayscale(1);
  pointer-events: none;
}

.language {
  position: absolute;
  left: calc(100% + 2px);
  box-shadow: 0 0 2px 0 black;
  border-radius: 0 2px 2px 0;
  top: 17px;
  border: 1px solid #009aeb7d;
  border-left: 0;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding-left: 3px;
  border-left: none;
}

.language div {
  height: 16px;
  width: 16px;
  background-size: cover;
  filter: drop-shadow(0 0 1px black);
  background-image: url('../../assets/icons/RU_language.png');
  border-radius: 3px;
  margin: 2px 2px 2px 0;
}

.language div.EN {
  background-image: url('../../assets/icons/EN_language.png');
}

.language .disable_language {
  filter: grayscale(75%);
  opacity: 0.75;
}

.language .disable_language:hover {
  filter: drop-shadow(0 0 1px white) grayscale(0%);
  opacity: 1;
}

.Failed {
  position: absolute;
  top: calc(100% + 1px);
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
  margin-top: 15px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.3);
}
</style>

<style>

.not_card_notify {
  margin: -2px 0;
  background: #dd7034;
  color: hsla(0, 0%, 100%, .8);
  font-size: 23px;
  line-height: 28px;
  height: 25px;
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
  padding-left: 10px;
  text-align: left;
  clear: both;
}

.not_card_notify_text {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}

.not_card_notify_text_link {
  color: #0cfdff;
  text-decoration: none;
}

.not_card_notify_text_link:hover {
  text-decoration: underline;
  color: #ffc588;
}

.gateBlock .chatUserLine {
  background: rgba(250, 235, 215, 0.28);
  text-align: left;
}

.gateBlock .chatUserLine .chatUserIcon {
  height: 40px;
  width: 40px;
}

.gateBlock .chatUserLine .chatUserName {
  line-height: 30px;
  font-size: 20px;
}
</style>
