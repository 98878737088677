import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";
import {getShieldByUUID} from "../shield/shield";
import store from "../../store/store";
import {ShakeCamera} from "../interface/shake_camera";
import {unitShieldAnimate} from "../equip/body_shield";
import {addIgnoreObject} from "../interface/mini_map";

let unitSprites = {};
const time_out_tick = 100;
const damage_time = 1500;
let lastDamage = null;

function damageText(data) {
  if (!gameStore.gameReady || data.damage === 0) return;

  let ownerSprite = getDamageSprite(data);
  if (!ownerSprite) {
    // positionDamage(data)
    return;
  }

  let key = data.dealerType + data.dealerID + data.ownerType + data.ownerID;
  if (data.damage < 0) {
    key += "heal"
    data.ownerType = "heal"
  } else if (data.ownerType === "unit" && data.ownerID === gameStore.playerUnitID) {
    let u = gameStore.units[gameStore.playerUnitID]
    if (u && !u.sprite.visible) {
      store.commit({
        type: 'setDamage',
        d: true,
      });
      ShakeCamera(25)

      if (lastDamage) {
        lastDamage.remove();
      }

      lastDamage = Scene.time.addEvent({
        delay: 2000,
        repeat: 1,
        callback: function () {
          store.commit({
            type: 'setDamage',
            d: false,
          });
        },
        loop: false
      });
    }
  }

  if (!unitSprites[key]) {
    unitSprites[key] = {
      sumDamage: data.damage,
      sprite: null,
      time_out: damage_time,
      x_offset: randomInt(-15, 15),
      y_offset: randomInt(-15, 15),
      time_out_checker: Scene.time.addEvent({
        delay: time_out_tick,
        loop: true,
        callback: function () {

          if (!unitSprites[key]) {
            return
          }

          unitSprites[key].time_out -= time_out_tick
          if (unitSprites[key].time_out < 0) {
            unitSprites[key].time_out_checker.remove();
            unitSprites[key].sprite.destroy();
            ownerSprite.damageTextSprite = null
            delete unitSprites[key]
          }
        }
      })
    }
  } else {
    unitSprites[key].time_out = damage_time
    unitSprites[key].sumDamage += data.damage
  }

  if (data.unitShield) {
    unitShieldAnimate(data.x, data.y, data.area, ownerSprite, data.destroyShield)
  }

  let damageState = unitSprites[key]
  if (damageState.sprite) damageState.sprite.destroy();
  if (damageState.damageTypeSprite) damageState.damageTypeSprite.destroy();

  let damageText = damageState.sumDamage
  if (data.ownerType === "heal") {
    damageText = "+" + (damageState.sumDamage * -1)
  }

  damageState.sprite = createTextSprite(ownerSprite.x + damageState.x_offset, ownerSprite.y + damageState.y_offset,
    damageText, data.ownerType, data.dealerType, data.ownerID, data.dealerID, data.unitShield)

  if (data.damageK !== 0) {
    data.damageK = data.damageK / 100;

    let name = 'critical_damage';
    if (data.damageK < 1) name = 'block_damage';

    damageState.damageTypeSprite = Scene.make.image({
      x: ownerSprite.x + damageState.x_offset,
      y: ownerSprite.y + damageState.y_offset - 13,
      key: "radar",
      frame: name,
      add: true
    });

    damageState.damageTypeSprite.setDepth(1001);
    damageState.damageTypeSprite.setOrigin(0.5);
    damageState.damageTypeSprite.setDisplaySize(15, 15);
    addIgnoreObject(damageState.damageTypeSprite);
  }

  let targets = [damageState.sprite];
  if (damageState.damageTypeSprite) {
    targets.push(damageState.damageTypeSprite)
  }

  Scene.tweens.add({
    targets: targets,
    duration: damage_time,
    scale: 0,
    delay: 1250,
    alpha: 0,
  });

  ownerSprite.damageText = damageState
}

function positionDamage(data) {

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  if (!Scene.cameras.main.worldView.contains(pos.x, pos.y)) {
    return;
  }

  pos.x += randomInt(-15, 15);
  pos.y += randomInt(-15, 15);

  let text = createTextSprite(pos.x, pos.y, data.damage, data.ownerType, data.dealerType, data.ownerID, data.dealerID)

  text.moveToPlugin = Scene.plugins.get('rexmovetoplugin').add(text, {
    speed: 100,
  }).on('complete', function (gameObject, moveTo) {
    text.destroy();
  });

  text.moveToPlugin.moveTo(text.x, text.y - 100);
}

function randomInt(min, max) {
  return min + Math.floor((max - min) * Math.random());
}

function createTextSprite(x, y, damage, ownerType, dealerType, ownerID, dealerID, unitShield) {
  let text = Scene.add.bitmapText(x, y, 'bit_text', damage, 32);
  if (ownerType === "shield" || unitShield) {
    text.setTint(0x00d3ff)
  }

  if (ownerType === "heal") {
    text.setTint(0x00ff83)
  }

  if (dealerType === ownerType && dealerID === ownerID) {
    text.setTint(0xff0000)
  }

  text.setDepth(1001);
  text.setOrigin(0.5);
  text.setScale(0.45);
  addIgnoreObject(text);

  return text
}

function getDamageSprite(data) {
  if (data.ownerType === 'unit' && gameStore.units[data.ownerID] && gameStore.units[data.ownerID].sprite) {
    return gameStore.units[data.ownerID].sprite
  }

  if (data.ownerType === 'object' && gameStore.objects[data.ownerID] && gameStore.objects[data.ownerID].objectSprite) {
    return gameStore.objects[data.ownerID].objectSprite
  }

  if (data.ownerType === 'shield' && getShieldByUUID(data.ownerID)) {
    return getShieldByUUID(data.ownerID)
  }
}

export {damageText}
