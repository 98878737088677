<template>
  <div class="wrapper" v-if="loader.visible" :style="{zoom: interfaceZoom}">
    <div class="mask"></div>

    <div id="loader" ref="loader">
      <app-control v-bind:head="texts['window_name'][language]"
                   v-bind:move="true"
                   v-bind:close="false"
                   v-bind:refWindow="'loader'"
                   v-bind:noHeight="true"
                   v-bind:noPos="true"
                   v-bind:noWidth="true"
                   v-bind:texture="'none'"/>

      <div class="texture_wrapper"
           :style="{
             top: '-109px',
             left:'-16px',
           }"/>

      <div class="button_back"></div>
      <div class="spinner_back"></div>
      <div class="spinner"
           :style="{backgroundImage: 'url(' + require('../../assets/interface/spinner.png').default + ')'}"/>

      <div class="text"
           v-if="loader.text && loader.text[language] && loader.text[language] !== ''"
           v-html="loader.text[language]"/>
      <div class="text" v-else>
        {{ texts['text_1'][language] }}
      </div>

      <div class="errors" v-if="systemErrors.length > 0">
        {{ language === 'RU' ? 'Произошла ошибка: ' : 'An error occurred:' }}<br>
        <template v-for="e in systemErrors">
          <div v-html="e"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';

export default {
  name: "Loader",
  computed: {
    loader() {
      return this.$store.getters.getEndLoad
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      let t = this.$store.getters.getInterfaceHandBook('Loader')
      if (!t || !t['window_name']) {
        return {
          'window_name': {language: 'Loading...'},
          'text_1': {language: 'Something mysterious is happening... ¯\\_(ツ)_/¯'},
        }
      }
      return t
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    },
    systemErrors() {
      return this.$store.getters.getSystemErrors
    }
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>

.wrapper {
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #7f7f7f;
  background-image: url('../../assets/bases/base.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: auto;
}

.text {
  border-radius: 5px;
  background: rgba(137, 150, 156, 0.3);
  pointer-events: auto;
  padding: 5px;
  box-shadow: inset 0 0 2px black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mask {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#loader {
  position: absolute;
  left: calc(50% - 140px);
  top: 20%;
  display: block;
  border-radius: 5px;
  width: 280px;
  height: 24px;
  padding: 18px 2px 2px 2px;
  box-shadow: 0 0 2px black;
  font-size: 12px;
  text-align: left;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

.arrow {
  width: 60px;
  height: 32px;
  margin-left: 14px;
  padding-top: 6px;
  transform: rotate(270deg) scale(0.9);
}

.texture_wrapper, .spinner {
  height: 310px;
  width: 308px;
  background-size: cover;
  position: absolute;
  /* pointer-events: none; */
  z-index: 89;
  background-image: url("../../assets/interface/frame_5.png");
}

.spinner_back, .button_back {
  z-index: 87;
  top: 41px;
  left: 181px;
  height: 40px;
  width: 50px;
  position: absolute;
  background: rgba(36, 99, 129, 0.3);
  backdrop-filter: blur(4px);
}

.button_back {
  z-index: 87;
  top: 50px;
  left: 89px;
  height: 28px;
  width: 79px;
  position: absolute;
  background-image: repeating-linear-gradient(36deg, rgba(36, 99, 129, 1), rgba(36, 99, 129, 1) 1px, transparent 0px, transparent 2px);
  background-size: 12px;
}

.spinner {
  z-index: 88;
  height: 36px;
  width: 30px;
  filter: drop-shadow(0px 0px 3px white);
  top: 46px;
  left: 190px;
  -webkit-animation: rotating 2s linear infinite; /* Safari 4+ */
  -moz-animation: rotating 2s linear infinite; /* Fx 5+ */
  -o-animation: rotating 2s linear infinite; /* Opera 12+ */
  animation: rotating 2s linear infinite; /* IE 10+, Fx 29+ */
}

@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.errors {
  position: absolute;
  top: 110px;
  color: red;
  font-weight: bold;
}
</style>

<style>
#loader .windowsHead {
  border: 0;
}
</style>
