import {gameStore} from "../store";
import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {addIgnoreObject} from "../interface/mini_map";
import {UpdateWatch} from "../radar/client_watcher";

function CreateCloud(cloudState) {
  if (gameStore.gameReady) {
    let cloud = gameStore.clouds[cloudState.id];
    if (!cloud) cloud = createCloud(cloudState);

    let pos = GetGlobalPos(cloudState.x, cloudState.y, gameStore.map.id);

    MoveSprite(cloud, pos.x, pos.y, 2000, null, true);
    UpdateWatch(cloud, cloud, 9, 1)
  }
}

function createCloud(cloudState) {

  let pos = GetGlobalPos(cloudState.x, cloudState.y, gameStore.map.id);

  let cloud = Scene.make.image({
    x: pos.x,
    y: pos.y,
    key: "clouds",
    frame: "cloud" + cloudState.type_id,
    add: true
  });

  cloud.setOrigin(0.5);
  cloud.setAngle(cloudState.r);
  cloud.setAlpha(cloudState.a);
  cloud.setDepth(1001);
  addIgnoreObject(cloud);

  cloud.id = cloudState.id;

  gameStore.clouds[cloud.id] = cloud;

  return cloud
}

function removeCloud(id) {
  let cloud = gameStore.clouds[id];
  if (cloud) {
    cloud.gameCache = true;
    cloud.destroy();
    delete gameStore.clouds[id];
  }
}

export {CreateCloud, removeCloud}
