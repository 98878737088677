import {CreateSelectSprite, destroyAllSelectedSprite} from "./create_select_sprite";
import {Scene} from "../create";
import {gameStore} from "../store";
import store from "../../store/store";
import {MapSize} from "../map/createMap";
import {GetGlobalPos} from "../map/gep_global_pos";

function MoveBase() {

  let callBack = function (x, y, r, id) {
    destroyAllSelectedSprite();

    let selectSprite = Scene.make.image({
      x: x,
      y: y,
      key: "radar",
      frame: 'baseResp',
      add: true
    });

    selectSprite.setOrigin(0.5);
    selectSprite.setDepth(1000);
    selectSprite.setScale(0.1);
    selectSprite.setInteractive();
    selectSprite.setAngle(r);
    gameStore.mapEditorState.placeObj = selectSprite;

    let updatePos = setInterval(function () {
      selectSprite.x = Math.round(Scene.input.mousePointer.worldX);
      selectSprite.y = Math.round(Scene.input.mousePointer.worldY);
    }, 10);

    selectSprite.on('pointerdown', function (pointer) {
      if (pointer.leftButtonDown()) {
        store.dispatch("sendSocketData", JSON.stringify({
          service: "map_editor",
          data: {
            event: "moveBase",
            id: gameStore.mapEditorState.mpID,
            x: Number(selectSprite.x - MapSize),
            y: Number(selectSprite.y - MapSize),
            to_base_id: Number(id),
          }
        }));
      }

      selectSprite.destroy()
      clearInterval(updatePos)
      gameStore.mapEditorState.placeObj = null;
    });
  };

  SelectedBase(callBack)
}

function SelectedBase(callBack, type) {
  if (gameStore.gameReady) {
    for (let i in gameStore.bases) {
      let pos = GetGlobalPos(gameStore.bases[i].x, gameStore.bases[i].y, gameStore.map.id);
      CreateSelectSprite(pos.x, pos.y, callBack, true, null, gameStore.bases[i].id);
    }
  }
}

export {MoveBase}
