import store from "../store/store";
import {gameStore} from "./store";

let loading = 0;

function preload() {
  try {

    this.load.on('progress', function (value) {
      loading = Math.round(value * 100);
    });

    if (!gameStore.mapEditor) {
      this.load.on('fileprogress', function (file) {
        if (!gameStore.noLoader && !gameStore.gameReady && location.href.includes('global')) {
          store.commit({
            type: 'setVisibleLoader',
            visible: true,
            text: {
              'RU': `загрузка текстур: <span style="font-size: 9px; color: yellow">${file.key}</span> (${loading} %)`,
              'EN': `loading textures: <span style="font-size: 9px; color: yellow">${file.key}</span> (${loading} %)`,
            }
          });
        }
      });

      this.load.on('complete', function () {
        if (!gameStore.noLoader && !gameStore.gameReady && location.href.includes('global')) {
          store.commit({
            type: 'setVisibleLoader',
            visible: true,
            text: {
              'RU': `<span style="font-size: 9px; color: yellow">тут я обычно зависаю</span>`,
              'EN': `<span style="font-size: 9px; color: yellow">this is where I usually get stuck</span>`,
            }
          });
        }
      });
    }

    this.load.bitmapFont('bit_text',
      require("../assets/bit_map_text/bit_map_text.png").default,
      require("../assets/bit_map_text/bit_map_text.xml").default,
    );

    this.load.bitmapFont('bit_text_12',
      require("../assets/bit_map_text/bit_map_text_12.png").default,
      require("../assets/bit_map_text/bit_map_text_12.xml").default,
    );

    this.load.bitmapFont('bit_text_14',
      require("../assets/bit_map_text/bit_map_text_14.png").default,
      require("../assets/bit_map_text/bit_map_text_14.xml").default,
    );

    const atlases = [
      {
        key: 'mountains',
        textureURL: require("../assets/map/objects/mountains/atlas/mountains.png").default,
        atlasURL: require("../assets/map/objects/mountains/atlas/mountains_atlas.json"),
      }, {
        key: 'ravines',
        textureURL: require("../assets/map/objects/ravines/atlas/ravines.png").default,
        atlasURL: require("../assets/map/objects/ravines/atlas/ravines_atlas.json"),
      }, {
        key: 'roads',
        textureURL: require("../assets/map/objects/roads/atlas/roads.png").default,
        atlasURL: require("../assets/map/objects/roads/atlas/roads_atlas.json"),
      }, {
        key: 'unknown_civilization',
        textureURL: require("../assets/map/objects/unknown_civilization/atlas/unknown_civilization.png").default,
        atlasURL: require("../assets/map/objects/unknown_civilization/atlas/unknown_civilization_atlas.json"),
      }, {
        key: 'structure_wreckage',
        textureURL: require("../assets/map/objects/structure_wreckage/atlas/structure_wreckage.png").default,
        atlasURL: require("../assets/map/objects/structure_wreckage/atlas/structure_wreckage_atlas.json"),
      }, {
        key: 'shores',
        textureURL: require("../assets/map/objects/shores/atlas/shores.png").default,
        atlasURL: require("../assets/map/objects/shores/atlas/shores_atlas.json"),
      }, {
        key: 'sector_structure',
        textureURL: require("../assets/map/objects/sector_structure/atlas/sector_structure.png").default,
        atlasURL: require("../assets/map/objects/sector_structure/atlas/sector_structure_atlas.json"),
      }, {
        key: 'other',
        textureURL: require("../assets/map/objects/other/atlas/other.png").default,
        atlasURL: require("../assets/map/objects/other/atlas/other_atlas.json"),
      }, {
        key: 'elevations',
        textureURL: require("../assets/map/objects/elevations/atlas/elevations.png").default,
        atlasURL: require("../assets/map/objects/elevations/atlas/elevations_atlas.json"),
      }, {
        key: 'structures',
        textureURL: require("../assets/map/structures/atlas/structures.png").default,
        atlasURL: require("../assets/map/structures/atlas/structures_atlas.json"),
      }, {
        key: 'resource',
        textureURL: require("../assets/map/resource/atlas/resource.png").default,
        atlasURL: require("../assets/map/resource/atlas/resource_atlas.json"),
      }, {
        key: 'flares',
        textureURL: require("../assets/fire_effects/atlas/flares.png").default,
        atlasURL: require("../assets/fire_effects/atlas/flares_atlas.json"),
      }, {
        key: 'bullets',
        textureURL: require("../assets/units/gameAmmo/atlas/bullets.png").default,
        atlasURL: require("../assets/units/gameAmmo/atlas/bullets_atlas.json"),
      }, {
        key: 'bodies',
        textureURL: require("../assets/units/1_atlases/bodies.png").default,
        atlasURL: require("../assets/units/1_atlases/bodies_atlas.json"),
      }, {
        key: 'equips',
        textureURL: require("../assets/units/1_atlases/equip_1.png").default,
        atlasURL: require("../assets/units/1_atlases/equip_1_atlas.json"),
      }, {
        key: 'radar',
        textureURL: require("../assets/game_icons/atlas/radar.png").default,
        atlasURL: require("../assets/game_icons/atlas/radar_atlas.json"),
      },
      {
        key: 'clouds',
        textureURL: require("../assets/map/clouds/atlas/clouds.png").default,
        atlasURL: require("../assets/map/clouds/atlas/clouds_atlas.json"),
      }
    ];

    for (let atlas of atlases) {
      this.load.atlas(atlas);
    }

    //Brush
    this.load.image('water_1', require("../assets/terrainTextures/water_1.jpg").default);
    this.load.image('brush_256', require("../assets/terrainTextures/brush_256.png").default);
    this.load.image('brush_128', require("../assets/terrainTextures/brush_128.png").default);
    this.load.image('brush_fog', require("../assets/terrainTextures/brush_fog.png").default);

    this.load.image('brush', require("../assets/terrainTextures/brush.png").default);
    this.load.image('desertDunes', require("../assets/terrainTextures/desertDunes.png").default);
    this.load.image('desertDunes_2', require("../assets/terrainTextures/desertDunes_2.png").default);
    this.load.image('xenos', require("../assets/terrainTextures/xenos.png").default);
    this.load.image('xenos_2', require("../assets/terrainTextures/xenos_2.png").default);
    this.load.image('arctic', require("../assets/terrainTextures/arctic.png").default);
    this.load.image('arctic_2', require("../assets/terrainTextures/arctic_2.png").default);
    this.load.image('tundra', require("../assets/terrainTextures/tundra.png").default);
    this.load.image('tundra_2', require("../assets/terrainTextures/tundra_2.png").default);
    this.load.image('grass_1', require("../assets/terrainTextures/grass_1.png").default);
    this.load.image('grass_2', require("../assets/terrainTextures/grass_2.png").default);
    this.load.image('grass_3', require("../assets/terrainTextures/grass_3.png").default);
    this.load.image('soil', require("../assets/terrainTextures/soil.png").default);
    this.load.image('soil_2', require("../assets/terrainTextures/soil_2.png").default);
    this.load.image('ravine_1', require("../assets/terrainTextures/ravine_1.png").default);
    this.load.image('ravine_2', require("../assets/terrainTextures/ravine_2.png").default);
    this.load.image('paving_stone_1', require("../assets/terrainTextures/paving_stone_1.png").default);
    this.load.image('clay_1', require("../assets/terrainTextures/clay_1.png").default);
    this.load.image('clay_2', require("../assets/terrainTextures/clay_2.png").default);
    this.load.image('decal_1', require("../assets/terrainTextures/decal_1.png").default);
    this.load.image('decal_2', require("../assets/terrainTextures/decal_2.png").default);
    this.load.image('decal_3', require("../assets/terrainTextures/decal_3.png").default);
    this.load.image('decal_4', require("../assets/terrainTextures/decal_4.png").default);
    this.load.image('decal_5', require("../assets/terrainTextures/decal_5.png").default);

    //this.load.atlas('terrains', require("../assets/terrainTextures/atlas/terrains.png"), require("../assets/terrainTextures/atlas/terrains_atlas.json"));

    // щиты
    // this.load.spritesheet('shield_1', require("../assets/units/shields/shield_1.png").default, {
    //   frameWidth: 1020,
    //   frameHeight: 1020,
    //   endFrame: 1
    // });
    // this.load.image('shield_1_border', require("../assets/units/shields/shield_1_border.png").default);

    // this.load.spritesheet('mapEditor', require("../assets/select/mapEditor.png").default, {
    //   frameWidth: 80,
    //   frameHeight: 100
    // });

    this.load.audio('structure_4', require("../assets/audio/sound_effects/fire_weapon/structure_4.mp3").default);
    this.load.audio('structure_5', require("../assets/audio/sound_effects/fire_weapon/structure_5.mp3").default);

    // common sound
    this.load.audio('crunch', require("../assets/audio/sound_effects/common/crunch.mp3").default);
    this.load.audio('vehicle_crash', require("../assets/audio/sound_effects/common/vehicle_crash.mp3").default);

    // equip sound
    this.load.audio('accelerate_2', require("../assets/audio/sound_effects/equip/accelerate_2.mp3").default);
    this.load.audio('harpoon', require("../assets/audio/sound_effects/equip/harpoon.mp3").default);
    this.load.audio('invisibility_off', require("../assets/audio/sound_effects/equip/invisibility_off.mp3").default);
    this.load.audio('invisibility_on', require("../assets/audio/sound_effects/equip/invisibility_on.mp3").default);
    this.load.audio('teleport_jump', require("../assets/audio/sound_effects/equip/teleport_jump.mp3").default);
    this.load.audio('large_explosion', require("../assets/audio/sound_effects/equip/large_explosion.mp3").default);
    this.load.audio('capsule_flight', require("../assets/audio/sound_effects/equip/capsule_flight.mp3").default);
    this.load.audio('magnetic_field', require("../assets/audio/sound_effects/equip/magnetic_field.mp3").default);
    this.load.audio('accelerate', require("../assets/audio/sound_effects/equip/accelerate.mp3").default);
    this.load.audio('accelerate_stop', require("../assets/audio/sound_effects/equip/accelerate_stop.mp3").default);
    this.load.audio('drone_activation', require("../assets/audio/sound_effects/equip/drone_activation.mp3").default);
    this.load.audio('drone_explosion', require("../assets/audio/sound_effects/equip/drone_explosion.mp3").default);

    // Weapon sound
    this.load.audio('big_laser_1_fire', require("../assets/audio/sound_effects/fire_weapon/big_laser_1_fire.mp3").default);
    this.load.audio('big_laser_1_reload', require("../assets/audio/sound_effects/fire_weapon/big_laser_1_reload.mp3").default);
    this.load.audio('big_laser_2_fire', require("../assets/audio/sound_effects/fire_weapon/big_laser_2_fire.mp3").default);
    this.load.audio('big_laser_2_reload', require("../assets/audio/sound_effects/fire_weapon/big_laser_2_reload.mp3").default);
    this.load.audio('big_laser_3_fire', require("../assets/audio/sound_effects/fire_weapon/big_laser_3_fire.mp3").default);
    this.load.audio('big_laser_3_reload', require("../assets/audio/sound_effects/fire_weapon/big_laser_3_reload.mp3").default);

    this.load.audio('laser_1_fire_1', require("../assets/audio/sound_effects/fire_weapon/laser_1_fire_1.mp3").default);
    this.load.audio('laser_1_fire_2', require("../assets/audio/sound_effects/fire_weapon/laser_1_fire_2.mp3").default);
    this.load.audio('laser_1_fire_3', require("../assets/audio/sound_effects/fire_weapon/laser_1_fire_3.mp3").default);
    this.load.audio('laser_1_reload', require("../assets/audio/sound_effects/fire_weapon/laser_1_reload.mp3").default);

    this.load.audio('laser_2_fire_1', require("../assets/audio/sound_effects/fire_weapon/laser_2_fire_1.mp3").default);
    this.load.audio('laser_2_fire_2', require("../assets/audio/sound_effects/fire_weapon/laser_2_fire_2.mp3").default);
    this.load.audio('laser_2_fire_3', require("../assets/audio/sound_effects/fire_weapon/laser_2_fire_3.mp3").default);
    this.load.audio('laser_2_fire_4', require("../assets/audio/sound_effects/fire_weapon/laser_2_fire_4.mp3").default);
    this.load.audio('laser_2_reload', require("../assets/audio/sound_effects/fire_weapon/laser_1_reload.mp3").default);

    this.load.audio('firearms_1', require("../assets/audio/sound_effects/fire_weapon/firearms_1.mp3").default);
    this.load.audio('firearms_2', require("../assets/audio/sound_effects/fire_weapon/firearms_2.mp3").default);
    this.load.audio('firearms_3', require("../assets/audio/sound_effects/fire_weapon/firearms_3.mp3").default);
    this.load.audio('firearms_4', require("../assets/audio/sound_effects/fire_weapon/firearms_4.mp3").default);
    this.load.audio('firearms_5', require("../assets/audio/sound_effects/fire_weapon/firearms_5.mp3").default);
    this.load.audio('firearms_6', require("../assets/audio/sound_effects/fire_weapon/firearms_6.mp3").default);

    this.load.audio('missile_1', require("../assets/audio/sound_effects/fire_weapon/missile_1.mp3").default);
    this.load.audio('missile_2', require("../assets/audio/sound_effects/fire_weapon/missile_2.mp3").default);
    this.load.audio('missile_3', require("../assets/audio/sound_effects/fire_weapon/missile_3.mp3").default);

    this.load.audio('laser_buzz', require("../assets/audio/sound_effects/fire_weapon/laser_buzz.mp3").default);
    this.load.audio('laser_load', require("../assets/audio/sound_effects/fire_weapon/laser_load.mp3").default);

    this.load.audio('explosion_1', require("../assets/audio/sound_effects/explosions/explosion_1.mp3").default);
    this.load.audio('explosion_2', require("../assets/audio/sound_effects/explosions/explosion_2.mp3").default);
    this.load.audio('explosion_3', require("../assets/audio/sound_effects/explosions/explosion_3.mp3").default);
    this.load.audio('explosion_4', require("../assets/audio/sound_effects/explosions/explosion_4.mp3").default);
    this.load.audio('explosion_5', require("../assets/audio/sound_effects/explosions/explosion_5.mp3").default);

    this.load.audio('explosion_1x2', require("../assets/audio/sound_effects/explosions/explosion_1x2.mp3").default);
    this.load.audio('explosion_2x2', require("../assets/audio/sound_effects/explosions/explosion_2x2.mp3").default);
    this.load.audio('explosion_3x2', require("../assets/audio/sound_effects/explosions/explosion_3x2.mp3").default);
    this.load.audio('explosion_4x2', require("../assets/audio/sound_effects/explosions/explosion_4x2.mp3").default);
    this.load.audio('explosion_5x2', require("../assets/audio/sound_effects/explosions/explosion_5x2.mp3").default);

    this.load.audio('explosion_3x3', require("../assets/audio/sound_effects/explosions/explosion_3x3.mp3").default);
    this.load.audio('explosion_4x3', require("../assets/audio/sound_effects/explosions/explosion_4x3.mp3").default);

    // interface sound
    this.load.audio('self_destruction_countdown', require("../assets/audio/sound_effects/interface/self_destruction_countdown.mp3").default);
    this.load.audio('missile_aim', require("../assets/audio/sound_effects/interface/missile_aim.mp3").default);

    // engiges sound
    this.load.audio('engine_4', require("../assets/audio/sound_effects/engiges/engine_4.mp3").default);
    this.load.audio('engine_6', require("../assets/audio/sound_effects/engiges/engine_6.mp3").default);
    this.load.audio('engine_7', require("../assets/audio/sound_effects/engiges/engine_7.mp3").default);
    this.load.audio('engine_10', require("../assets/audio/sound_effects/engiges/engine_10.mp3").default);
    this.load.audio('engine_11', require("../assets/audio/sound_effects/engiges/engine_11.mp3").default);
    this.load.audio('engine_12', require("../assets/audio/sound_effects/engiges/engine_12.mp3").default);
    this.load.audio('engine_13', require("../assets/audio/sound_effects/engiges/engine_13.mp3").default);
    this.load.audio('engine_14', require("../assets/audio/sound_effects/engiges/engine_14.mp3").default);
    this.load.audio('antigravity_engine', require("../assets/audio/sound_effects/engiges/antigravity_engine.mp3").default);

    // Other/Quests
    this.load.image('sun_city', require("../assets/map/objects/unknown_civilization/sun_city.png").default);

    // this.load.image('displacement_1', require("../assets/shaders/displacement_2.png").default);

    // for (let i in gameStore.gameTypes.ammo) {
    //   let ammoSlot = {
    //     type: "ammo", item: gameStore.gameTypes.ammo[i]
    //   }
    //   try {
    //     this.load.image("ammo_" + gameStore.gameTypes.ammo[i].name, getIconPath(ammoSlot));
    //   } catch (e) {
    //   }
    // }
  } catch (e) {
    store.commit({
      type: 'addSystemError',
      error: e,
    });

    throw e
  }
}

export {preload}
