<template>
  <div class="InventoryCell"
       v-bind:style="{width: size + 'px', height: size + 'px', outline: few ? 'red solid 1px' : ''}"
       v-bind:class="{
          notAllow: noAllowClass,
          select: select,
          BackStorage: back_storage,
          green_animate: green_animate,
          disable: disable,
          cursor_hover: cursor_pointer,
        }">

    <div class="Fine" v-if="fine">

    </div>

    <div class="lock" v-if="lock">

    </div>

    <div :class="{['type' + type]: true}" v-if="type"/>
    <div :class="{['label_type' + label_type]: true}" v-if="label_type"/>

    <span class="QuantityItems" v-if="slotData && slotData.quantity && !noQuantity">
      <template v-if="slotData.infinite">
        <span class="infinite">&#8734;</span>
      </template>
      <span v-if="!slotData.infinite" :style="{color: quantityColor ? quantityColor : '#fff16f'}">
        {{ slotData.quantity }}
      </span>
    </span>

    <div v-if="showHP && slotHP !== 100" class="ItemHP" :class="{downHP: downHP}">
      <div class="hpBar" :style="{width: slotHP + '%', backgroundColor: colorDamage}"></div>
    </div>

    <app-background-item-cell v-if="slotData && slotData.item"
                              v-bind:slotItem="slotData"
                              v-bind:opacity="opacity"
                              v-bind:textFine="textFine"
                              v-bind:noShowName="noShowName"
                              v-bind:disable="disable"
    />

    <span v-if="text" v-html="text"/>

    <div class="noAllowCell" v-if="few"></div>

    <div class="broken" v-if="itemSlot.item_id > 10000000">

    </div>

    <div class="itemTax" v-if="tax">
      <span style="float: left">{{ texts['cell_tax'][language] }}</span>
      <span style="float: right">{{ tax }}%</span>
    </div>
  </div>
</template>

<script>
import BackgroundItemCell from './BackgroundItemCell'
import store from "../../store/store";

export default {
  name: "ItemCell",
  props: ['itemSlot', 'size', 'parent', 'drag', 'text', 'noShowName', 'noAllowClass', 'equipProps', 'few', 'tax',
    'noQuantity', 'fine', 'textFine', 'opacity', 'showHP', 'select', 'lock', 'type', 'label_type', 'back_storage',
    'green_animate', 'disable', 'quantityColor', 'cursor_pointer', 'downHP'],
  data() {
    return {
      uParent: '',
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Inventory')
    },
    slotData() {
      this.init();
      return this.$props.itemSlot
    },
    slotHP() {
      return (100 / (this.slotData.max_hp / this.slotData.hp))
    },
    colorDamage() {
      return this.$store.getters.GetColorDamage(this.slotHP)
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$props.drag) {
        this.initDrag();
      }
      if (this.$props.equipProps) {
        this.initDragEquip();
      }
    },
    initDragEquip() {
      let props = this.$props.equipProps;

      if (props.type === 'equip') {
        this.initEquipSlot(props)
      }
      if (props.type === 'weapon') {
        this.initWeaponSlot(props)
      }
      if (props.type === 'ammo') {
        this.initAmmoSlot(props)
      }
      if (props.type === 'thorium') {
        this.initThoriumSlot(props)
      }
    },
    initThoriumSlot(props) {
      let app = this;
      $(app.$el).droppable({
        tolerance: "pointer",
        drop: function (event, ui) {
          $('.ui-selected').removeClass('ui-selected');

          let draggable = ui.draggable;
          let slotData = draggable.data("slotData");

          if (slotData && slotData.data && slotData.data.type === "fuel") {
            app.$store.dispatch("sendSocketData", JSON.stringify({
              service: "inventory",
              data: {
                event: "SetThorium",
                inventory_slot: Number(slotData.number),
                thorium_slot: Number(props.data.number_slot),
                source: slotData.parent,
              }
            }));
          }
        }
      });
    },
    initAmmoSlot(props) {
      let app = this;

      if (props.data && props.data.ammo) {
        $(app.$el).draggable({
          disabled: false,
          start: function () {
            $(app.$el).data("slotData", {
              event: "RemoveMotherShipAmmo",
              parent: "Constructor",
              equipSlot: Number(props.data.number_slot),
            });
          },
          drag: function (e, ui) {

          },
          revert: "invalid",
          zIndex: 999,
          helper: 'clone',
          appendTo: 'body',
        });
      }

      $(app.$el).droppable({
        tolerance: "pointer",
        drop: function (event, ui) {
          $('.ui-selected').removeClass('ui-selected');
          let draggable = ui.draggable;
          let slotData = draggable.data("slotData");

          if (slotData.data && slotData.data.type === "ammo") {
            app.$store.dispatch('setAmmo', {
              slot: slotData.data,
              src: slotData.parent,
              equip_slot: Number(props.data.number_slot),
            });
          }
        }
      });
    },
    initWeaponSlot(props) {
      let app = this;

      if (props.data && props.data.weapon) {
        $(app.$el).draggable({
          disabled: false,
          start: function () {
            $(app.$el).data("slotData", {
              event: "RemoveMotherShipWeapon",
              parent: "Constructor",
              equipSlot: Number(props.data.number_slot),
            });
          },
          drag: function (e, ui) {

          },
          revert: "invalid",
          zIndex: 999,
          helper: 'clone',
          appendTo: 'body',
        });
      }

      $(app.$el).droppable({
        tolerance: "pointer",
        drop: function (event, ui) {
          $('.ui-selected').removeClass('ui-selected');
          let draggable = ui.draggable;
          let slotData = draggable.data("slotData");

          if (slotData.data && slotData.data.type === "weapon") {
            app.$store.dispatch('setWeapon', {
              slot: slotData.data,
              src: slotData.parent,
              equip_slot: Number(props.data.number_slot),
            });
          }
        }
      });
    },
    initEquipSlot(props) {

      let app = this;

      if (props.data && props.data.equip) {
        $(app.$el).draggable({
          disabled: false,
          start: function () {
            $(app.$el).data("slotData", {
              event: "RemoveMotherShipEquip",
              parent: "Constructor",
              equipSlot: Number(props.data.number_slot),
              equipType: Number(props.data.type_slot),
            });
          },
          drag: function (e, ui) {

          },
          revert: "invalid",
          zIndex: 999,
          helper: 'clone',
          appendTo: 'body',
        });
      } else {
        $(app.$el).draggable({
          disabled: true
        })
      }

      $(app.$el).droppable({
        tolerance: "pointer",
        drop: function (event, ui) {

          $('.ui-selected').removeClass('ui-selected');

          let draggable = ui.draggable;
          let slotData = draggable.data("slotData");
          if (slotData.data && slotData.data.type === "equip") {
            app.$store.dispatch("sendSocketData", JSON.stringify({
              service: "inventory",
              data: {
                event: "SetMotherShipEquip",
                equip_id: Number(slotData.data.item_id),
                inventory_slot: Number(slotData.number),
                equip_slot: Number(props.data.number_slot),
                equip_slot_type: Number(props.data.type_slot),
                source: slotData.parent,
              }
            }));
          }
        }
      });
    },
    initDrag() {

      let app = this;
      let slot = this.$props.itemSlot;

      $(app.$el).data("slotData", {parent: app.$props.parent, data: slot, number: slot.number});

      $(app.$el).draggable({
        start: function () {
          $(app.$el).addClass("ui-selected");

          let selectItems = $('.InventoryCell.ui-selected');
          if (selectItems.length > 0) {

            // если выделено много элементов то отправляем их все
            let slotsNumbers = [];
            slotsNumbers.push(Number($(app.$el).data("slotData").number));
            selectItems.each(function (index) {
              if ($(this).data("slotData") !== undefined && $(this).data("slotData").number !== $(app.$el).data("slotData").number) {
                slotsNumbers.push(Number($(this).data("slotData").number));
              }
            });

            $(app.$el).data("selectedItems", {parent: app.$props.parent, slotsNumbers: slotsNumbers});
          } else {
            $(app.$el).removeData("selectedItems");
          }
        },
        stop: function (event, ui) {
          if (app.$props.parent === "storage" || app.$props.parent === "squadInventory" || app.$props.parent.includes('aInv')) {

            let inner = false

            for (let i of ["wrapperInventoryAndStorage", "inventoryStorageInventory", "inventoryBox", "Workbench", "processorRoot", "SquadBarCells"]) {
              let inv = document.getElementById(i);

              if (inv) {
                let rect = inv.getBoundingClientRect();

                if (rect.top < event.pageY && rect.bottom > event.pageY &&
                  rect.left < event.pageX && rect.right > event.pageX) {
                  inner = true
                }
              }
            }

            for (let j of ['objectDialogWrapper', 'bodyThoriumWrapper']) {
              for (let i of document.getElementsByClassName(j)) {
                let rect = i.getBoundingClientRect();
                if (rect.top < event.pageY && rect.bottom > event.pageY &&
                  rect.left < event.pageX && rect.right > event.pageX) {
                  inner = true
                }
              }
            }

            if (!inner) {
              let throwItems = {};
              let items = {};

              $('.InventoryCell.ui-selected').each(function (index) {
                if ($(this).data("slotData") !== undefined) {
                  throwItems[Number($(this).data("slotData").number)] = Number($(this).data("slotData").data.quantity);
                  items[Number($(this).data("slotData").number)] = $(this).data("slotData").data;
                }
              });

              if (app.$route.path !== '/global') {
                app.$store.commit({
                  type: 'setThrowItemsState',
                  open: true,
                  slots: throwItems,
                  items: items,
                  source: app.$props.parent,
                });
              } else {
                app.$store.dispatch("sendSocketData", JSON.stringify({
                  service: "inventory",
                  data: {
                    event: "ThrowItems",
                    storage_slots: throwItems,
                    source: app.$props.parent,
                  }
                }));
              }
            }
          }

          $('.ui-selected').removeClass("ui-selected");
        },
        drag: function (e, ui) {
          let icon = $('.ui-draggable-dragging');
          if ($('.InventoryCell.ui-selected').length > 1) {
            icon.empty().addClass('itemsDrag');
          }

          let size = 30 * ((100 + store.getters.getSettings.ZoomInterface) / 100);

          icon.css('pointer-events', 'none');
          icon.css('height', size + 'px');
          icon.css('width', size + 'px');
        },
        disabled: false,
        revert: "invalid",
        zIndex: 999,
        helper: 'clone',
        appendTo: 'body',
        delay: 0,
        distance: 1,
        opacity: 0.75,
        refreshPositions: true,
        revertDuration: 150,
        iframeFix: true,
      });

      $(app.$el).droppable({
        tolerance: "pointer",
        greedy: true,
        over: function (event, ui) {
          let draggable = ui.draggable;

          let src = draggable.data("slotData");
          let dst = $(this).data("slotData");

          if (draggable.data("selectedItems") !== undefined) {
            // если в рукаве много айтемов то это нельзя комбинировать
            $(this).droppable("option", "greedy", false);
            return
          }

          if (src && dst && src.data && dst.data && dst.data.item_id === src.data.item_id && dst.data.type === src.data.type) {
            $(this).droppable("option", "greedy", true);
          } else {
            $(this).droppable("option", "greedy", false);
          }
        },
        drop: function (event, ui) {
          $('.ui-selected').removeClass('ui-selected');
          let draggable = ui.draggable;

          let src = draggable.data("slotData");
          let dst = $(this).data("slotData");

          if (draggable.data("selectedItems") > 1) {
            return
          }

          if (src && dst && src.data && dst.data && dst.data.item_id === src.data.item_id && dst.data.type === src.data.type) {
            app.$store.dispatch("sendSocketData", JSON.stringify({
              service: "inventory",
              data: {
                event: "combineItems",
                source: src.parent,
                src_slot: Number(src.number),
                destination: dst.parent,
                dst_slot: Number(dst.number),
              }
            }));
          }
        }
      });
    }
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
.InventoryCell {
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: inline-block;
  background-color: rgba(76, 76, 76, 0.4);
  vertical-align: top;
  margin: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  float: left;
  position: relative;
  overflow: hidden;
  border: 1px solid #959595;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.InventoryCell:hover {
  box-shadow: 0 0 2px 1px #FF9520;
  border: 1px solid #FF9520;
}

.QuantityItems {
  z-index: 1;
  position: absolute;
  color: #fff16f;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  pointer-events: none;
  bottom: 2px;
  left: 4px;
  font-size: 12px;
  font-weight: bold;
}

.InventoryCell.ui-selecting {
  box-shadow: 0 0 2px 1px #FF9520;
  border: 1px solid #FF9520;
}

.InventoryCell.ui-selected {
  box-shadow: 0 0 1px 1px #2fef17;
  border: 1px solid #2fef17;
}

.InventoryCell.select, .InventoryCell:hover {
  box-shadow: 0 0 2px 1px #FF9520;
  border: 1px solid #FF9520;
}

.InventoryCell.notAllow div {
  filter: blur(5px) saturate(0);
}

.noAllowCell {
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, rgba(30, 87, 153, 0) 0%, rgba(30, 87, 153, 0) 40%, rgb(249, 14, 24) 50%, rgba(30, 87, 153, 0) 60%, rgba(30, 87, 153, 0) 100%);
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
}

.itemTax {
  position: absolute;
  top: 5px;
  font-size: 9px;
  color: #ff4c4c;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  width: 90%;
  padding-left: 3px;
}

.Fine {
  background-image: url("../../assets/icons/equip_fine.png");
  filter: drop-shadow(0px 0px 3px black);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  opacity: 1;
  z-index: 0;
}

.ItemHP {
  height: 4px;
  border: 1px solid #a5a5b4;
  width: calc(80% - 2px);
  left: 10%;
  position: absolute;
  top: 4px;
  z-index: 2;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px black;
  overflow: hidden;
  background: #404041;
}

.ItemHP .hpBar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  box-shadow: inset 0 0 2px 0 black;
}

.downHP {
  top: unset;
  bottom: 2px;
}

.infinite {
  font-size: 18px;
  line-height: 10px;
}

.InventoryCell {

}

.broken {
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  border: 2px lightgrey dashed;
  border-radius: 5px;
}

.lock {
  height: 100%;
  width: 100%;
  z-index: 2;
  border-radius: 50%;
  background-image: url("../../assets/icons/lock.png");
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
}

.type1, .type2, .type3, .type4, .type6 {
  height: 100%;
  width: 100%;
  z-index: 2;
  border-radius: 50%;
  background-image: url("../../assets/icons/equip_type_1.png");
  background-position: center;
  background-size: 27px;
  background-repeat: no-repeat;
  opacity: 0.5;
  filter: drop-shadow(1px 1px 1px black);
}

.type2 {
  background-image: url("../../assets/icons/equip_type_2.png");
}

.type3 {
  background-image: url("../../assets/icons/equip_type_3.png");
}

.type4 {
  background-image: url("../../assets/icons/equip_type_4.png");
}

.type6 {
  background-image: url("../../assets/icons/category_weapons.png");
}

.label_type1, .label_type2, .label_type3, .label_type4 {
  position: absolute;
  left: 3px;
  top: 3px;
  height: 12px;
  width: 12px;
  background: url("../../assets/icons/equip_type_1.png");
  background-size: contain;
  z-index: 15;
  border-radius: 2px;
  filter: drop-shadow(1px 1px 1px black);
  background-color: rgba(0, 0, 0, 0.25);
}

.label_type2 {
  background-image: url("../../assets/icons/equip_type_2.png");
}

.label_type3 {
  background-image: url("../../assets/icons/equip_type_3.png");
}

.label_type4 {
  background-image: url("../../assets/icons/equip_type_4.png");
}

.BackStorage {
  background-color: rgba(62, 62, 62, 0.5);
}

.green_animate {
  box-shadow: inset 0 0 4px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: inset 0 0 6px 3px greenyellow;
  }
  50% {
    box-shadow: inset 0 0 12px 6px greenyellow;
  }
  100% {
    box-shadow: inset 0 0 6px 3px greenyellow;
  }
}

.disable {
  opacity: 0.5;
}

.additional {
  border: 1px dashed rgb(67, 147, 201);
  background-color: rgba(67, 147, 201, 0.3);
}
</style>

<style>
.itemsDrag {
  background-image: url(../../assets/indexImg/dragDetail.png);
  background-size: cover;
  background-color: grey !important;
}
</style>
