import {gameStore} from "../store";
import {Scene} from "../create";
import {ClearBars, CreateMapBar, UpdatePosBars} from "../interface/status_layer";
import {GetGlobalPos} from "../map/gep_global_pos";
import {getInt64Bytes, intFromBytes} from "../../utils";
import {GetMapLvl} from "../map/height_offset";
import {BlueExplosion} from "../weapon/explosion";
import {UpdateWatch} from "../radar/client_watcher";

function CreateShield(shield) {
  CreateShieldSprite(shield);
}

function CreateShieldSprite(shield) {

  shield = {
    id: intFromBytes(shield.slice(0, 4)),
    x: intFromBytes(shield.slice(4, 8)),
    y: intFromBytes(shield.slice(8, 12)),
    radius: intFromBytes(shield.slice(12, 16)),
    block: intFromBytes(shield.slice(16, 17)) === 1,
    current_hp: intFromBytes(shield.slice(17, 21)),
    max_hp: intFromBytes(shield.slice(21, 25)),
    owner_type: gameStore.mapBinItems[intFromBytes(shield.slice(25, 26))],
    owner_id: intFromBytes(shield.slice(26, 30)),
  }

  let pos = GetGlobalPos(shield.x, shield.y, gameStore.map.id);
  let oldShield = getShieldByUUID(shield.id);
  if (oldShield) {
    return oldShield
  }

  let sprite = Scene.make.image({
    x: pos.x + Math.random(), y: pos.y + Math.random(), key: 'equips', frame: 'shield_1', add: true
  });
  sprite.setOrigin(0.5);
  sprite.displayWidth = (shield.radius * 2);
  sprite.displayHeight = (shield.radius * 2);
  sprite.setDepth(shield.radius + 15 + GetMapLvl(shield.x, shield.y, gameStore.map.id));

  let border = Scene.make.image({
    x: pos.x,
    y: pos.y,
    key: 'equips',
    frame: 'shield_1_border',
    add: true
  });
  border.setOrigin(0.5);
  border.displayWidth = (shield.radius * 2);
  border.displayHeight = (shield.radius * 2);
  border.setDepth(0);

  shield.sprite = sprite;
  shield.border = border;

  if (shield.block) {
    shield.sprite.setTint(0x00ff00);
    shield.border.setTint(0x00ff00);
  } else {
    shield.sprite.setTint(0x9effff);
    shield.border.setTint(0x9effff);
  }
  shield.sprite.setAlpha(0.75)
  shield.border.setAlpha(0.15)

  shield.sprite.setBlendMode(Phaser.BlendModes.SCREEN)

  gameStore.shields[shield.id] = shield;

  let maskRadius = shield.radius - (shield.radius / 50);
  let mask = Scene.make.graphics({x: pos.x, y: pos.y});
  mask.fillStyle(0xffffff);
  mask.fillCircle(0, 0, maskRadius);
  shield.sprite.explode_mask = {
    mask: mask.createGeometryMask(),
    graphics: mask,
    radius: maskRadius,
  };


  UpdateWatch(shield, shield.sprite, 10, 2)
  UpdateShield(shield.id, getInt64Bytes(shield.id).concat(getInt64Bytes(shield.current_hp)))
  return shield;
}

function UpdateShieldPos(force) {
  let startOffset = 16;
  let addOffset = 4
  let offsets = {};

  for (let i in gameStore.shields) {
    let s = gameStore.shields[i];

    let key = s.owner_type + s.owner_id
    if (!offsets[key]) {
      offsets[key] = startOffset
    } else {
      offsets[key] += addOffset
    }

    if (s.owner_type === "unit") {
      updateShieldUnitPos(s, gameStore.units[s.owner_id], offsets[key], force)
    }

    if (s.owner_type === "object") {
      updateShieldObjectPos(s, gameStore.objects[s.owner_id], offsets[key], force)
    }
  }
}

function updateShieldUnitPos(shield, unit, startOffset) {
  if (unit && unit.sprite) {

    if (shield.sprite.x === unit.sprite.x && shield.sprite.y === unit.sprite.y) {
      return
    }

    shield.sprite.x = unit.sprite.x;
    shield.sprite.y = unit.sprite.y;

    shield.border.x = unit.sprite.x;
    shield.border.y = unit.sprite.y;

    shield.sprite.explode_mask.graphics.x = unit.sprite.x;
    shield.sprite.explode_mask.graphics.y = unit.sprite.y;

    if (shield.sprite.damageText && shield.sprite.damageText.sprite) {
      shield.sprite.damageText.sprite.setPosition(shield.sprite.x + shield.sprite.damageText.x_offset, shield.sprite.y + shield.sprite.damageText.y_offset)
    }

    let needDepth = shield.radius + 15 + (unit.sprite.depth - 35)
    if (shield.sprite.depth !== needDepth) {
      shield.sprite.setDepth(needDepth);
    }

    UpdateWatch(shield, shield.sprite, 10, 2)
    UpdatePosBars(unit.sprite, shield.max_hp, shield.current_hp, startOffset, 0x0070ff, Scene, 'unit', shield.id, 'shield', 50);
  }
}

function updateShieldObjectPos(shield, obj, startOffset) {
  if (obj && obj.objectSprite) {
    shield.sprite.x = obj.objectSprite.x;
    shield.sprite.y = obj.objectSprite.y;

    shield.border.x = obj.objectSprite.x;
    shield.border.y = obj.objectSprite.y;

    shield.sprite.explode_mask.graphics.x = obj.objectSprite.x;
    shield.sprite.explode_mask.graphics.y = obj.objectSprite.y;

    UpdatePosBars(obj.objectSprite, shield.max_hp, shield.current_hp, startOffset, 0x0070ff, Scene, 'object', obj.id, 'shield', 50);
  }
}

function UpdateShield(id, update) {
  let shield = getShieldByUUID(id);
  if (shield) {
    shield.current_hp = intFromBytes(update.slice(0, 4));
    if (shield.owner_type === "unit") {
      let unit = gameStore.units[shield.owner_id]
      if (unit) CreateMapBar(unit.sprite, shield.max_hp, shield.current_hp, 16, 0x0070ff, Scene, shield.owner_type, shield.id, 'shield', 50);
    }

    if (shield.owner_type === "object") {
      let obj = gameStore.objects[shield.owner_id]
      if (obj) CreateMapBar(obj.objectSprite, shield.max_hp, shield.current_hp, 14, 0x0070ff, Scene, shield.owner_type, shield.owner_id, 'shield', 50);
    }
  }
}

function RemoveShield(id) {
  let shield = gameStore.shields[id]
  if (!shield) {
    return;
  }

  shield.gameCache = true;
  ClearBars('unit', id, 'shield');
  ClearBars('object', id, 'shield');

  shield.border.destroy();
  shield.sprite.destroy();

  // что бы рендер не падал, когда удалилась маска щита, а анимацию еще идет
  Scene.time.addEvent({
    delay: 5000,
    callback: function () {
      shield.sprite.explode_mask.graphics.destroy();
      shield.sprite.explode_mask.mask.destroy();
    },
    loop: false
  });

  delete gameStore.shields[id];
}

function getShieldByUUID(id) {
  return gameStore.shields[id]
}

function shieldAnimate(id, r, x, y) {
  let shield = getShieldByUUID(id)
  if (!shield || !shield.sprite || !shield.sprite.explode_mask) return;

  let scale = r * 15
  if (scale < 25) {
    scale = 25
  }

  let pos = GetGlobalPos(x, y, gameStore.map.id);
  BlueExplosion(pos.x, pos.y, 1000, 2, scale, 55, 0, 360, 500, null, null, shield.sprite.explode_mask.mask);
}

export {
  CreateShield,
  UpdateShield,
  RemoveShield,
  getShieldByUUID,
  shieldAnimate,
  UpdateShieldPos
}
