import {statusBarWrapper} from "./status_bar";
import {Scene} from "../create";
import {createCircleTexture} from "./server_gun_designator";
import {gameStore} from "../store";
import {MapSize} from "../map/createMap";
import {mobileAndTabletCheck} from "../utils/utils";

let joystickState = {
  joystick: null,
  targetJoystick: null,
  targetJoystickConfig: {
    type: 'independent', // fixed, independent,
    pointer: null,
    startPosition: {
      x: 0,
      y: 0,
      currentOffsetX: 0,
      currentOffsetY: 0,
    },
    offset: {
      x: 0,
      y: 0,
    }
  },
  init: false,
  radius: 74,
  offset: 30,
  attackRadius: 50,
  deadZoneRadius: (75 + 50) // radius + attackRadius
}

function initJoystick() {

  if (!statusBarWrapper.scene) return;

  if (!joystickState.init) {
    joystickState.init = true;
    if (mobileAndTabletCheck()) {
      createJoystick()
    }
  }
}

function createJoystick() {
  let base = statusBarWrapper.scene.add.circle(0, 0, joystickState.radius, 0x888888);
  base.setAlpha(0.5)

  let thumb = statusBarWrapper.scene.add.circle(0, 0, joystickState.radius / 2, 0xcccccc);
  thumb.setAlpha(0.5)

  createCircleTexture(Scene, {
    lineStyle: {
      width: 20, color: 0xFFFFFF, alpha: 1
    }, fillStyle: {
      color: 0xFFFFFF, alpha: 1
    }
  }, "targetJoystick")

  joystickState.targetJoystick = Scene.make.image({
    x: Scene.cameras.main.worldView.centerX, y: Scene.cameras.main.worldView.centerY, key: "targetJoystick", add: true
  });
  joystickState.targetJoystick.setOrigin(0.5)
  joystickState.targetJoystick.setAlpha(0.5)
  joystickState.targetJoystick.setDisplaySize(joystickState.attackRadius, joystickState.attackRadius)

  joystickState.joystick = statusBarWrapper.scene.plugins.get('rexvirtualjoystickplugin').add(statusBarWrapper.scene, {
    x: joystickState.radius + joystickState.offset,
    y: statusBarWrapper.scene.cameras.main.displayHeight - joystickState.radius - joystickState.offset,
    radius: joystickState.radius,
    base: base,
    thumb: thumb,
    forceMin: 20,
  })

  Scene.input.addPointer(1);  // total points = num + 1

  // let deadZone = statusBarWrapper.scene.add.circle(joystickState.joystick.x, joystickState.joystick.y - 20, joystickState.deadZoneRadius, 0xffcccc);
  // deadZone.setDepth(-10)
}

function updateTargetJoystick(pos, targetPointer) {

  let deadZone = joystickState.deadZoneRadius > Phaser.Math.Distance.Between(pos.worldX, pos.worldY, joystickState.joystick.x, joystickState.joystick.y - 20)
  if (!deadZone) {

    let x1 = gameStore.fireState.firePosition.x;
    let y1 = gameStore.fireState.firePosition.y;
    let x2 = gameStore.fireState.target.x;
    let y2 = gameStore.fireState.target.y;
    let lockAttack = true;

    if (joystickState.targetJoystick) {

      if (joystickState.targetJoystickConfig.type === 'fixed') {
        joystickState.targetJoystick.setPosition(pos.x + MapSize, pos.y + MapSize)
      } else if (joystickState.targetJoystickConfig.type === 'independent') {

        if (targetPointer && !joystickState.targetJoystickConfig.pointer) {
          joystickState.targetJoystickConfig.pointer = targetPointer
        }

        if (joystickState.targetJoystickConfig.pointer) {
          if (joystickState.targetJoystickConfig.pointer.isDown) {

            joystickState.targetJoystickConfig.startPosition = {
              currentOffsetX: (joystickState.targetJoystickConfig.pointer.downX - joystickState.targetJoystickConfig.pointer.x),
              currentOffsetY: (joystickState.targetJoystickConfig.pointer.downY - joystickState.targetJoystickConfig.pointer.y),
            }

          } else {
            joystickState.targetJoystickConfig.offset = {
              x: joystickState.targetJoystickConfig.offset.x + joystickState.targetJoystickConfig.startPosition.currentOffsetX,
              y: joystickState.targetJoystickConfig.offset.y + joystickState.targetJoystickConfig.startPosition.currentOffsetY,
            }

            joystickState.targetJoystickConfig.startPosition.currentOffsetX = 0;
            joystickState.targetJoystickConfig.startPosition.currentOffsetY = 0;
            joystickState.targetJoystickConfig.pointer = null;
          }
        }

        if (gameStore.userUnit && gameStore.userUnit.sprite) {
          joystickState.targetJoystick.x = gameStore.userUnit.sprite.x - joystickState.targetJoystickConfig.offset.x - joystickState.targetJoystickConfig.startPosition.currentOffsetX;
          joystickState.targetJoystick.y = gameStore.userUnit.sprite.y - joystickState.targetJoystickConfig.offset.y - joystickState.targetJoystickConfig.startPosition.currentOffsetY;
        }

        pos.x = Math.round(joystickState.targetJoystick.x - MapSize)
        pos.y = Math.round(joystickState.targetJoystick.y - MapSize)
      }

      let len = Math.sqrt((y2 - y1) * (y2 - y1) + (y2 - y1) * (y2 - y1))
      let distance = Math.abs((y2 - y1) * pos.x - (x2 - x1) * pos.y + x2 * y1 - x1 * y2) / len;
      lockAttack = distance > (joystickState.attackRadius)

      if (!lockAttack) {
        joystickState.targetJoystick.setTint(0x00ff00)
      } else {
        joystickState.targetJoystick.setTint(0xffffff)
      }
    }

    return {lockAttack: lockAttack, deadZone: deadZone}
  } else {
    return {lockAttack: true, deadZone: deadZone}
  }
}

export {initJoystick, joystickState, updateTargetJoystick}
