import {gameStore} from "../store";
import {AddMoveBufferData} from "../utils/add_move_buffer_data";
import {GetGlobalPos} from "./gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {ShortDirectionRotateTween} from "../utils/utils";
import {UpdateObjectFog} from "../interface/fog_of_war";
import {setPositionObject} from "../interface/mini_map";
import {checkView} from "../update";
import {Scene} from "../create";

function AddObjectMoveBufferData(data) {
  let obj = gameStore.objects[data.id];
  if (obj) {
    AddMoveBufferData(data, obj, 5)
  }
}

function ObjectTo(object, ms) {
  if (!object || !object.objectSprite) return;

  let data = object.bufferMoveTick.shift();
  if (!data) {
    return;
  }

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  data.x = pos.x;
  data.y = pos.y;

  MoveSprite(object.objectSprite, data.x, data.y, ms, false, false, false, processObject(object));

  ShortDirectionRotateTween(object.objectSprite, data.r, ms);

  if (object.objectSprite.weapon) {
    MoveSprite(object.objectSprite.weapon, data.x, data.y, ms);
  }

  if (object.objectSprite.weaponShadow) {
    MoveSprite(object.objectSprite.weaponShadow, data.x + object.objectSprite.xShadow, data.y + object.objectSprite.yShadow, ms);
  }

  if (object.objectSprite.top) {
    MoveSprite(object.objectSprite.top, data.x, data.y, ms);
  }

  if (object.objectSprite.shadowTop) {
    MoveSprite(object.objectSprite.shadowTop, data.x + 5, data.y + 5, ms); // todo hardcode, пока только для щитов
  }

  if (object.objectSprite.light) {
    MoveSprite(object.objectSprite.light, data.x, data.y, ms);
  }

  if (object.objectSprite.shadow) {
    MoveSprite(object.objectSprite.shadow, data.x + object.x_shadow_offset, data.y + object.y_shadow_offset, ms);
    ShortDirectionRotateTween(object.objectSprite.shadow, data.r, ms);
  }
}

function processObject(object) {
  return function () {
    if (!object) return;

    if (object.gameCache) {
      return;
    }

    if (object.work) {
      if (checkView(object.owner_id, object.warrior, object.fraction, object.corporation_id)) {
        UpdateObjectFog(Scene, object, object.objectSprite, "object", object.range_view)
      }
    }

    if (object.build) {
      setPositionObject('structure', object.objectSprite, 130, 130, object.owner_id,
        object.warrior, object.fraction, object.corporation_id, object.work)
    }
  }
}

export {AddObjectMoveBufferData, ObjectTo, processObject}
