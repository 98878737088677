import {gameStore} from "../store";
import {ClearBars} from "../interface/status_layer";

function DestroyBox(id) {
  let box = gameStore.boxes[id];
  if (box) {
    box.gameCache = true
    box.sprite.destroy();
    if (box.shadow) {
      box.shadow.destroy();
    }

    if (box.sprite.RadarMark) box.sprite.RadarMark.destroy();

    ClearBars('box', box.id, 'hp');

    delete gameStore.boxes[id]
  }
}

export {DestroyBox}
