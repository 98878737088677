import {gameStore} from "../store";
import {Scene} from "../create";
import {ShortDirectionRotateTween, ShortDirectionRotateTweenGroup} from "../utils/utils";
import {GetSpriteEquip} from "./equip";

function SetBodyAngle(unit, angle, time, ignoreOldTween, shadowDist, force) {
  if (angle > 180) {
    angle -= 360
  }

  ShortDirectionRotateTween(unit.sprite, angle, time);
}

function RotateUnitGun(id, ms, rotate, slot) {
  if (!gameStore.gameReady) return;

  let unit = gameStore.units[id];
  if (!unit || !unit.sprite || !unit.weapons || !unit.weapons[slot]) return;

  ShortDirectionRotateTweenGroup([unit.weapons[slot].weapon], rotate, ms, unit.weapons[slot].weapon.angle)
}

function RotateWheel(angle, anim) {
  ShortDirectionRotateTweenGroup([anim], angle, 128, anim.angle)
}

function RotateEquip(data) {
  if (!gameStore.gameReady) return;

  let unit = gameStore.units[data.id];

  if (!unit) return;

  let equipSlot = GetSpriteEquip(unit.id, data.ts, data.s);
  if (!equipSlot || !equipSlot.sprite || Math.round(equipSlot.angle) === Math.round(data.r)) {
    return;
  }

  ShortDirectionRotateTweenGroup([equipSlot.sprite], data.r, 32, equipSlot.sprite.angle)
}

export {SetBodyAngle, RotateUnitGun, RotateWheel, RotateEquip}
