import {urls} from '../const'
import Vue from 'vue'
import {gameStore} from "../game/store";
import createSocketPlugin from "./ws/socket";
import {AxiosClient} from "../main";

import {v4 as uuidv4} from 'uuid';

const mutations = {
  reconnectWS(state) {
    let token = state.token
    if (token === "") token = window.localStorage.getItem(urls.authTokenKey)
    if (urls.siteUrl.includes('yandex')) if (!token) return;

    AxiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`

    state.ws.pending = true
    let w = new Worker(new URL('./ws/web_worker_socket.js', import.meta.url));
    w.postMessage({
      event: 'create_ws',
      token: token,
      url: urls.socketURL + "?token=" + token,
    });

    createSocketPlugin(w, 'setWSConnectState', true);
  },
  reconnectNodeWS(state, payload) {
    let token = state.token
    if (token === "") token = window.localStorage.getItem(urls.authTokenKey)
    if (urls.siteUrl.includes('yandex')) if (!token) return;

    state.node_ws.uuid = uuidv4();

    let w = new Worker(new URL('./ws/web_worker_socket.js', import.meta.url));
    w.postMessage({
      event: 'create_ws',
      token: token,
      url: urls.nodeSocketURL + "?token=" + token + "&node_name=" + payload.node,
    });

    createSocketPlugin(w, 'setNodeWSConnectState', false, state.node_ws.uuid);
  },
  setLang(state, payload) {
    Vue.set(state, 'Lang', payload.lang)
  },
  closeWS(state) {
    if (state.ws.ws) {
      state.ws.ws.noRedirect = true
      state.ws.ws.terminate()
    }
  },
  closeNodeWS(state) {
    if (state.node_ws.ws) {
      state.node_ws.ws.terminate()
    }

    state.node_ws.connect = false;
    state.node_ws.error = false;
    state.node_ws.pending = false;
    state.node_ws.ws = null;
    state.node_ws.uuid = '';
  },
  setWSConnectState(state, payload) {
    state.ws.connect = payload.connect;
    state.ws.error = payload.error;
    state.ws.pending = payload.pending;
    state.ws.ws = payload.ws;
  },
  setNodeWSConnectState(state, payload) {
    if (state.node_ws.uuid === payload.uuid) {
      state.node_ws.connect = payload.connect;
      state.node_ws.error = payload.error;
      state.node_ws.pending = payload.pending;
      state.node_ws.ws = payload.ws;
    }
  },
  setToken(state, payload) {
    Vue.set(state, 'token', payload.data);
  },
  addCheckViewPort(state, payload) {
    Vue.set(state, 'CheckViewPort', state.CheckViewPort + 1)
  },
  setResolution(state, payload) {
    Vue.set(state.Interface, 'resolution', $(window).width() + ':' + $(window).height());
  },
  setPlayer(state, payload) {
    Vue.set(state, 'Player', payload.player)
  },
  setPlayerFraction(state, payload) {
    Vue.set(state.Player, 'fraction', payload.fraction)
  },
  setBattleRank(state, payload) {
    Vue.set(state.Player, 'battle_rank', payload.data)
  },
  addNotification(state, payload) {
    let app = this;

    if (!state.Notifications.hasOwnProperty(payload.id)) {
      if (payload.removeSec) {

        payload.newHtml = payload.html.replace(/time_place/gi, payload.removeSec);
        Vue.set(state.Notifications, payload.id, {html: payload.newHtml, time: payload.removeSec});

        let time = setInterval(function () {
          if (state.Notifications.hasOwnProperty(payload.id) && state.Notifications[payload.id].time > 0) {

            let newTime = state.Notifications[payload.id].time - 1;
            payload.newHtml = payload.html.replace(/time_place/gi, newTime);
            Vue.set(state.Notifications, payload.id, {html: payload.newHtml, time: newTime, input: payload.input});
          } else {
            app.commit({
              type: 'removeNotification',
              id: payload.id,
            });
            clearInterval(time)
          }

        }, 1000)
      } else {
        Vue.set(state.Notifications, payload.id, {html: payload.html});
      }

      if (payload.input) {
        Vue.set(state.Notifications[payload.id], 'input', payload.input);
      }
    }
  },
  removeNotification(state, payload) {
    Vue.delete(state.Notifications, payload.id);
  },
  /** WINDOWS MANAGER **/
  setWindowState(state, payload) {

    if (!state.Interface.state) {
      state.Interface.state = {}
    }

    let newResolution = $(window).width() + ':' + $(window).height()
    if (!state.Interface.state[newResolution]) {
      if (!state.Interface.state[state.Interface.resolution]) {
        Vue.set(state.Interface.state, newResolution, {});
      } else {
        Vue.set(state.Interface.state, newResolution, state.Interface.state[state.Interface.resolution]);
      }
    }

    Vue.set(state.Interface, "resolution", newResolution);
    Vue.set(state.Interface.state[state.Interface.resolution], payload.id, payload.state);

    if (payload.state.open) {
      this.commit({
        type: 'setWindowZIndex',
        id: payload.id,
      });
    } else {
      if (!state.Interface.allowIDs.hasOwnProperty(payload.id)) {
        delete state.Interface.state[state.Interface.resolution][payload.id]
      }
    }

  },
  setWindowZIndex(state, payload) {
    let minZ = 5

    let windowIndex = state.Interface.openQueue.indexOf(payload.id);
    if (windowIndex > -1) {
      state.Interface.openQueue.splice(windowIndex, 1);
    }

    let exclude = {"loader": true, "gateBlock": true, "News": true,} // костыль что бы на эти окна не применялась активность

    /** это немного не повьюшному но вот как то так, это позволяет выводить открываемые/выделяемые окна на передний план **/
    if (state.Interface.state && state.Interface.state[state.Interface.resolution]) {
      for (let id in state.Interface.state[state.Interface.resolution]) {
        if (state.Interface.state[state.Interface.resolution].hasOwnProperty(id)) {
          let modal = document.getElementById(id);
          if (modal) {
            let z = state.Interface.openQueue.indexOf(id) + minZ;
            if (z < 1) {
              z = 1;
            }

            if (!exclude.hasOwnProperty(id)) {
              modal.classList.add("window_no_active");
              modal.classList.remove("window_active");
            }

            modal.style.zIndex = z;
          }
        }
      }
    }

    state.Interface.openQueue.push(payload.id)
    if (state.Interface.openQueue.length > 15) {
      state.Interface.openQueue.shift();
    }

    let modal = document.getElementById(payload.id);
    if (modal) {

      if (!exclude.hasOwnProperty(payload.id)) {
        modal.classList.add("window_active");
        modal.classList.remove("window_no_active");
      }

      modal.style.zIndex = state.Interface.openQueue.indexOf(payload.id) + minZ;
    }
  },
  closeTopWindow(state) {
    if (state.Interface.openQueue.length > 0) {
      let topWindow = state.Interface.openQueue[state.Interface.openQueue.length - 1];
      this.commit({
        type: 'toggleWindow',
        id: topWindow,
        forceClose: true,
      });
    }
  },
  toggleWindow(state, payload) {
    if (state.NeedOpenComponents.hasOwnProperty(payload.id)) {
      payload.open = !state.NeedOpenComponents[payload.id].open

      // если окно на заднем плане то делаем его на передний
      if (state.Interface.openQueue[state.Interface.openQueue.length - 1] !== payload.id) {
        payload.open = true;
      }
    } else {
      payload.open = true;
    }

    if (payload.forceOpen) {
      payload.open = true;
    }

    if (payload.forceClose) {
      payload.open = false;
    }

    let window = {
      id: payload.id,
      component: payload.component,
      open: payload.open,
      meta: payload.meta,
    };

    if (payload.open) {
      this.commit({
        type: 'setWindowZIndex',
        id: payload.id,
      });
    } else {
      state.Interface.openQueue.splice(state.Interface.openQueue.indexOf(payload.id), 1);
    }

    Vue.set(state.NeedOpenComponents, payload.id, window);

    if (!payload.open && !state.Interface.allowIDs.hasOwnProperty(payload.id)) {
      Vue.delete(state.NeedOpenComponents, payload.id)
    }
  },
  removeAllWindowsByComponentName(state, payload) {
    for (let i in state.NeedOpenComponents) {
      if (state.NeedOpenComponents.hasOwnProperty(i) && state.NeedOpenComponents[i].component === payload.component) {
        this.commit({
          type: 'toggleWindow',
          id: state.NeedOpenComponents[i].id,
          component: state.NeedOpenComponents[i].component,
          forceClose: true,
        });
      }
    }
  },
  setInterfaceState(state, payload) {

    Vue.set(state.Interface, 'state', payload.user_interface);
    Vue.set(state.Interface, 'allowIDs', payload.allow_window_save);

    if (payload.user_interface) {

      if (!state.Interface.state[state.Interface.resolution]) {
        state.Interface.state[state.Interface.resolution] = {};
      }

      for (let id in payload.user_interface[state.Interface.resolution]) {

        if (!payload.user_interface[state.Interface.resolution].hasOwnProperty(id)) continue;

        Vue.set(state.NeedOpenComponents, id, {
          id: id,
          open: payload.user_interface[state.Interface.resolution][id].open
        });
      }
    }
  },
  setGameSettings(state, payload) {
    console.log(payload.settings)
    Vue.set(state.Settings, 'playMusic', payload.settings.play_music)
    Vue.set(state.Settings, 'volume', payload.settings.volume_music / 100)
    Vue.set(state.Settings, 'SFXVolume', payload.settings.volume_sfx / 100)
    Vue.set(state.Settings, 'FollowCamera', payload.settings.follow_camera)
    Vue.set(state.Settings, 'ZoomCamera', payload.settings.zoom_camera / 100)
    Vue.set(state.Settings, 'UnitTrack', payload.settings.unit_track)
    Vue.set(state.Settings, 'LazyCamera', payload.settings.lazy_camera)
    Vue.set(state.Settings, 'ShowNpcDialog', payload.settings.show_npc_dialog)
    Vue.set(state.Settings, 'ZoomInterface', payload.settings.zoom_interface)
    Vue.set(state.Settings, 'ZoomInterfaceIn', payload.settings.zoom_interface_in)
  },
  setVisibleLoader(state, payload) {
    Vue.set(state.EndLoad, 'visible', payload.visible);
    Vue.set(state.EndLoad, 'text', payload.text);
  },
  updateReservoir(state, payload) {
    if (state.NeedOpenComponents.hasOwnProperty(payload.id) && state.NeedOpenComponents[payload.id].meta) {
      Vue.set(state.NeedOpenComponents[payload.id].meta, 'count', payload.count);
    }
  },

  /** CHAT **/
  setTraining(state, payload) {
    Vue.set(state.training, 'point', payload.training);
  },
  setTrainingIntoHangar(state, payload) {
    Vue.set(state.training, 'needIntoHangar', payload.need);
  },
  setChatState(state, payload) {

    if (payload.groups[payload.currentChatID]) {
      state.Chat.currentChatID = payload.currentChatID;
    } else {
      state.Chat.currentChatID = 0
    }

    state.Chat.groups = payload.groups;
  },
  setGameUserSate(state, payload) {
    state.GameUser = payload.gameUser;
    state.UserPlayers = payload.userPlayers;
  },
  setPlayerGameStatus(state, payload) {
    Vue.set(state.SessionGame, 'playersStatus', payload.playerStatus);
  },
  setCurrentChatIDGroup(state, payload) {
    if (!state.Chat.groups[payload.id]) {
      return
    }

    state.Chat.currentChatID = payload.id;
    Vue.set(state.Chat.groups[payload.id], 'newMessage', false);
  },
  newChatMessage(state, payload) {
    if (!state.Chat.groups[payload.id]) {
      return
    }

    if (!state.Chat.groups[payload.id].history) {
      Vue.set(state.Chat.groups[payload.id], 'history', []);
    }

    if (state.Chat.groups[payload.id].history.length > 50) {
      state.Chat.groups[payload.id].history.shift();
    }

    state.Chat.groups[payload.id].history.push(payload.msg);
    Vue.set(state.Chat.groups[payload.id], 'newMessage', payload.notify);

    if (payload.npc_animate) {

      this.dispatch('playSound', {
        sound: 'npc_message.mp3',
        k: 0.6,
      });

      Vue.set(state.Chat, 'npc_animate', true);
      clearTimeout(state.Chat.npc_animate_timeout)
      state.Chat.npc_animate_timeout = setTimeout(function () {
        Vue.set(state.Chat, 'npc_animate', false);
      }, 15000)
    }
  },
  clearChatGroupHistory(state, payload) {
    if (state.Chat.groups[payload.id]) Vue.set(state.Chat.groups[payload.id], 'history', []);
  },
  setNewMessageChatStateGroup(state, payload) {
    console.log(payload.id, payload.notify)
    Vue.set(state.Chat.groups[payload.id], 'newMessage', payload.notify);
  },
  setAllGroupsInChat(state, payload) {
    Vue.set(state.Chat, 'allGroups', payload.groups);
    Vue.set(state.Chat, 'allGroupsCountPlayers', payload.countPlayers);
  },
  updateChatUsers(state, payload) {
    Vue.set(state.Chat.users, payload.group.id, payload.users);
  },
  addNotify(state, payload) {
    Vue.set(state.Chat.notifys, payload.notify.uuid, payload.notify);
  },
  removeNotify(state, payload) {
    Vue.delete(state.Chat.notifys, payload.uuid);
  },
  setFriends(state, payload) {
    Vue.set(state.Chat, 'friends', payload.friends);
  },
  setGroup(state, payload) {
    Vue.set(state.Chat.group, 'members', payload.members);
    Vue.set(state.Chat.group, 'leaderID', payload.leader);
    Vue.set(state.Chat.group, 'uuid', payload.uuid);
    Vue.set(state.Chat.group, 'online', payload.online);
  },
  setStateGroup(state, payload) {
    Vue.set(state.Chat.group, 'state', payload.state);
  },
  removeAvatar(state, payload) {
    Vue.delete(state.Chat.avatars, payload.id);
  },
  addAvatar(state, payload) {
    Vue.set(state.Chat.avatars, payload.id, {fake: payload.fake, avatar: payload.avatar});
  },
  addCorpLogo(state, payload) {
    Vue.set(state.Chat.corporationsLogo, payload.id, {fake: payload.fake, logo: payload.logo});
  },
  setCorporationState(state, payload) {
    Vue.set(state.Chat.corporation, 'info', payload.info);
    Vue.set(state.Chat.corporation, 'members', payload.members);
    Vue.set(state.Chat.corporation, 'roles', payload.roles);
    Vue.set(state.Chat.corporation, 'inviteRequest', payload.inviteRequest);
    Vue.set(state.Chat.corporation, 'localRights', payload.localRights);
  },
  setCorporationDeposits(state, payload) {
    Vue.set(state.Chat.corporation, 'deposits', payload.deposits);
  },
  setCorporationBases(state, payload) {
    Vue.set(state.Chat.corporation.bases, 'bases', payload.bases);
    Vue.set(state.Chat.corporation.bases, 'resources', payload.resources);
    Vue.set(state.Chat.corporation.bases, 'modules', payload.modules);
    Vue.set(state.Chat.corporation.bases, 'siege', payload.siege);
  },
  setCorporationContacts(state, payload) {
    Vue.set(state.Chat.corporation, 'contacts', payload.contacts);
  },
  setCorporationLocalPolicy(state, payload) {
    Vue.set(state.Chat.corporation, 'localPolicy', payload.localPolicy);
  },
  setAvailableWithdrawDeposits(state, payload) {
    Vue.set(state.Chat.corporation, 'available_withdraw_deposits', payload.deposits);
  },
  addCorporationOffice(state, payload) {
    if (!state.Chat.corporation.offices) {
      Vue.set(state.Chat.corporation, 'offices', {});
    }

    Vue.set(state.Chat.corporation.offices, payload.office.id, payload.office);
  },
  setRentalPrice(state, payload) {
    Vue.set(state.Chat.corporation, 'rental_price', payload.rental_price);
  },
  setDepositHistory(state, payload) {
    Vue.set(state.Chat.corporation, 'deposit_history', payload.deposit_history);
  },
  setLocalRight(state, payload) {
    Vue.set(state.Chat.corporation, 'local_right', payload.local_right);
  },
  setAllCorporations(state, payload) {
    Vue.set(state.Chat.corporations, 'current', payload.corporations);
  },
  setCorporationBaseNames(state, payload) {
    Vue.set(state.Chat, 'corporationBaseNames', payload.names);
  },
  setMySkills(state, payload) {
    Vue.set(state.mySkills, 'skills', payload.skills);
    Vue.set(state.mySkills, 'points', payload.points);
  },
  setExitTime(state, payload) {
    Vue.set(state.Chat, 'exitTime', payload.time);
    Vue.set(state.Chat, 'vioType', payload.vio_type);
    Vue.set(state.Chat, 'vioTime', payload.vio_time);
    Vue.set(state.Chat, 'rankUpdate', payload.rank_update);
  },
  setFractionNews(state, payload) {
    Vue.set(state.Chat, 'fractionNews', payload.news);
  },
  addKillsNotify(state, payload) {
    state.KillsNotify.push(payload.notify)
  },
  addPointsNotify(state, payload) {
    state.PointsNotify.push(payload.notify)
  },
  /** INVENTORY **/
  setCriticalDamage(state, payload) {
    if (payload.cd !== state.CriticalDamage) {
      Vue.set(state, 'CriticalDamage', payload.cd);
    }
  },
  setDamage(state, payload) {
    Vue.set(state, 'Damage', payload.d);
  },
  setInventoryState(state, payload) {

    if (state.Inventory.warehouse === '') {
      Vue.set(state.Inventory, 'warehouse', payload.warehouse);
    }

    if (payload.unit) {
      gameStore.unitState.body = payload.unit.body;
      gameStore.unitState.weapon_slots = payload.unit.weapon_slots;
      gameStore.unitState.equip_slots = payload.unit.equip_slots;
      gameStore.unitState.effects = payload.effects
    }

    Vue.set(state.Inventory, 'inBase', payload.inBase);
    Vue.set(state.Inventory, 'unit', payload.unit);
    Vue.set(state.Inventory, 'squadName', payload.squadName);
    Vue.set(state.Inventory, 'stateMS', payload.stateMS);
    Vue.set(state.Inventory, 'baseSquads', payload.baseSquads);
    Vue.set(state.Inventory, 'effects', payload.effects);

    if (payload.unit) {
      Vue.set(state.Game, 'thorium_slots', payload.unit.thorium_slots);
    }
  },
  setThoriumState(state, payload) {
    if (state.Inventory.unit) {
      Vue.set(state.Game, 'thorium_slots', payload.thorium_slots);
      Vue.set(state.Inventory.unit, 'thorium_slots', payload.thorium_slots);
    }
  },
  updateWarehouse(state, payload) {

    if (!state.Inventory.warehouses) {
      Vue.set(state.Inventory, 'warehouses', {});
    }

    if (!state.Inventory.warehouses.hasOwnProperty(payload.warehouse)) {
      let warehouse = {
        capacity: payload.capacity,
        size: payload.size,
        inventory: payload.inventory,
        title: payload.warehouse,
        remote: payload.remote,
        object_owner: payload.object_owner,
        source_type: payload.source_type,
        source_id: payload.source_id,
      };
      Vue.set(state.Inventory.warehouses, payload.warehouse, warehouse);
    } else {
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'capacity', payload.capacity);
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'size', payload.size);
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'title', payload.warehouse);
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'object_owner', payload.object_owner);
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'remote', payload.remote);
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'inventory', payload.inventory);
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'source_type', payload.source_type);
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'source_id', payload.source_id);
    }

    if (payload.meta) {
      Vue.set(state.Inventory.warehouses[payload.warehouse], 'meta', payload.meta);
    }
  },
  setPlaceDialog(state, payload) {
    if (!(state.NeedOpenComponents['wrapperInventoryAndStorage'] && state.NeedOpenComponents['wrapperInventoryAndStorage'].open)) {
      return
    }

    Vue.set(state.Inventory, 'placeDialog', payload.data);
  },
  removeWarehouse(state, payload) {
    Vue.delete(state.Inventory.warehouses, payload.warehouse);
  },
  setAddSlotDialogData(state, payload) {
    Vue.set(state.Inventory, 'addSlotDialogData', payload.data);
  },
  setCurrentWarehouse(state, payload) {
    Vue.set(state.Inventory, 'warehouse', payload.warehouse);
  },
  setInventoryFilters(state, payload) {
    Vue.set(state.Inventory, 'filters', payload.filters);
  },
  setFindFilter(state, payload) {
    Vue.set(state.Inventory, 'findFilter', payload.filters);
  },
  setSquadConfigs(state, payload) {
    Vue.set(state.Inventory, 'squadConfigs', payload.configs);
  },
  setCheckItems(state, payload) {
    Vue.set(state.Inventory, 'checkItems', payload.items);
  },
  /** Market **/
  SetBaseMarketTax(state, payload) {
    Vue.set(state.Market.base_market_tax, payload.base_id, payload.tax);
  },

  setMarketState(state, payload) {

    for (let id in payload.orders) {
      payload.orders[id].path_jump = payload.orders_distance[id];
    }

    Vue.set(state.Market, 'my_orders', payload.my_orders);
    Vue.set(state.Market, 'orders', payload.orders);
    Vue.set(state.Market, 'preloader', payload.preloader);
  },
  setMarketItemsByFilterName(state, payload) {
    Vue.set(state.Market, 'filterItems', payload.filterItems);
  },
  setMarketAssortment(state, payload) {
    Vue.set(state.Market.assortment, 'ammo', payload.assortment.ammo);
    Vue.set(state.Market.assortment, 'blueprints', payload.assortment.blueprints);
    Vue.set(state.Market.assortment, 'body', payload.assortment.body);
    Vue.set(state.Market.assortment, 'boxes', payload.assortment.boxes);
    Vue.set(state.Market.assortment, 'detail', payload.assortment.detail);
    Vue.set(state.Market.assortment, 'equip', payload.assortment.equip);
    Vue.set(state.Market.assortment, 'recycle', payload.assortment.recycle);
    Vue.set(state.Market.assortment, 'resource', payload.assortment.resource);
    Vue.set(state.Market.assortment, 'trash', payload.assortment.trash);
    Vue.set(state.Market.assortment, 'weapon', payload.assortment.weapon);
    Vue.set(state.Market.assortment, 'product', payload.assortment.product);
    Vue.set(state.Market.assortment, 'fuel', payload.assortment.fuel);
  },

  setCredits(state, payload) {
    if (!state.Market.init_credits) {
      state.Market.init_credits = true
    } else {

      let diff = payload.my_credits - state.Market.my_credits
      if (diff === 0) {
        return
      }

      let text
      if (diff < 0) {
        text = `<span class="importantly" style="color: orangered"> ${(diff / 100).toFixed(2)} cr. </span>`
      } else {
        text = `<span class="importantly" style="color: greenyellow"> +${(diff / 100).toFixed(2)} cr. </span>`
      }

      this.commit({
        type: 'addNotification',
        id: "change_balance_" + diff + Math.random() * 1000,
        removeSec: 5,
        html: text,
      });
    }

    Vue.set(state.Market, 'my_credits', payload.my_credits);
  },

  addOrder(state, payload) {
    if (state.Market.orders) {
      payload.order.path_jump = payload.distance
      Vue.set(state.Market.orders, payload.order.Id, payload.order);
    }
  },

  setHistory(state, payload) {
    Vue.set(state.Market, 'history', payload.history);
  },

  setCountItems(state, payload) {
    Vue.set(state.Market.count_items, payload.id, {count: payload.count, fraction: payload.fraction});
  },

  addMyOrder(state, payload) {
    if (state.Market.my_orders) state.Market.my_orders.push(payload.orderID)
  },

  removeOrder(state, payload) {
    if (state.Market.orders) {
      Vue.delete(state.Market.orders, payload.id)
    }
  },

  updateOrders(state, payload) {
    if (state.Market.orders) {
      for (let order of payload.orders) {
        if (order.Count === 0) {
          Vue.delete(state.Market.orders, order.Id)
        } else {
          order.path_jump = payload.orders_distance[order.Id];
          Vue.set(state.Market.orders, order.Id, order);
        }
      }
    }
  },

  setMarketFilter(state, payload) {
    Vue.set(state.Market.filters, 'selectType', payload.main);
    Vue.set(state.Market.filters, 'item', payload.item);
    if (payload.main !== '' && state.Market.filters[payload.main]) {
      Vue.set(state.Market.filters[payload.main], 'type', payload.filterType);
      Vue.set(state.Market.filters[payload.main], 'size', payload.size);
      Vue.set(state.Market.filters[payload.main], 'id', payload.id);
    }
  },

  setSkins(state, payload) {
    Vue.set(state, 'Skins', payload.data);
  },

  /** WorldMap **/
  removeWorldMapState(state, payload) {
    Vue.set(state.WorldMap, 'maps', null);
  },
  setWorldMapState(state, payload) {
    Vue.set(state.WorldMap, 'maps', payload.maps);
    Vue.set(state.WorldMap, 'userMapID', payload.id);
    Vue.set(state.WorldMap, 'defenders', payload.defenders);
    Vue.set(state.WorldMap, 'regions', payload.regions);
  },
  setSecureHandlers(state, payload) {
    Vue.set(state.WorldMap, 'secureHandlers', payload.secureHandlers);
  },
  updateWorldMapState(state, payload) {
    Vue.set(state.WorldMap, 'userMapID', payload.id);
    for (let i in payload.update_maps) {
      if (state.WorldMap.maps && state.WorldMap.maps.hasOwnProperty(payload.update_maps[i].id)) {
        Vue.set(state.WorldMap.maps[payload.update_maps[i].id], 'battle', payload.update_maps[i].b);
        Vue.set(state.WorldMap.maps[payload.update_maps[i].id], 'fraction', payload.update_maps[i].f);
        Vue.set(state.WorldMap.maps[payload.update_maps[i].id], 'corporation_id', payload.update_maps[i].c);
      }
    }
  },
  setTimeAttack(state, payload) {
    Vue.set(state.WorldMap, 'timeAttack', payload.t);
  },
  updatePriorityWarMap(state, payload) {
    Vue.set(state.PriorityWarMap, 'attack', payload.attack);
    Vue.set(state.PriorityWarMap, 'defend', payload.defend);
  },
  loadMapBackGround(state, payload) {
    let background = new Image();
    background.src = payload.background;
    background.addEventListener("load", function () {
      state.WorldMap.background = background
    })
  },
  setPreviewPath(state, payload) {
    Vue.set(state.WorldMap, 'previewPath', payload.previewPath);
  },
  setMapInfo(state, payload) {
    Vue.set(state.WorldMap.mapInfo, payload.mapID, {bases: payload.bases});
  },
  setGlobalPath(state, payload) {
    Vue.set(state.WorldMap.global_path, 'mapID', payload.mapID);
    Vue.set(state.WorldMap.global_path, 'baseID', payload.baseID);
  },
  setGlobalMission(state, payload) {
    Vue.set(state.WorldMap.global_path, 'mission', payload.mission);
  },
  /** UsersStat **/
  addUserState(state, payload) {
    Vue.set(state.UsersStat.users, payload.player.id, payload.player);
  },

  /** Dialog **/
  setOpenDialog(state, payload) {
    Vue.set(state.OpenDialog, 'page', payload.page);
    Vue.set(state.OpenDialog, 'mission', payload.mission);
    Vue.set(state.OpenDialog, 'visited_pages', payload.visited_pages);
  },

  /** Missions **/
  setMission(state, payload) {

    for (let i in payload.actions) {
      for (let number in payload.need_items_slots) {
        if (payload.actions[i].number === Number(number)) {
          payload.actions[i].need_items_slots = payload.need_items_slots[number];
        }
      }
    }

    Vue.set(state.Missions, 'missions', payload.missions);
    Vue.set(state.Missions, 'currentMissionUUID', payload.mission_uuid);
    Vue.set(state.Missions, 'currentActions', payload.actions);
  },
  updateActionCount(state, payload) {
    if (state.Missions.currentMissionUUID !== payload.mission) {
      return
    }

    for (let i in state.Missions.currentActions) {
      if (state.Missions.currentActions[i].number === payload.action) {
        for (let j in state.Missions.currentActions[i].need_items_slots) {
          if (state.Missions.currentActions[i].need_items_slots[j].number === payload.slot) {
            state.Missions.currentActions[i].need_items_slots[j].find_count = payload.find_count
          }
        }
      }
    }
  },
  setCurrentAction(state, payload) {
    Vue.set(state.Missions, 'currentAction', payload.currentAction);
  },
  setHelper(state, payload) {
    Vue.set(state.Missions, 'helper', payload.helper);
  },
  setPoints(state, payload) {
    Vue.set(state.Missions, 'points', payload.points);
  },
  setAllFullMissions(state, payload) {
    Vue.set(state.AllFullMissions, 'data', payload.data);
  },
  setTrackMission(state, payload) {

    if (!state.AllFullMissions.data || !state.AllFullMissions.data.missions || !state.AllFullMissions.data.missions[payload.data.uuid]) {
      return
    } else {
      Vue.set(state.AllFullMissions.data.missions[payload.data.uuid], 'track', payload.data.track);
    }
  },

  /** Lobby **/
  setBaseFullStatus(state, payload) {
    if (payload.state !== "") Vue.set(state.Lobby.base, 'state', JSON.parse(payload.state));
    if (payload.resources && payload.resources !== "") Vue.set(state.Lobby.base, 'resources', JSON.parse(payload.resources));
    Vue.set(state.Lobby.base, 'stories', payload.stories);
    Vue.set(state.Lobby.base, 'fraction', payload.fraction);
    Vue.set(state.Lobby.base, 'tax', payload.tax);
    Vue.set(state.Lobby.base, 'attr', payload.attr);
    Vue.set(state.Lobby.base, 'base_attr', payload.base_attr);
    Vue.set(state.Lobby.base, 'siege', payload.siege);
  },
  setBaseWorkStatus(state, payload) {
    Vue.set(state.Lobby.base.state, 'type', payload.typeBase);
  },
  setBaseStatus(state, payload) {
    Vue.set(state.Lobby.base, 'state', payload.state);
  },
  setExitBase(state, payload) {
    Vue.set(state.Lobby.base, 'exit', payload.exit);
  },
  setBaseProducts(state, payload) {
    Vue.set(state.Lobby.base, 'products', payload.products);
  },

  /** Workbench **/
  setBlueWork(state, payload) {
    Vue.set(state.Lobby.workbench, 'blue_works', payload.works);
  },
  setSelectBP(state, payload) {
    Vue.set(state.Lobby.workbench.select_bp, 'type_select', payload.type_select);
    Vue.set(state.Lobby.workbench.select_bp, 'blue_print', payload.blue_print);
    Vue.set(state.Lobby.workbench.select_bp, 'bp_item', payload.bp_item);
    Vue.set(state.Lobby.workbench.select_bp, 'preview_recycle_slots', payload.preview_recycle_slots);
    Vue.set(state.Lobby.workbench.select_bp, 'count', payload.count);
    Vue.set(state.Lobby.workbench.select_bp, 'mineral_tax', payload.mineral_tax);
    Vue.set(state.Lobby.workbench.select_bp, 'time_tax', payload.time_tax);
    Vue.set(state.Lobby.workbench.select_bp, 'time', payload.time);
    Vue.set(state.Lobby.workbench.select_bp, 'max_count', payload.max_count);
    Vue.set(state.Lobby.workbench.select_bp, 'storage_slot', payload.storage_slot);
    Vue.set(state.Lobby.workbench.select_bp, 'blue_work', payload.blue_work);
    Vue.set(state.Lobby.workbench.select_bp, 'start_time', payload.start_time);
    Vue.set(state.Lobby.workbench.select_bp, 'to_time', payload.to_time);
  },
  setCountSelectBP(state, payload) {
    Vue.set(state.Lobby.workbench.select_bp, 'count', payload.count);
  },

  /** Recycler **/
  setRecycler(state, payload) {
    Vue.set(state.Lobby.recycler, 'recycle_slots', payload.recycle_slots);
    Vue.set(state.Lobby.recycler, 'preview_recycle_slots', payload.preview_recycle_slots);
    Vue.set(state.Lobby.recycler, 'user_recycle_skill', payload.user_recycle_skill);
    Vue.set(state.Lobby.recycler, 'lost_recycle_slots', payload.lost_recycle_slots);
    Vue.set(state.Lobby.recycler, 'tax_recycle_slots', payload.tax_recycle_slots);
  },

  /** PrefabricatedMenu **/
  setPrefabricatedMenu(state, payload) {
    Vue.set(state.Lobby.prefabricated_menu, 'detail_need_items', payload.detail_need_items);
    Vue.set(state.Lobby.prefabricated_menu, 'detail_max_counts', payload.detail_max_counts);
  },
  setPrefabricatedBpsMenu(state, payload) {
    Vue.set(state.Lobby.prefabricated_menu, 'detail_bps', payload.detail_bps);
  },
  setSelectDetail(state, payload) {
    Vue.set(state.Lobby.prefabricated_menu.select_detail, 'detail', payload.detail);
    Vue.set(state.Lobby.prefabricated_menu.select_detail, 'maxCount', payload.maxCount);
    Vue.set(state.Lobby.prefabricated_menu.select_detail, 'count', payload.count);
  },
  setNeedItemsForSelectDetail(state, payload) {
    Vue.set(state.Lobby.prefabricated_menu.select_detail, 'needItems', payload.needItems);
    Vue.set(state.Lobby.prefabricated_menu, 'tax', payload.tax);
  },
  setCountForSelectDetail(state, payload) {
    Vue.set(state.Lobby.prefabricated_menu.select_detail, 'count', payload.count);
  },

  /** Game **/
  setThoriumSlots(state, payload) {
    Vue.set(state.Game, 'thorium_slots', payload.thorium_slots);
  },
  setLowPower(state, payload) {
    if (state.Game.lowPower === payload.lowPower) return;
    Vue.set(state.Game, 'lowPower', payload.lowPower);
  },
  setGlobalTarget(state, payload) {
    Vue.set(state.Game, 'global_target', payload.global_target);
  },
  setAutoPilot(state, payload) {
    if (state.Inventory.unit.autoPilot !== payload.auto) {
      Vue.set(state.Inventory.unit, 'autoPilot', payload.auto);
    }
  },
  setStateUnit(state, payload) {
    Vue.set(state.Inventory, 'stateMS', payload.state_ms);
  },
  setSector(state, payload) {
    Vue.set(state.Game.sector, 'fraction', payload.fraction);
    Vue.set(state.Game.sector, 'free_land', payload.free_land);
    Vue.set(state.Game.sector, 'possible_battle', payload.possible_battle);
    Vue.set(state.Game.sector, 'transports', payload.transports);
  },
  setDetailSector(state, payload) {
    Vue.set(state.Game.sector, 'detail', payload.data);
  },
  setSectorName(state, payload) {
    Vue.set(state.Game.sector, 'name', payload.name);
    Vue.set(state.Game.sector, 'id', payload.id);
  },
  setAnomalies(state, payload) {
    Vue.set(state.Game, 'anomalies', payload.anomalies);
  },
  addOpenObjects(state, payload) {
    Vue.set(state.Game.open_objects, payload.id, {
      dynamic_object: payload.dynamic_object,
      other_user: payload.other_user,
      box: payload.box,
      inventory: payload.inventory,
      size: payload.size,
    });
  },
  setBoxToOpenObjects(state, payload) {
    for (let i in state.Game.open_objects) {
      if (state.Game.open_objects[i].box.id === payload.id) {
        state.Game.open_objects[i].box.capacity_size = payload.capacity_size
        state.Game.open_objects[i].box.inventory_size = payload.inventory_size
        state.Game.open_objects[i].size = payload.inventory_size
      }
    }
  },
  setInventoryToOpenObjects(state, payload) {
    console.log(1)
    for (let i in state.Game.open_objects) {
      console.log(2, i)
      if (state.Game.open_objects[i] && state.Game.open_objects[i].box && payload && state.Game.open_objects[i].box.id === payload.id) {
        console.log(3)
        Vue.set(state.Game.open_objects[i], 'inventory', payload.inventory);
        Vue.set(state.Game.open_objects[i], 'size', payload.size);
      }
    }
  },
  setPlayerRole(state, payload) {
    Vue.set(state.Game, 'role', payload.role);
  },
  setWars(state, payload) {
    Vue.set(state, 'Wars', payload.data);
  },
  addHeadquartersName(state, payload) {
    Vue.set(state.HeadquartersNames, payload.data.id, payload.data);
  },
  setCorporationLogs(state, payload) {
    Vue.set(state, 'CorporationLogs', payload.logs);
  },
  /** Build Menu **/
  setBuildMenuBps(state, payload) {
    Vue.set(state.BuildMenu, 'points', payload.points);
    Vue.set(state.BuildMenu, 'maxEnergy', payload.maxEnergy);
    Vue.set(state.BuildMenu, 'currentEnergy', payload.currentEnergy);
    Vue.set(state.BuildMenu, 'currentLimit', payload.currentLimit);
  },
  setSelectDestroyTarget(state, payload) {
    Vue.set(state.BuildMenu, 'selectDestroyTarget', payload.data);
  },
  setOverBP(state, payload) {
    Vue.set(state.BuildMenu, 'overBP', payload.id);
  },
  setBuildExpandTip(state, payload) {
    Vue.set(state.BuildMenu, 'expandTip', payload.expand);
  },
  setBuildSelectBPID(state, payload) {
    Vue.set(state.BuildMenu, 'selectBPID', payload.id);
  },

  /** ammo reload **/
  setAvailableAmmo(state, payload) {
    Vue.set(state.Game, 'availableAmmo', payload.availableAmmo)
  },

  /** Map editor **/
  setMapsInMapEditor(state, payload) {
    Vue.set(state.MapEditor, 'maps', payload.maps)
  },

  setTypeCoordinates(state, payload) {
    Vue.set(state.MapEditor, 'typeCoordinates', payload.typeCoordinates)
  },

  /** Dialog editor **/
  setDialogsInDialogEditor(state, payload) {
    Vue.set(state.DialogEditor, 'dialogs', payload.dialogs)
  },

  /** Mission editor **/
  setMissionInMissionEditor(state, payload) {
    Vue.set(state.MissionEditor, 'missions', payload.missions)
    Vue.set(state.MissionEditor, 'items', payload.items)
    Vue.set(state.MissionEditor, 'actions', payload.actions)
    Vue.set(state.MissionEditor, 'rewardItems', payload.rewardItems)
  },

  /** Volume, SFXVolume **/
  setVolume(state, payload) {
    Vue.set(state.Settings, 'volume', payload.volume)
  },
  setSFXVolume(state, payload) {
    Vue.set(state.Settings, 'SFXVolume', payload.SFXVolume)
  },
  setLanguage(state, payload) {
    Vue.set(state.Settings, 'Language', payload.language)
  },
  setFollowCamera(state, payload) {
    Vue.set(state.Settings, 'FollowCamera', payload.follow)
  },
  setLazyCamera(state, payload) {
    Vue.set(state.Settings, 'LazyCamera', payload.lazy_camera)
  },
  setZoomCamera(state, payload) {
    Vue.set(state.Settings, 'ZoomCamera', payload.zoom)
  },
  setUnitTrack(state, payload) {
    Vue.set(state.Settings, 'UnitTrack', payload.UnitTrack)
  },
  setShowNpcDialog(state, payload) {
    Vue.set(state.Settings, 'ShowNpcDialog', payload.ShowNpcDialog)
  },
  setZoomInterface(state, payload) {
    Vue.set(state.Settings, 'ZoomInterface', payload.zi)
  },
  setZoomInterfaceIn(state, payload) {
    Vue.set(state.Settings, 'ZoomInterfaceIn', payload.zi)
  },
  /** ServerState **/
  setServerState(state, payload) {
    //Vue.set(state, 'ServerState', payload.data);

    state.ServerState = payload.data
    // Vue.set(state.ServerState, 'count_bots', payload.data.count_bots);
    // Vue.set(state.ServerState, 'count_players', payload.data.count_players);
    // Vue.set(state.ServerState, 'count_calc_move', payload.data.count_calc_move);
    // Vue.set(state.ServerState, 'count_bullets', payload.data.count_bullets);
    // Vue.set(state.ServerState, 'count_objects', payload.data.count_objects);
    // Vue.set(state.ServerState, 'count_transports', payload.data.count_transports);
    // Vue.set(state.ServerState, 'count_reservoirs', payload.data.count_reservoirs);
    // Vue.set(state.ServerState, 'count_boxes', payload.data.count_boxes);
    // Vue.set(state.ServerState, 'count_signals', payload.data.count_signals);
    //
    // Vue.set(state.ServerState, 'ai_move', payload.data.ai_move);
    // Vue.set(state.ServerState, 'meteorite_wait_end', payload.data.meteorite_wait_end);
    // Vue.set(state.ServerState, 'maps_ticks', payload.data.maps_ticks);
    // Vue.set(state.ServerState, 'players', payload.data.players);
    // Vue.set(state.ServerState, 'maps', payload.data.maps);
    // Vue.set(state.ServerState, 'short_info_map', payload.data.short_info_map);
    // Vue.set(state.ServerState, 'maps_dynamic_objects', payload.data.maps_dynamic_objects);
    // Vue.set(state.ServerState, 'maps_reservoirs', payload.data.maps_reservoirs);
    // Vue.set(state.ServerState, 'maps_geo_zones', payload.data.maps_geo_zones);
    // Vue.set(state.ServerState, 'maps_boxes', payload.data.maps_boxes);
    // Vue.set(state.ServerState, 'maps_bullets', payload.data.maps_bullets);
    // Vue.set(state.ServerState, 'maps_units', payload.data.maps_units);
    // Vue.set(state.ServerState, 'maps_evacuations', payload.data.maps_evacuations);
    // Vue.set(state.ServerState, 'maps_signals', payload.data.maps_signals);
  },
  setHandBook(state, payload) {
    Vue.set(state, 'HandBook', payload.description_items)
  },
  setInterfaceHandBook(state, payload) {
    Vue.set(state, 'InterfaceHandBook', payload.interface_handbook)
  },
  setBotsState(state, payload) {
    Vue.set(state.BotsState, 'bots', payload.bots);
  },
  setBotInfo(state, payload) {
    Vue.set(state.BotsState, 'bot', payload.bot);
  },
  /** FRACTION STORE **/
  setFractionStoreAssortment(state, payload) {
    Vue.set(state.FractionStore, 'assortment', payload.assortment)
  },
  setFractionStoreUserPoints(state, payload) {
    Vue.set(state.FractionStore, 'user_fraction_points', payload.user_fraction_points)
  },
  /** SEARCH **/
  setSearch(state, payload) {
    Vue.set(state, 'Search', payload.search)
  },
  SetProductsType(state, payload) {
    Vue.set(state, 'Products', payload.products)
  },
  /** && **/
  SetInit(state, payload) {
    Vue.set(state.init, payload.id, true)
  },
  SetRelateBonuses(state, payload) {
    Vue.set(state, 'RelateBonuses', payload.data)
  },
  AddError(state, payload) {
    Vue.set(state.Errors, payload.error, {active: payload.active, data: payload.data})
  },
  setHelpSelect(state, payload) {
    Vue.set(state, 'HelpSelect', payload.data)
  },
  setFillUpState(state, payload) {
    Vue.set(state.FillUp, 'need', payload.need)
    Vue.set(state.FillUp, 'price', payload.price)
  },
  setThrowItemsState(state, payload) {
    Vue.set(state.ThrowItems, 'open', payload.open)
    Vue.set(state.ThrowItems, 'slots', payload.slots)
    Vue.set(state.ThrowItems, 'items', payload.items)
    Vue.set(state.ThrowItems, 'source', payload.source)
  },
  setArmState(state, payload) {
    Vue.set(state.Arm, 'need', payload.need)
    Vue.set(state.Arm, 'price', payload.price)
    Vue.set(state.Arm, 'step', payload.step)
    Vue.set(state.Arm, 'ammo_id', payload.ammo_id)
  },
  setOpenBuildObject(state, payload) {
    Vue.set(state.OpenBuildObject, 'object_id', payload.object_id)
    Vue.set(state.OpenBuildObject, 'base_id', payload.base_id)
    Vue.set(state.OpenBuildObject, 'current_resources', payload.current_resources)
  },
  setAttackDialog(state, payload) {
    Vue.set(state, 'AttackDialog', payload.data)
  },
  setTrailer(state, payload) {
    Vue.set(state, 'Trailer', {
      open: payload.open,
      sound: payload.sound,
      url: payload.url,
      glitchOff: payload.glitchOff,
      mission_uuid: payload.mission_uuid,
      action_number: payload.action_number,
    })
  },
  setGlitch(state, payload) {
    Vue.set(state, 'Glitch', {x: payload.f, y: payload.f})
  },
  setTargetData(state, payload) {

    // проврека что это не новый диалог
    if (state.TargetData.history && state.TargetData.history.length > 0 && state.TargetData.owner_id === payload.data.owner_id) {
      payload.data.history = state.TargetData.history
      payload.data.overload_ascs = state.TargetData.overload_ascs
    } else {
      payload.data.history = []
      payload.data.overload_ascs = []
    }

    Vue.set(state, 'TargetData', payload.data)
  },
  addMsgInTargetData(state, payload) {
    if (payload.data.new) {
      Vue.set(state.TargetData, 'history', [])
    }

    state.TargetData.history.push(payload.data)
  },
  addAscsInTargetData(state, payload) {
    state.TargetData.overload_ascs = payload.ascs
    state.TargetData.overload_ascs_uuid = payload.uuid
  },
  setNotOpenMails(state, payload) {
    Vue.set(state.Mails, 'notOpenMails', payload.count)
  },
  setUpdateMails(state, payload) {
    Vue.set(state.Mails, 'update', payload.update)
  },
  setSteam(state, payload) {
    Vue.set(state, 'Steam', {steam: payload.steam, status: payload.status})
  },
  /**/
  setSynchronization(state, payload) {
    payload.state.bonuses = payload.bonuses
    Vue.set(state.SynchronizationState, payload.state.body_id, payload.state)
  },
  addDamageLog(state, payload) {
    if (state.DamageLog.length > 500) {
      state.DamageLog.shift();
    }

    state.DamageLog.push(payload.data)
  },
  addSystemError(state, payload) {
    state.systemErrors.push(payload.error)
  }
};

export default mutations;
