import {gameStore} from "../store";
import {DrawMissionPath, minimap, RenderGlobalPathInMiniMap} from "../interface/mini_map";
import {Scene} from "../create";

const timeOut = 256;
let tasks = {
  lastRun: Date.now(),
  timer: null,
  updaters: {},
  full: true// предметы, которые двигались и их надо проверить
}

function ClientWatch(updaters) {

  tasks.full = tasks.full || !updaters
  let date = Date.now();
  if (Math.abs(tasks.lastRun - date) < timeOut) {
    if (tasks.timer) tasks.timer.remove();
    tasks.timer = Scene.time.addEvent({
      delay: timeOut * 1.5,
      callback: function () {
        ClientWatch(updaters)
      }
    })
    return
  }

  tasks.lastRun = date

  RenderGlobalPathInMiniMap(gameStore.userUnit);
  DrawMissionPath(gameStore.userUnit);

  let children = [];
  let miniMap = [];
  // bullet обрабатываются в методе движения, т.к. они всегда в движение

  if (tasks.full) {
    tasks.full = false
    // собираем эти спрайты, делаем кул и в зависимости от уже делаем свитч визибл
    viewUnits(children, miniMap);
    viewDrones(children, miniMap);
    viewBoxes(children, miniMap);
    viewObjects(children, miniMap);
    viewItems(children, miniMap);
    viewPollens(children, miniMap);
    viewClouds(children, miniMap);
    viewMarks(children, miniMap);
    viewShields(children, miniMap);
    viewReservoirs(children, miniMap);
    viewBars(children, miniMap);

    // console.log(1)
  } else {
    for (let i in tasks.updaters) {
      if (tasks.updaters[i].typeCam === 1) {
        children.push(tasks.updaters[i].sprite)
      } else {
        miniMap.push(tasks.updaters[i].sprite)
      }
    }

    // console.log(2)
  }

  // console.log("main:", children.length, "mini:", miniMap.length)
  // внешняя камера
  if (minimap.backRenderTexture) {
    viewCamera(minimap.backRenderTexture, miniMap)
  }

  // внутреняя
  viewCamera(Scene.cameras.main, children)
  tasks.updaters = []
  // console.log(Scene.children.getChildren())
}

function UpdateWatch(obj, sprite, typeObject, typeCam) {
  if (!sprite) return;
  // этот метод обновляет объекты которые двигаются без вмешательства юзера
  if (typeObject === 1) sprite.viewCallback = viewUnit(obj) // unit +
  if (typeObject === 2) sprite.viewCallback = viewDrone(obj) // drone +
  if (typeObject === 4) sprite.viewCallback = viewBox(obj) // box +
  if (typeObject === 5) sprite.viewCallback = viewObject(obj) // object +
  if (typeObject === 6) sprite.viewCallback = viewItem(obj) // map_item +
  if (typeObject === 7) sprite.viewCallback = viewPollen(obj) // pollens +
  if (typeObject === 8) sprite.viewCallback = viewMark(obj) // mark +
  if (typeObject === 9) sprite.viewCallback = viewCloud(obj) // cloud +
  if (typeObject === 10) sprite.viewCallback = viewShield(obj) // shield +
  if (typeObject === 11) sprite.viewCallback = viewBar(obj) // bar +

  let id = obj.id ? obj.id : obj.uuid
  if (!id) {
    id = Math.random() * 100;
  }

  let key = id + "" + typeObject

  tasks.updaters[key] = {
    sprite: sprite,
    typeCam: typeCam,
  }

  if (sprite.RadarMark) {
    tasks.updaters["m" + key] = {
      sprite: sprite.RadarMark,
      typeCam: 2,
    }
  }

  ClientWatch(true)
}

// function viewCamera(cam, children) {
//   for (let child of children) {
//     child.viewCallback(false)
//   }
//
//   children = cam.cull(children)
//   for (let child of children) {
//     child.viewCallback(true)
//   }
// }

function viewCamera(cam, children) {

  let cameraMatrix = cam.matrix.matrix;

  let mva = cameraMatrix[0];
  let mvb = cameraMatrix[1];
  let mvc = cameraMatrix[2];
  let mvd = cameraMatrix[3];

  let determinant = (mva * mvd) - (mvb * mvc);
  if (!determinant) {
    return;
  }

  let mve = cameraMatrix[4];
  let mvf = cameraMatrix[5];

  let scrollX = cam.scrollX;
  let scrollY = cam.scrollY;

  let cameraW = cam.width;
  let cameraH = cam.height;

  let cullTop = cam.y;
  let cullBottom = cullTop + cameraH;
  let cullLeft = cam.x;
  let cullRight = cullLeft + cameraW;

  for (let index = 0; index < children.length; ++index) {
    let object = children[index];

    if (!object.hasOwnProperty('width') || object.parentContainer) {
      object.viewCallback(true)
      continue;
    }

    let objectW = object.width;
    let objectH = object.height;
    let objectX = (object.x - (scrollX * object.scrollFactorX)) - (objectW * object.originX);
    let objectY = (object.y - (scrollY * object.scrollFactorY)) - (objectH * object.originY);
    let tx = (objectX * mva + objectY * mvc + mve);
    let ty = (objectX * mvb + objectY * mvd + mvf);
    let tw = ((objectX + objectW) * mva + (objectY + objectH) * mvc + mve);
    let th = ((objectX + objectW) * mvb + (objectY + objectH) * mvd + mvf);

    let v = (tw > cullLeft && tx < cullRight) && (th > cullTop && ty < cullBottom)
    object.viewCallback(v)
  }
}

function viewItems(arr, marks) {
  for (let i in gameStore.mapItems) {
    gameStore.mapItems[i].sprite.viewCallback = viewItem(gameStore.mapItems[i])
    arr.push(gameStore.mapItems[i].sprite)
    if (gameStore.mapItems[i].sprite.RadarMark) marks.push(gameStore.mapItems[i].sprite.RadarMark)
  }
}

function viewItem(i) {
  return function (v) {
    if (i.gameCache) return;

    i.sprite.visible = v
    i.shadow.visible = v
    i.light.visible = v
  }
}

function viewObjects(arr, marks) {
  for (let i in gameStore.objects) {
    let obj = gameStore.objects[i];
    if (!obj) continue;

    obj.objectSprite.viewCallback = viewObject(obj)
    arr.push(obj.objectSprite)
    if (obj.objectSprite.RadarMark) marks.push(obj.objectSprite.RadarMark)
  }

  if (gameStore.map && gameStore.map.id && gameStore.mapsState[gameStore.map.id] && gameStore.mapsState[gameStore.map.id].staticObjects) {
    for (let i in gameStore.mapsState[gameStore.map.id].staticObjects) {
      let obj = gameStore.mapsState[gameStore.map.id].staticObjects[i];
      if (!obj || !obj.objectSprite) continue;

      obj.objectSprite.viewCallback = viewObject(obj)
      marks.push(obj.objectSprite)
    }
  }
}

function viewObject(obj) {
  return function (v) {

    if (obj.gameCache) return;

    obj.objectSprite.visible = v
    if (obj.objectSprite.shadow) obj.objectSprite.shadow.visible = obj.objectSprite.visible
    if (obj.objectSprite.weapon) obj.objectSprite.weapon.visible = obj.objectSprite.visible
    if (obj.objectSprite.weaponShadow) obj.objectSprite.weaponShadow.visible = obj.objectSprite.visible
    if (obj.objectSprite.top) obj.objectSprite.top.visible = obj.objectSprite.visible
    if (obj.objectSprite.shadowTop) obj.objectSprite.shadowTop.visible = obj.objectSprite.visible
    if (obj.objectSprite.emitter) obj.objectSprite.emitter.visible = obj.objectSprite.visible
  }
}

function viewClouds(arr, marks) {
  for (let i in gameStore.clouds) {
    gameStore.clouds[i].viewCallback = viewCloud(gameStore.clouds[i])
    arr.push(gameStore.clouds[i])
  }
}

function viewCloud(c) {
  return function (v) {
    if (c.gameCache) return;
    c.visible = v
  }
}

function viewPollens(arr, marks) {
  for (let i in gameStore.pollens) {
    gameStore.pollens[i].sprite.viewCallback = viewPollen(gameStore.pollens[i])
    arr.push(gameStore.pollens[i].sprite)
    if (gameStore.pollens[i].sprite.RadarMark) marks.push(gameStore.pollens[i].sprite.RadarMark)
  }
}

function viewPollen(p) {
  return function (v) {
    if (p.gameCache) return;
    p.sprite.visible = v
    if (p.circle) p.circle.visible = v
    if (p.wortex) p.wortex.visible = v
  }
}

function viewUnits(arr, marks) {
  for (let i in gameStore.units) {
    gameStore.units[i].sprite.viewCallback = viewUnit(gameStore.units[i])
    arr.push(gameStore.units[i].sprite)
    if (gameStore.units[i].sprite.RadarMark) marks.push(gameStore.units[i].sprite.RadarMark)
  }
}

function viewUnit(unit) {
  return function (v) {

    // if (unit.id === gameStore.user_squad_id) {
    //   console.log(unit.gameCache)
    // }

    if (unit.gameCache) return;

    unit.sprite.visible = v
    if (unit.sprite.bodyBottom) unit.sprite.bodyBottom.visible = v
    if (unit.sprite.bodyBottomRight) unit.sprite.bodyBottomRight.visible = v
    if (unit.sprite.bodyBottomLeft) unit.sprite.bodyBottomLeft.visible = v
    if (unit.sprite.antigravityPlace) unit.sprite.antigravityPlace.visible = v
    if (unit.sprite.shadow) unit.sprite.shadow.visible = v
    if (unit.sprite.bodyBottomLeftShadow) unit.sprite.bodyBottomLeftShadow.visible = v
    if (unit.sprite.bodyBottomRightShadow) unit.sprite.bodyBottomRightShadow.visible = v

    for (let i in unit.weapons) {
      unit.weapons[i].weapon.visible = v
    }

    for (let i in unit.equips) {
      unit.equips[i].sprite.visible = v
    }

    for (let i in unit.lights) {
      unit.lights[i].visible = unit.light && v
    }

    if (unit.sprite.backEngine) unit.sprite.backEngine.visible = v
    if (unit.sprite.rightEngine) unit.sprite.rightEngine.visible = v
    if (unit.sprite.leftEngine) unit.sprite.leftEngine.visible = v
    if (unit.sprite.antigravityPlace) unit.sprite.antigravityPlace.visible = v

    if (unit.sprite.userName) {
      if (unit.sprite.userName.pointer) unit.sprite.userName.pointer.visible = v
      if (unit.sprite.userName.login) unit.sprite.userName.login.visible = v
      if (unit.sprite.userName.attitude) unit.sprite.userName.attitude.visible = v
    }
  }
}

function viewDrones(arr, marks) {
  for (let i in gameStore.drones) {
    gameStore.drones[i].sprite.viewCallback = viewDrone(gameStore.drones[i])
    arr.push(gameStore.drones[i].sprite)
    if (gameStore.drones[i].sprite.RadarMark) marks.push(gameStore.drones[i].sprite.RadarMark)
  }
}

function viewDrone(d) {
  return function (v) {

    if (d.gameCache) return;

    d.sprite.visible = v
    d.sprite.shadow.visible = v

    for (let k in d.sprite.engages) {
      if (d.sprite.engages[k].emitter) {
        d.sprite.engages[k].emitter.visible = v
      }
    }
  }
}

function viewMarks(arr, marks) {
  for (let i in gameStore.radar_marks) {
    gameStore.radar_marks[i].sprite.viewCallback = viewMark(gameStore.radar_marks[i])
    arr.push(gameStore.radar_marks[i].sprite)
    if (gameStore.radar_marks[i].sprite.RadarMark) marks.push(gameStore.radar_marks[i].sprite.RadarMark)
  }
}

function viewMark(mark) {
  return function (v) {
    if (mark.gameCache) return;
    mark.sprite.visible = v
  }
}

function viewBoxes(arr, marks) {
  for (let i in gameStore.boxes) {
    gameStore.boxes[i].sprite.viewCallback = viewBox(gameStore.boxes[i])
    arr.push(gameStore.boxes[i].sprite)
    if (gameStore.boxes[i].sprite.RadarMark) marks.push(gameStore.boxes[i].sprite.RadarMark)
  }
}

function viewBox(b) {
  return function (v) {
    if (b.gameCache) return;
    b.sprite.visible = v
    if (b.shadow) b.shadow.visible = v
  }
}

function viewShields(arr, marks) {
  for (let i in gameStore.shields) {
    gameStore.shields[i].sprite.viewCallback = viewShield(gameStore.shields[i])
    marks.push(gameStore.shields[i].sprite)
  }
}

function viewShield(s) {
  return function (v) {
    if (s.gameCache) return;
    s.sprite.visible = v
    s.border.visible = v
  }
}

function viewReservoirs(arr, marks) {
  for (let i in gameStore.reservoirs) {
    gameStore.reservoirs[i].sprite.viewCallback = viewReservoir(gameStore.reservoirs[i])
    arr.push(gameStore.reservoirs[i].sprite)
    if (gameStore.reservoirs[i].sprite.RadarMark) marks.push(gameStore.reservoirs[i].sprite.RadarMark)
  }
}

function viewReservoir(r) {
  return function (v) {
    if (r.gameCache) return;
    r.sprite.visible = v
    if (r.crackSprite) r.crackSprite.visible = v
    if (r.sprite.shadow) r.sprite.shadow.visible = v
  }
}

function viewBars(arr, marks) {
  for (let i in gameStore.StatusLayer.bars) {
    gameStore.StatusLayer.bars[i].bar.viewCallback = viewBar(gameStore.StatusLayer.bars[i])
    arr.push(gameStore.StatusLayer.bars[i].bar)
  }
}

function viewBar(b) {
  return function (v) {
    if (b.gameCache) return;
    b.bar.visible = v
  }
}

export {ClientWatch, UpdateWatch, viewUnit}
