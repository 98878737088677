import store from "../src/store/store";

$.widget("ui.resizable", $.ui.mouse, {
  version: "1.13.3",
  widgetEventPrefix: "resize",
  options: {
    alsoResize: false,
    animate: false,
    animateDuration: "slow",
    animateEasing: "swing",
    aspectRatio: false,
    autoHide: false,
    classes: {
      "ui-resizable-se": "ui-icon ui-icon-gripsmall-diagonal-se"
    },
    containment: false,
    ghost: false,
    grid: false,
    handles: "e,s,se",
    helper: false,
    maxHeight: null,
    maxWidth: null,
    minHeight: 10,
    minWidth: 10,

    // See #7960
    zIndex: 90,

    // Callbacks
    resize: null,
    start: null,
    stop: null
  },

  _num: function (value) {
    return parseFloat(value) || 0;
  },

  _isNumber: function (value) {
    return !isNaN(parseFloat(value));
  },

  _hasScroll: function (el, a) {

    if ($(el).css("overflow") === "hidden") {
      return false;
    }

    var scroll = (a && a === "left") ? "scrollLeft" : "scrollTop",
      has = false;

    if (el[scroll] > 0) {
      return true;
    }

    // TODO: determine which cases actually cause this to happen
    // if the element doesn't have the scroll set, see if it's possible to
    // set the scroll
    try {
      el[scroll] = 1;
      has = (el[scroll] > 0);
      el[scroll] = 0;
    } catch (e) {

      // `el` might be a string, then setting `scroll` will throw
      // an error in strict mode; ignore it.
    }
    return has;
  },

  _create: function () {

    var margins,
      o = this.options,
      that = this;
    this._addClass("ui-resizable");

    $.extend(this, {
      _aspectRatio: !!(o.aspectRatio),
      aspectRatio: o.aspectRatio,
      originalElement: this.element,
      _proportionallyResizeElements: [],
      _helper: o.helper || o.ghost || o.animate ? o.helper || "ui-resizable-helper" : null
    });

    // Wrap the element if it cannot hold child nodes
    if (this.element[0].nodeName.match(/^(canvas|textarea|input|select|button|img)$/i)) {

      this.element.wrap(
        $("<div class='ui-wrapper'></div>").css({
          overflow: "hidden",
          position: this.element.css("position"),
          width: this.element.outerWidth(),
          height: this.element.outerHeight(),
          top: this.element.css("top"),
          left: this.element.css("left")
        })
      );

      this.element = this.element.parent().data(
        "ui-resizable", this.element.resizable("instance")
      );

      this.elementIsWrapper = true;

      margins = {
        marginTop: this.originalElement.css("marginTop"),
        marginRight: this.originalElement.css("marginRight"),
        marginBottom: this.originalElement.css("marginBottom"),
        marginLeft: this.originalElement.css("marginLeft")
      };

      this.element.css(margins);
      this.originalElement.css("margin", 0);

      // support: Safari
      // Prevent Safari textarea resize
      this.originalResizeStyle = this.originalElement.css("resize");
      this.originalElement.css("resize", "none");

      this._proportionallyResizeElements.push(this.originalElement.css({
        position: "static",
        zoom: 1,
        display: "block"
      }));

      // Support: IE9
      // avoid IE jump (hard set the margin)
      this.originalElement.css(margins);

      this._proportionallyResize();
    }

    this._setupHandles();

    if (o.autoHide) {
      $(this.element)
        .on("mouseenter", function () {
          if (o.disabled) {
            return;
          }
          that._removeClass("ui-resizable-autohide");
          that._handles.show();
        })
        .on("mouseleave", function () {
          if (o.disabled) {
            return;
          }
          if (!that.resizing) {
            that._addClass("ui-resizable-autohide");
            that._handles.hide();
          }
        });
    }

    this._mouseInit();
  },

  _destroy: function () {

    this._mouseDestroy();
    this._addedHandles.remove();

    var wrapper,
      _destroy = function (exp) {
        $(exp)
          .removeData("resizable")
          .removeData("ui-resizable")
          .off(".resizable");
      };

    // TODO: Unwrap at same DOM position
    if (this.elementIsWrapper) {
      _destroy(this.element);
      wrapper = this.element;
      this.originalElement.css({
        position: wrapper.css("position"),
        width: wrapper.outerWidth(),
        height: wrapper.outerHeight(),
        top: wrapper.css("top"),
        left: wrapper.css("left")
      }).insertAfter(wrapper);
      wrapper.remove();
    }

    this.originalElement.css("resize", this.originalResizeStyle);
    _destroy(this.originalElement);

    return this;
  },

  _setOption: function (key, value) {
    this._super(key, value);

    switch (key) {
      case "handles":
        this._removeHandles();
        this._setupHandles();
        break;
      case "aspectRatio":
        this._aspectRatio = !!value;
        break;
      default:
        break;
    }
  },

  _setupHandles: function () {
    var o = this.options, handle, i, n, hname, axis, that = this;
    this.handles = o.handles ||
      (!$(".ui-resizable-handle", this.element).length ?
        "e,s,se" : {
          n: ".ui-resizable-n",
          e: ".ui-resizable-e",
          s: ".ui-resizable-s",
          w: ".ui-resizable-w",
          se: ".ui-resizable-se",
          sw: ".ui-resizable-sw",
          ne: ".ui-resizable-ne",
          nw: ".ui-resizable-nw"
        });

    this._handles = $();
    this._addedHandles = $();
    if (this.handles.constructor === String) {

      if (this.handles === "all") {
        this.handles = "n,e,s,w,se,sw,ne,nw";
      }

      n = this.handles.split(",");
      this.handles = {};

      for (i = 0; i < n.length; i++) {

        handle = String.prototype.trim.call(n[i]);
        hname = "ui-resizable-" + handle;
        axis = $("<div>");
        this._addClass(axis, "ui-resizable-handle " + hname);

        axis.css({zIndex: o.zIndex});

        this.handles[handle] = ".ui-resizable-" + handle;
        if (!this.element.children(this.handles[handle]).length) {
          this.element.append(axis);
          this._addedHandles = this._addedHandles.add(axis);
        }
      }

    }

    this._renderAxis = function (target) {

      var i, axis, padPos, padWrapper;

      target = target || this.element;

      for (i in this.handles) {

        if (this.handles[i].constructor === String) {
          this.handles[i] = this.element.children(this.handles[i]).first().show();
        } else if (this.handles[i].jquery || this.handles[i].nodeType) {
          this.handles[i] = $(this.handles[i]);
          this._on(this.handles[i], {"mousedown": that._mouseDown});
        }

        if (this.elementIsWrapper &&
          this.originalElement[0]
            .nodeName
            .match(/^(textarea|input|select|button)$/i)) {
          axis = $(this.handles[i], this.element);

          padWrapper = /sw|ne|nw|se|n|s/.test(i) ?
            axis.outerHeight() :
            axis.outerWidth();

          padPos = ["padding",
            /ne|nw|n/.test(i) ? "Top" :
              /se|sw|s/.test(i) ? "Bottom" :
                /^e$/.test(i) ? "Right" : "Left"].join("");

          target.css(padPos, padWrapper);

          this._proportionallyResize();
        }

        this._handles = this._handles.add(this.handles[i]);
      }
    };

    // TODO: make renderAxis a prototype function
    this._renderAxis(this.element);

    this._handles = this._handles.add(this.element.find(".ui-resizable-handle"));
    this._handles.disableSelection();

    this._handles.on("mouseover", function () {
      if (!that.resizing) {
        if (this.className) {
          axis = this.className.match(/ui-resizable-(se|sw|ne|nw|n|e|s|w)/i);
        }
        that.axis = axis && axis[1] ? axis[1] : "se";
      }
    });

    if (o.autoHide) {
      this._handles.hide();
      this._addClass("ui-resizable-autohide");
    }
  },

  _removeHandles: function () {
    this._addedHandles.remove();
  },

  _mouseCapture: function (event) {
    var i, handle,
      capture = false;

    for (i in this.handles) {
      handle = $(this.handles[i])[0];
      if (handle === event.target || $.contains(handle, event.target)) {
        capture = true;
      }
    }

    return !this.options.disabled && capture;
  },

  _mouseStart: function (event) {

    var curleft, curtop, cursor,
      o = this.options,
      el = this.element;

    this.resizing = true;

    this._renderProxy();

    curleft = this._num(this.helper.css("left"));
    curtop = this._num(this.helper.css("top"));

    if (o.containment) {
      curleft += $(o.containment).scrollLeft() || 0;
      curtop += $(o.containment).scrollTop() || 0;
    }

    this.offset = this.helper.offset();
    this.position = {left: curleft, top: curtop};

    this.size = this._helper ? {
      width: this.helper.width(),
      height: this.helper.height()
    } : {
      width: el.width(),
      height: el.height()
    };

    this.originalSize = this._helper ? {
      width: el.outerWidth(),
      height: el.outerHeight()
    } : {
      width: el.width(),
      height: el.height()
    };

    this.sizeDiff = {
      width: el.outerWidth() - el.width(),
      height: el.outerHeight() - el.height()
    };

    this.originalPosition = {left: curleft, top: curtop};
    this.originalMousePosition = {left: event.pageX, top: event.pageY};

    this.aspectRatio = (typeof o.aspectRatio === "number") ?
      o.aspectRatio :
      ((this.originalSize.width / this.originalSize.height) || 1);

    cursor = $(".ui-resizable-" + this.axis).css("cursor");
    $("body").css("cursor", cursor === "auto" ? this.axis + "-resize" : cursor);

    this._addClass("ui-resizable-resizing");
    this._propagate("start", event);
    return true;
  },

  _mouseDrag: function (event) {

    let zoom = (100 + store.getters.getSettings.ZoomInterface) / 100;

    var data, props,
      smp = this.originalMousePosition,
      a = this.axis,
      dx = (event.pageX - smp.left) || 0,
      dy = (event.pageY - smp.top) || 0,
      trigger = this._change[a];

    dx = dx / zoom
    dy = dy / zoom

    this._updatePrevProperties();

    if (!trigger) {
      return false;
    }

    data = trigger.apply(this, [event, dx, dy]);

    this._updateVirtualBoundaries(event.shiftKey);
    if (this._aspectRatio || event.shiftKey) {
      data = this._updateRatio(data, event);
    }

    data = this._respectSize(data, event);

    this._updateCache(data);

    this._propagate("resize", event);

    props = this._applyChanges();

    if (!this._helper && this._proportionallyResizeElements.length) {
      this._proportionallyResize();
    }

    if (!$.isEmptyObject(props)) {
      this._updatePrevProperties();
      this._trigger("resize", event, this.ui());
      this._applyChanges();
    }

    return false;
  },

  _mouseStop: function (event) {

    this.resizing = false;
    var pr, ista, soffseth, soffsetw, s, left, top,
      o = this.options, that = this;

    if (this._helper) {

      pr = this._proportionallyResizeElements;
      ista = pr.length && (/textarea/i).test(pr[0].nodeName);
      soffseth = ista && this._hasScroll(pr[0], "left") ? 0 : that.sizeDiff.height;
      soffsetw = ista ? 0 : that.sizeDiff.width;

      s = {
        width: (that.helper.width() - soffsetw),
        height: (that.helper.height() - soffseth)
      };
      left = (parseFloat(that.element.css("left")) +
        (that.position.left - that.originalPosition.left)) || null;
      top = (parseFloat(that.element.css("top")) +
        (that.position.top - that.originalPosition.top)) || null;

      if (!o.animate) {
        this.element.css($.extend(s, {top: top, left: left}));
      }

      that.helper.height(that.size.height);
      that.helper.width(that.size.width);

      if (this._helper && !o.animate) {
        this._proportionallyResize();
      }
    }

    $("body").css("cursor", "auto");

    this._removeClass("ui-resizable-resizing");

    this._propagate("stop", event);

    if (this._helper) {
      this.helper.remove();
    }

    return false;

  },

  _updatePrevProperties: function () {
    this.prevPosition = {
      top: this.position.top,
      left: this.position.left
    };
    this.prevSize = {
      width: this.size.width,
      height: this.size.height
    };
  },

  _applyChanges: function () {
    var props = {};

    if (this.position.top !== this.prevPosition.top) {
      props.top = this.position.top + "px";
    }
    if (this.position.left !== this.prevPosition.left) {
      props.left = this.position.left + "px";
    }

    this.helper.css(props);

    if (this.size.width !== this.prevSize.width) {
      props.width = this.size.width + "px";
      this.helper.width(props.width);
    }
    if (this.size.height !== this.prevSize.height) {
      props.height = this.size.height + "px";
      this.helper.height(props.height);
    }

    return props;
  },

  _updateVirtualBoundaries: function (forceAspectRatio) {
    var pMinWidth, pMaxWidth, pMinHeight, pMaxHeight, b,
      o = this.options;

    b = {
      minWidth: this._isNumber(o.minWidth) ? o.minWidth : 0,
      maxWidth: this._isNumber(o.maxWidth) ? o.maxWidth : Infinity,
      minHeight: this._isNumber(o.minHeight) ? o.minHeight : 0,
      maxHeight: this._isNumber(o.maxHeight) ? o.maxHeight : Infinity
    };

    if (this._aspectRatio || forceAspectRatio) {
      pMinWidth = b.minHeight * this.aspectRatio;
      pMinHeight = b.minWidth / this.aspectRatio;
      pMaxWidth = b.maxHeight * this.aspectRatio;
      pMaxHeight = b.maxWidth / this.aspectRatio;

      if (pMinWidth > b.minWidth) {
        b.minWidth = pMinWidth;
      }
      if (pMinHeight > b.minHeight) {
        b.minHeight = pMinHeight;
      }
      if (pMaxWidth < b.maxWidth) {
        b.maxWidth = pMaxWidth;
      }
      if (pMaxHeight < b.maxHeight) {
        b.maxHeight = pMaxHeight;
      }
    }
    this._vBoundaries = b;
  },

  _updateCache: function (data) {
    this.offset = this.helper.offset();
    if (this._isNumber(data.left)) {
      this.position.left = data.left;
    }
    if (this._isNumber(data.top)) {
      this.position.top = data.top;
    }
    if (this._isNumber(data.height)) {
      this.size.height = data.height;
    }
    if (this._isNumber(data.width)) {
      this.size.width = data.width;
    }
  },

  _updateRatio: function (data) {

    var cpos = this.position,
      csize = this.size,
      a = this.axis;

    if (this._isNumber(data.height)) {
      data.width = (data.height * this.aspectRatio);
    } else if (this._isNumber(data.width)) {
      data.height = (data.width / this.aspectRatio);
    }

    if (a === "sw") {
      data.left = cpos.left + (csize.width - data.width);
      data.top = null;
    }
    if (a === "nw") {
      data.top = cpos.top + (csize.height - data.height);
      data.left = cpos.left + (csize.width - data.width);
    }

    return data;
  },

  _respectSize: function (data) {

    var o = this._vBoundaries,
      a = this.axis,
      ismaxw = this._isNumber(data.width) && o.maxWidth && (o.maxWidth < data.width),
      ismaxh = this._isNumber(data.height) && o.maxHeight && (o.maxHeight < data.height),
      isminw = this._isNumber(data.width) && o.minWidth && (o.minWidth > data.width),
      isminh = this._isNumber(data.height) && o.minHeight && (o.minHeight > data.height),
      dw = this.originalPosition.left + this.originalSize.width,
      dh = this.originalPosition.top + this.originalSize.height,
      cw = /sw|nw|w/.test(a), ch = /nw|ne|n/.test(a);
    if (isminw) {
      data.width = o.minWidth;
    }
    if (isminh) {
      data.height = o.minHeight;
    }
    if (ismaxw) {
      data.width = o.maxWidth;
    }
    if (ismaxh) {
      data.height = o.maxHeight;
    }

    if (isminw && cw) {
      data.left = dw - o.minWidth;
    }
    if (ismaxw && cw) {
      data.left = dw - o.maxWidth;
    }
    if (isminh && ch) {
      data.top = dh - o.minHeight;
    }
    if (ismaxh && ch) {
      data.top = dh - o.maxHeight;
    }

    // Fixing jump error on top/left - bug #2330
    if (!data.width && !data.height && !data.left && data.top) {
      data.top = null;
    } else if (!data.width && !data.height && !data.top && data.left) {
      data.left = null;
    }

    return data;
  },

  _getPaddingPlusBorderDimensions: function (element) {
    var i = 0,
      widths = [],
      borders = [
        element.css("borderTopWidth"),
        element.css("borderRightWidth"),
        element.css("borderBottomWidth"),
        element.css("borderLeftWidth")
      ],
      paddings = [
        element.css("paddingTop"),
        element.css("paddingRight"),
        element.css("paddingBottom"),
        element.css("paddingLeft")
      ];

    for (; i < 4; i++) {
      widths[i] = (parseFloat(borders[i]) || 0);
      widths[i] += (parseFloat(paddings[i]) || 0);
    }

    return {
      height: widths[0] + widths[2],
      width: widths[1] + widths[3]
    };
  },

  _proportionallyResize: function () {

    if (!this._proportionallyResizeElements.length) {
      return;
    }

    var prel,
      i = 0,
      element = this.helper || this.element;

    for (; i < this._proportionallyResizeElements.length; i++) {

      prel = this._proportionallyResizeElements[i];

      // TODO: Seems like a bug to cache this.outerDimensions
      // considering that we are in a loop.
      if (!this.outerDimensions) {
        this.outerDimensions = this._getPaddingPlusBorderDimensions(prel);
      }

      prel.css({
        height: (element.height() - this.outerDimensions.height) || 0,
        width: (element.width() - this.outerDimensions.width) || 0
      });

    }

  },

  _renderProxy: function () {

    var el = this.element, o = this.options;
    this.elementOffset = el.offset();

    if (this._helper) {

      this.helper = this.helper || $("<div></div>").css({overflow: "hidden"});

      this._addClass(this.helper, this._helper);
      this.helper.css({
        width: this.element.outerWidth(),
        height: this.element.outerHeight(),
        position: "absolute",
        left: this.elementOffset.left + "px",
        top: this.elementOffset.top + "px",
        zIndex: ++o.zIndex //TODO: Don't modify option
      });

      this.helper
        .appendTo("body")
        .disableSelection();

    } else {
      this.helper = this.element;
    }

  },

  _change: {
    e: function (event, dx) {
      return {width: this.originalSize.width + dx};
    },
    w: function (event, dx) {
      var cs = this.originalSize, sp = this.originalPosition;
      return {left: sp.left + dx, width: cs.width - dx};
    },
    n: function (event, dx, dy) {
      var cs = this.originalSize, sp = this.originalPosition;
      return {top: sp.top + dy, height: cs.height - dy};
    },
    s: function (event, dx, dy) {
      return {height: this.originalSize.height + dy};
    },
    se: function (event, dx, dy) {
      return $.extend(this._change.s.apply(this, arguments),
        this._change.e.apply(this, [event, dx, dy]));
    },
    sw: function (event, dx, dy) {
      return $.extend(this._change.s.apply(this, arguments),
        this._change.w.apply(this, [event, dx, dy]));
    },
    ne: function (event, dx, dy) {
      return $.extend(this._change.n.apply(this, arguments),
        this._change.e.apply(this, [event, dx, dy]));
    },
    nw: function (event, dx, dy) {
      return $.extend(this._change.n.apply(this, arguments),
        this._change.w.apply(this, [event, dx, dy]));
    }
  },

  _propagate: function (n, event) {
    $.ui.plugin.call(this, n, [event, this.ui()]);
    if (n !== "resize") {
      this._trigger(n, event, this.ui());
    }
  },

  plugins: {},

  ui: function () {
    return {
      originalElement: this.originalElement,
      element: this.element,
      helper: this.helper,
      position: this.position,
      size: this.size,
      originalSize: this.originalSize,
      originalPosition: this.originalPosition
    };
  }

});
