import {gameStore} from "../store";
import store from "../../store/store";
import {Scene} from "../create";
import {md5} from "../utils/md5";
import {VueEx} from "../../main";
import {UpdatePowerSlots} from "./equip_bar";

let statusBarWrapper = {
  init: false,
  loadScene: false,
  scene: null,
  connection: {
    state: null,
    text: null,
    timer: null,
  },
}

function resetStatusBar() {
  if (statusBarWrapper.hasOwnProperty('avatar')) {
    if (statusBarWrapper.borderSprite) statusBarWrapper.borderSprite.destroy();
    if (statusBarWrapper.avatar.sprite) statusBarWrapper.avatar.sprite.destroy();
    if (statusBarWrapper.avatar.border) statusBarWrapper.avatar.border.destroy();

    if (statusBarWrapper.bars.hp.outer) statusBarWrapper.bars.hp.outer.destroy();
    if (statusBarWrapper.bars.hp.inner) statusBarWrapper.bars.hp.inner.destroy();

    if (statusBarWrapper.bars.energy.outer) statusBarWrapper.bars.energy.outer.destroy();
    if (statusBarWrapper.bars.energy.inner) statusBarWrapper.bars.energy.inner.destroy();

    if (statusBarWrapper.bars.unrepairableDamage.inner) statusBarWrapper.bars.unrepairableDamage.inner.destroy();

    if (statusBarWrapper.notExistReady.sprite) statusBarWrapper.notExistReady.sprite.destroy();
    if (statusBarWrapper.notExistReady.time) statusBarWrapper.notExistReady.time.destroy();

    if (statusBarWrapper.pvpStatus.sprite) statusBarWrapper.pvpStatus.sprite.destroy();
    if (statusBarWrapper.pvpStatus.time) statusBarWrapper.pvpStatus.time.destroy();

    if (statusBarWrapper.pvpStatus.strike.sprite) statusBarWrapper.pvpStatus.strike.sprite.destroy();
    if (statusBarWrapper.pvpStatus.strike.count.sprite) statusBarWrapper.pvpStatus.strike.count.sprite.destroy();

    if (statusBarWrapper.upRank.sprite) statusBarWrapper.upRank.sprite.destroy();

    if (statusBarWrapper.connection.text) statusBarWrapper.connection.text.destroy();

    for (let i in statusBarWrapper.thoriumBars.bars) {
      if (statusBarWrapper.thoriumBars.bars[i].outer) statusBarWrapper.thoriumBars.bars[i].outer.destroy()
      if (statusBarWrapper.thoriumBars.bars[i].inner) statusBarWrapper.thoriumBars.bars[i].inner.destroy()
      if (statusBarWrapper.thoriumBars.bars[i].texture) statusBarWrapper.thoriumBars.bars[i].texture.destroy()
    }

    store.dispatch("sendSocketData", JSON.stringify({
      service: "global",
      data: {
        event: "ResetOldCommand",
      }
    }));

    gameStore.unitState.hp = 0;
    gameStore.unitState.power = 0;
  }

  let addPercent = 30
  let percent = addPercent
  if (store) {
    percent = store.getters.getSettings.ZoomInterfaceIn + addPercent
  }

  function getPercentVal(min, max) {
    return min + (percent / 100 * (max - min))
  }

  statusBarWrapper = {
    init: statusBarWrapper.init,
    loadScene: statusBarWrapper.loadScene,
    borderSprite: null,
    borderSpriteConf: {
      scale: getPercentVal(0.39, 0.585),
      offsetX: getPercentVal(30, 45),
      offsetY: getPercentVal(-10, -15),
    },
    avatar: {
      sprite: null,
      border: null,
      size: getPercentVal(50, 75),
      pos: {x: getPercentVal(65, 97, 5), y: getPercentVal(40, 60)},
      textureKey: "",
    },
    bars: {
      outerBorderColor: 0x000000,
      outerBackColor: 0x919191,
      outerOffset: getPercentVal(2, 3),
      hp: {
        outer: null,
        inner: null,
        x_offset: getPercentVal(-20, -30),
        y_offset: getPercentVal(-27, -40, 5),
        height: getPercentVal(13, 19.5),
        width: getPercentVal(94, 141),
      },
      energy: {
        outer: null,
        inner: null,
        x_offset: getPercentVal(-20, -30),
        y_offset: getPercentVal(-12, -18),
        height: getPercentVal(4, 6),
        width: getPercentVal(94, 141),
      },
      unrepairableDamage: {
        inner: null
      }
    },
    loadAvatar: null,
    initAvatar: null,
    scene: statusBarWrapper.scene,
    waitData: null,
    waitMsgs: [],
    notExistReady: {
      sprite: null,
      time: null,
      scale: getPercentVal(0.4, 0.6),
      xOffset: getPercentVal(236, 354),
      yOffset: getPercentVal(3, 4.5),
      textXOffset: getPercentVal(237, 355.5),
      textYOffset: getPercentVal(12, 18),
      textSize: getPercentVal(12, 18),
    },
    pvpStatus: {
      sprite: null,
      time: null,
      scale: getPercentVal(0.4, 0.6),
      xOffset: getPercentVal(200, 300),
      yOffset: getPercentVal(3, 4.5),
      textXOffset: getPercentVal(201, 301.5),
      textYOffset: getPercentVal(12, 18),
      textSize: getPercentVal(12, 18),
      strike: {
        scale: getPercentVal(0.55, 0.825),
        xOffset: getPercentVal(170, 255),
        yOffset: getPercentVal(7, 10.5),
        textXOffset: getPercentVal(156, 234),
        textYOffset: getPercentVal(3, 4.5),
        textSize: getPercentVal(16, 24),
        sprite: null,
        count: {count: 0, sprite: null},
      },
    },
    upRank: {
      sprite: null,
      scale: getPercentVal(0.4, 0.6),
      xOffset: getPercentVal(266, 399),
      yOffset: getPercentVal(3, 4.5),
    },
    connection: statusBarWrapper.connection,
    thoriumBars: {
      state: null,
      backColor: 0x515151,
      yOffset: getPercentVal(-1, -1.5),
      xOffset: getPercentVal(-16, -24),
      height: getPercentVal(4, 6),
      cellWith: getPercentVal(24, 36),
      padding: getPercentVal(6, 9),
      bars: {},
      scale: getPercentVal(0.3, 0.45),
      bracketXOffset: getPercentVal(5, 7.5),
      bracketYOffset: getPercentVal(3, 4.5),
    }
  }

  statusBarWrapper.connection.textSize = getPercentVal(16, 24)
  statusBarWrapper.connection.xOffset = getPercentVal(35, 52.5)
  statusBarWrapper.connection.yOffset = getPercentVal(5, 7.5)

  createBars();
}

function initUI() {
  if (!statusBarWrapper.init || !statusBarWrapper.loadAvatar) {
    init()
  }
}

function init() {
  if (!statusBarWrapper.init && !statusBarWrapper.loadScene) {
    resetStatusBar()
    createStatusBar();
  }

  if (!statusBarWrapper.loadAvatar && statusBarWrapper.scene) {
    createAvatar();
  }
}

function removeAvatar() {
  if (statusBarWrapper.avatar.sprite) {
    statusBarWrapper.avatar.sprite.destroy();
    statusBarWrapper.avatar.border.destroy();
    statusBarWrapper.loadAvatar = false;
    statusBarWrapper.initAvatar = false;
  }
}

function removeThoriumBars() {
  for (let i in statusBarWrapper.thoriumBars.bars) {
    if (statusBarWrapper.thoriumBars.bars[i].inner) {
      statusBarWrapper.thoriumBars.bars[i].inner.destroy();
    }

    if (statusBarWrapper.thoriumBars.bars[i].outer) {
      statusBarWrapper.thoriumBars.bars[i].outer.destroy();
    }

    if (statusBarWrapper.thoriumBars.bars[i].texture) {
      statusBarWrapper.thoriumBars.bars[i].texture.destroy();
    }

    delete statusBarWrapper.thoriumBars.bars[i]
  }
}

function createStatusBar() {
  statusBarWrapper.loadScene = true;

  Scene.scene.add('ui', {
    key: "ui", active: true, visible: true,
  }, true, null)

  let waitScene = setInterval(function () {
    let scene = Scene.scene.get("ui");
    if (scene) {
      statusBarWrapper.scene = scene;
      clearInterval(waitScene);
      createAvatar();
      createBars();
      statusBarWrapper.init = true
    }
  }, 100)
}

function createAvatar() {

  if (statusBarWrapper.borderSprite) statusBarWrapper.borderSprite.destroy();
  statusBarWrapper.borderSprite = statusBarWrapper.scene.make.image({
    x: statusBarWrapper.avatar.pos.x + statusBarWrapper.borderSpriteConf.offsetX,
    y: statusBarWrapper.avatar.pos.y + statusBarWrapper.borderSpriteConf.offsetY,
    key: "radar",
    frame: "player_hp_bar",
    add: true
  });
  statusBarWrapper.borderSprite.setDepth(1200)
  statusBarWrapper.borderSprite.setScale(statusBarWrapper.borderSpriteConf.scale);

  try {

    if (!statusBarWrapper.initAvatar) {
      statusBarWrapper.initAvatar = true

      store.dispatch('getAvatar', {
        userID: gameStore.playerID,
        vm: VueEx
      });

      let avatar = store.getters.getAvatars[gameStore.playerID];
      if (!avatar || avatar.fake) {
        statusBarWrapper.initAvatar = false
        return
      }

      let avatarBase64 = avatar.avatar;
      if (!avatarBase64) {
        statusBarWrapper.initAvatar = false
        return
      }

      avatarBase64 = avatarBase64.substring(5)
      avatarBase64 = avatarBase64.substring(0, avatarBase64.length - 2)
      statusBarWrapper.avatar.textureKey = 'avatar' + md5(avatarBase64);

      try {
        Scene.textures.addBase64(statusBarWrapper.avatar.textureKey, avatarBase64);
      } catch (e) {
        console.log(e)
      }

      statusBarWrapper.avatar.border = statusBarWrapper.scene.add.rectangle(statusBarWrapper.avatar.pos.x,
        statusBarWrapper.avatar.pos.y, statusBarWrapper.avatar.size, statusBarWrapper.avatar.size,
        0x25a0e1);

      statusBarWrapper.avatar.border.setStrokeStyle(4, 0x25a0e1);
      statusBarWrapper.avatar.border.setDepth(1001)
    }

    if (statusBarWrapper.avatar.sprite) {
      statusBarWrapper.avatar.sprite.destroy();
      statusBarWrapper.avatar.sprite = null;
    }

    statusBarWrapper.avatar.sprite = statusBarWrapper.scene.make.image({
      x: statusBarWrapper.avatar.pos.x,
      y: statusBarWrapper.avatar.pos.y,
      key: statusBarWrapper.avatar.textureKey,
      add: true
    });

    statusBarWrapper.avatar.sprite.setDepth(1100)
    statusBarWrapper.avatar.sprite.setScale(0.2)
    statusBarWrapper.avatar.sprite.setDisplaySize(statusBarWrapper.avatar.size, statusBarWrapper.avatar.size);
    if (statusBarWrapper.avatar.sprite.texture.key !== '__MISSING') {
      statusBarWrapper.loadAvatar = true
    }
  } catch (e) {
  }
}

function createBars() {
  if (!statusBarWrapper.scene) return;

  statusBarWrapper.bars.hp.outer = statusBarWrapper.scene.add.graphics({
    x: statusBarWrapper.avatar.pos.x + statusBarWrapper.avatar.size + statusBarWrapper.bars.hp.x_offset,
    y: statusBarWrapper.avatar.pos.y + statusBarWrapper.bars.hp.y_offset,
    add: true,
  });

  statusBarWrapper.bars.hp.outer.fillStyle(statusBarWrapper.bars.outerBackColor);
  statusBarWrapper.bars.hp.outer.fillRect(statusBarWrapper.bars.outerOffset, statusBarWrapper.bars.outerOffset, statusBarWrapper.bars.hp.width, statusBarWrapper.bars.hp.height);

  statusBarWrapper.bars.energy.outer = statusBarWrapper.scene.add.graphics({
    x: statusBarWrapper.avatar.pos.x + statusBarWrapper.avatar.size + statusBarWrapper.bars.energy.x_offset,
    y: statusBarWrapper.avatar.pos.y + statusBarWrapper.bars.energy.y_offset,
    add: true,
  });

  statusBarWrapper.bars.energy.outer.fillStyle(statusBarWrapper.bars.outerBackColor);
  statusBarWrapper.bars.energy.outer.fillRect(statusBarWrapper.bars.outerOffset, statusBarWrapper.bars.outerOffset, statusBarWrapper.bars.energy.width, statusBarWrapper.bars.energy.height);
}

function fillBar(type, percent) {
  if (statusBarWrapper.bars[type].inner) {
    statusBarWrapper.bars[type].inner.clear();
  } else {
    statusBarWrapper.bars[type].inner = statusBarWrapper.scene.add.graphics({
      x: statusBarWrapper.avatar.pos.x + statusBarWrapper.avatar.size + statusBarWrapper.bars[type].x_offset,
      y: statusBarWrapper.avatar.pos.y + statusBarWrapper.bars[type].y_offset,
      add: true,
    });
  }

  percent++
  let width = statusBarWrapper.bars[type].width * (percent / 100)
  let color = 0x87ceeb;
  if (type === 'hp') {
    color = percentHP(percent);
    store.commit({
      type: 'setCriticalDamage',
      cd: percent <= 33,
    });
  }

  if (type === 'energy') color = percentEnergy(percent);
  statusBarWrapper.bars[type].inner.fillStyle(color);
  statusBarWrapper.bars[type].inner.fillRect(statusBarWrapper.bars.outerOffset, statusBarWrapper.bars.outerOffset, width, statusBarWrapper.bars[type].height);
}

function waitInit() {
  if (!statusBarWrapper.waitData) {
    statusBarWrapper.waitData = setInterval(function () {
      if (!statusBarWrapper.init || !gameStore.userUnit) return;

      while (statusBarWrapper.waitMsgs.length > 0) {

        let msg = statusBarWrapper.waitMsgs.shift();

        if (msg.type === 'hp') updateHP(msg.val);
        if (msg.type === 'energy') updateEnergy(msg.val);
        if (msg.type === 'ud') setUnrepairableDamage(msg.val);
        if (msg.type === 'thorium') thoriumBars(msg.val);
      }

      clearInterval(statusBarWrapper.waitData)
      statusBarWrapper.waitData = null
    }, 100)
  }
}

function updateHP(newHP) {
  if (!statusBarWrapper.init || !gameStore.userUnit) {
    statusBarWrapper.waitMsgs.push({type: 'hp', val: newHP})
    waitInit()
    return;
  }

  if (gameStore.unitState.hp === newHP) {
    return
  }

  gameStore.unitState.hp = newHP

  let percent = (100 / (gameStore.userUnit.body.max_hp / gameStore.unitState.hp));
  fillBar('hp', percent)
}

function updateEnergy(newEnergy) {

  if (!statusBarWrapper.init || !gameStore.userUnit) {
    statusBarWrapper.waitMsgs.push({type: 'energy', val: newEnergy})
    waitInit()
    return;
  }

  if (gameStore.unitState.power === newEnergy) {
    return
  }

  gameStore.unitState.power = newEnergy

  let percent = (100 / (gameStore.userUnit.body.max_power / gameStore.unitState.power))
  fillBar('energy', percent)
  UpdatePowerSlots()
}

function updateExistReady(time) {
  if (!statusBarWrapper.scene) return;

  if (time > 0) {
    if (!statusBarWrapper.notExistReady.sprite) {
      statusBarWrapper.notExistReady.sprite = statusBarWrapper.scene.make.image({
        x: statusBarWrapper.avatar.pos.x + statusBarWrapper.notExistReady.xOffset,
        y: statusBarWrapper.avatar.pos.y - statusBarWrapper.notExistReady.yOffset,
        key: "radar",
        frame: "not_exist_ready",
        add: true
      });
      statusBarWrapper.notExistReady.sprite.setScale(statusBarWrapper.notExistReady.scale)
      statusBarWrapper.notExistReady.sprite.setOrigin(1);
    } else {
      statusBarWrapper.notExistReady.sprite.setVisible(true);
    }

    let t = timeFormat(time)
    if (statusBarWrapper.notExistReady.time) statusBarWrapper.notExistReady.time.destroy();
    statusBarWrapper.notExistReady.time = statusBarWrapper.scene.add.bitmapText(
      statusBarWrapper.avatar.pos.x + statusBarWrapper.notExistReady.textXOffset,
      statusBarWrapper.avatar.pos.y + statusBarWrapper.notExistReady.textYOffset,
      'bit_text', t.minutes + ":" + t.sec, statusBarWrapper.notExistReady.textSize);
    statusBarWrapper.notExistReady.time.setOrigin(1);
    statusBarWrapper.notExistReady.time.setLetterSpacing(1)
    statusBarWrapper.notExistReady.time.setTint(0xFFFFFF)
  } else {
    if (statusBarWrapper.notExistReady.sprite) {
      statusBarWrapper.notExistReady.sprite.setVisible(false);
      statusBarWrapper.notExistReady.time.destroy();
    }
  }
}

function updateVioStatus(time, type, strikes) {
  if (!statusBarWrapper.scene) return;

  if (time !== 0 && type > 0) {
    if (!statusBarWrapper.pvpStatus.sprite) {
      statusBarWrapper.pvpStatus.sprite = statusBarWrapper.scene.make.image({
        x: statusBarWrapper.avatar.pos.x + statusBarWrapper.pvpStatus.xOffset,
        y: statusBarWrapper.avatar.pos.y - statusBarWrapper.pvpStatus.yOffset,
        key: "radar",
        frame: "law_icon",
        add: true
      });
      statusBarWrapper.pvpStatus.sprite.setScale(statusBarWrapper.pvpStatus.scale)
      statusBarWrapper.pvpStatus.sprite.setOrigin(1);
    } else {
      statusBarWrapper.pvpStatus.sprite.setVisible(true);
    }

    let t = timeFormat(time)
    if (statusBarWrapper.pvpStatus.time) statusBarWrapper.pvpStatus.time.destroy();

    statusBarWrapper.pvpStatus.time = statusBarWrapper.scene.add.bitmapText(
      statusBarWrapper.avatar.pos.x + statusBarWrapper.pvpStatus.textXOffset,
      statusBarWrapper.avatar.pos.y + statusBarWrapper.pvpStatus.textYOffset,
      'bit_text', t.minutes + ":" + t.sec, statusBarWrapper.pvpStatus.textSize);

    statusBarWrapper.pvpStatus.time.setOrigin(1);
    statusBarWrapper.pvpStatus.time.setLetterSpacing(1)
    statusBarWrapper.pvpStatus.time.setTint(0xFFFFFF)

    if (type === 1) statusBarWrapper.pvpStatus.sprite.setTint(0xFF0000)
    if (type === 2 || type === 2) statusBarWrapper.pvpStatus.sprite.setTint(0xcc5ffd)
  } else {
    if (statusBarWrapper.pvpStatus.sprite) {
      statusBarWrapper.pvpStatus.sprite.setVisible(false);
      statusBarWrapper.pvpStatus.time.destroy();
    }
  }

  if (strikes > 0) {
    if (!statusBarWrapper.pvpStatus.strike.sprite) {
      statusBarWrapper.pvpStatus.strike.sprite = statusBarWrapper.scene.make.image({
        x: statusBarWrapper.avatar.pos.x + statusBarWrapper.pvpStatus.strike.xOffset,
        y: statusBarWrapper.avatar.pos.y + statusBarWrapper.pvpStatus.strike.yOffset,
        key: 'radar',
        frame: "strike_icon",
        add: true
      });
      statusBarWrapper.pvpStatus.strike.sprite.setScale(statusBarWrapper.pvpStatus.strike.scale)
      statusBarWrapper.pvpStatus.strike.sprite.setOrigin(1);
    }

    statusBarWrapper.pvpStatus.strike.sprite.setVisible(true);

    if (strikes !== statusBarWrapper.pvpStatus.strike.count.count) {
      if (statusBarWrapper.pvpStatus.strike.count.sprite) {
        statusBarWrapper.pvpStatus.strike.count.sprite.destroy();
      }

      statusBarWrapper.pvpStatus.strike.count.count = strikes

      let tint = {
        1: 0xFFFFFF,
        2: 0xFDD8BB,
        3: 0xFDC08b,
        4: 0xF99769,
        5: 0xF89932,
        6: 0xFA7F03,
        7: 0xED5F0E,
        8: 0xD74B20,
        9: 0xFF0000,
        10: 0xFF0000,
        11: 0xFF0000,
        12: 0xFF0000,
      }

      statusBarWrapper.pvpStatus.strike.count.sprite = statusBarWrapper.scene.add.bitmapText(
        statusBarWrapper.avatar.pos.x + statusBarWrapper.pvpStatus.strike.textXOffset,
        statusBarWrapper.avatar.pos.y - statusBarWrapper.pvpStatus.strike.textYOffset,
        'bit_text', strikes, statusBarWrapper.pvpStatus.strike.textSize);
      statusBarWrapper.pvpStatus.strike.count.sprite.setOrigin(1);
      statusBarWrapper.pvpStatus.strike.count.sprite.setLetterSpacing(1)
      statusBarWrapper.pvpStatus.strike.count.sprite.setTint(tint[strikes])
    }
  } else {
    if (statusBarWrapper.pvpStatus.strike.sprite) statusBarWrapper.pvpStatus.strike.sprite.setVisible(false);
    if (statusBarWrapper.pvpStatus.strike.count.sprite) {
      statusBarWrapper.pvpStatus.strike.count.sprite.destroy();
    }
  }
}

function updateRank(up) {
  if (!statusBarWrapper.scene) return;

  if (up) {
    if (!statusBarWrapper.upRank.sprite) {
      statusBarWrapper.upRank.sprite = statusBarWrapper.scene.make.image({
        x: statusBarWrapper.avatar.pos.x + statusBarWrapper.upRank.xOffset,
        y: statusBarWrapper.avatar.pos.y - statusBarWrapper.upRank.yOffset,
        key: "radar",
        frame: "up_rank",
        add: true
      });
      statusBarWrapper.upRank.sprite.setScale(statusBarWrapper.upRank.scale)
      statusBarWrapper.upRank.sprite.setOrigin(1);
    } else {
      statusBarWrapper.upRank.sprite.setVisible(true);
    }
  } else {
    if (statusBarWrapper.upRank.sprite) {
      statusBarWrapper.upRank.sprite.setVisible(false);
    }
  }
}

function timeFormat(time) {

  if (time < 0 || time === 99999) {
    return {minutes: '--', sec: '--'}
  }

  let minutes = Math.floor(time / 60);
  let sec = time % 60;
  return {minutes: (minutes % 60 < 10 ? "0" : "") + minutes, sec: (sec % 60 < 10 ? "0" : "") + sec};
}

const connect_time_out_tick = 100;
const connect_time = 1500;

function drawConnectState(state, color) {
  if (!statusBarWrapper.scene) return;

  if (statusBarWrapper.connection.state && statusBarWrapper.connection.state.state === state) {
    if (statusBarWrapper.connection.timer && statusBarWrapper.connection.timer.callback) {
      statusBarWrapper.connection.timer.time_out = connect_time
      return;
    }
  }

  if (!statusBarWrapper.connection.state || statusBarWrapper.connection.state.state === "" || statusBarWrapper.connection.state.state !== state && state === 'bad') {
    statusBarWrapper.connection.state = {state: state, color: color}

    if (statusBarWrapper.connection.text) {
      statusBarWrapper.connection.text.destroy();
    }

    if (statusBarWrapper.connection.timer && statusBarWrapper.connection.timer.callback) {
      statusBarWrapper.connection.timer.callback.destroy();
    }

    statusBarWrapper.connection.timer = {
      callback: Scene.time.addEvent({
        delay: connect_time_out_tick,
        loop: true,
        callback: function () {
          statusBarWrapper.connection.timer.time_out -= connect_time_out_tick
          if (statusBarWrapper.connection.timer.time_out < 0) {
            statusBarWrapper.connection.state = {state: "", color: ""};
            statusBarWrapper.connection.text.destroy();
          }
        }
      }),
      time_out: connect_time,
    }

    statusBarWrapper.connection.text = statusBarWrapper.scene.add.bitmapText(
      statusBarWrapper.avatar.pos.x + statusBarWrapper.connection.xOffset,
      statusBarWrapper.avatar.pos.y + statusBarWrapper.connection.yOffset,
      'bit_text', "connect: " + state, statusBarWrapper.connection.textSize);
    statusBarWrapper.connection.text.setTint(color)
  }
}

function percentHP(percent) {
  if (percent >= 85) {
    return 0x5ece4d // #48FF28
  } else if (percent < 85 && percent >= 80) {
    return 0xfff326
  } else if (percent < 80 && percent >= 60) {
    return 0xfac227
  } else if (percent < 60 && percent >= 35) {
    return 0xfa7b31
  } else if (percent < 35) {
    return 0xff2615
  }
}

function percentEnergy(percent) {
  if (percent < 35) {
    return 0xff0000
  }

  return 0x87ceeb
}

function setUnrepairableDamage(percent) {
  if (!statusBarWrapper.init || !gameStore.userUnit) {
    statusBarWrapper.waitMsgs.push({type: 'ud', val: percent})
    waitInit()
    return;
  }

  if (statusBarWrapper.bars.unrepairableDamage.inner) {
    statusBarWrapper.bars.unrepairableDamage.inner.clear();
  } else {
    statusBarWrapper.bars.unrepairableDamage.inner = statusBarWrapper.scene.add.graphics({
      x: statusBarWrapper.avatar.pos.x + statusBarWrapper.avatar.size + statusBarWrapper.bars['hp'].x_offset,
      y: statusBarWrapper.avatar.pos.y + statusBarWrapper.bars['hp'].y_offset,
      add: true,
    });
    statusBarWrapper.bars.unrepairableDamage.inner.setDepth(1000)
  }

  let width = statusBarWrapper.bars['hp'].width * (percent / 100)

  statusBarWrapper.bars.unrepairableDamage.inner.fillStyle(0x52576c);
  statusBarWrapper.bars.unrepairableDamage.inner.fillRect(statusBarWrapper.bars['hp'].width - width, statusBarWrapper.bars.outerOffset, width, statusBarWrapper.bars['hp'].height);
}

function thoriumBars(data) {
  if (!statusBarWrapper.init || !gameStore.userUnit) {
    statusBarWrapper.waitMsgs.push({type: 'thorium', val: data})
    waitInit()
    return;
  }

  if (statusBarWrapper.thoriumBars.state && statusBarWrapper.thoriumBars.state.length !== data.length) {
    removeThoriumBars()
  }

  for (let slot of data) {

    let x = statusBarWrapper.avatar.pos.x + statusBarWrapper.avatar.size + statusBarWrapper.thoriumBars.xOffset + ((statusBarWrapper.thoriumBars.cellWith + statusBarWrapper.thoriumBars.padding) * (slot.number_slot - 1))
    let y = statusBarWrapper.avatar.pos.y + statusBarWrapper.thoriumBars.yOffset

    if (!statusBarWrapper.thoriumBars.bars[slot.number_slot]) {
      statusBarWrapper.thoriumBars.bars[slot.number_slot] = {outer: null, inner: null}

      statusBarWrapper.thoriumBars.bars[slot.number_slot].outer = statusBarWrapper.scene.add.graphics({
        x: x,
        y: y,
        add: true,
      });

      statusBarWrapper.thoriumBars.bars[slot.number_slot].inner = statusBarWrapper.scene.add.graphics({
        x: x,
        y: y,
        add: true,
      });

      statusBarWrapper.thoriumBars.bars[slot.number_slot].outer.fillStyle(statusBarWrapper.thoriumBars.backColor);
      statusBarWrapper.thoriumBars.bars[slot.number_slot].outer.fillRect(0, 0, statusBarWrapper.thoriumBars.cellWith, statusBarWrapper.thoriumBars.height);

      statusBarWrapper.thoriumBars.bars[slot.number_slot].texture = statusBarWrapper.scene.make.image({
        x: x + statusBarWrapper.thoriumBars.bracketXOffset,
        y: y + statusBarWrapper.thoriumBars.bracketYOffset,
        key: "radar",
        frame: "thorium_hp_bar",
        add: true
      });
      statusBarWrapper.thoriumBars.bars[slot.number_slot].texture.setDepth(1201)
      statusBarWrapper.thoriumBars.bars[slot.number_slot].texture.setScale(statusBarWrapper.thoriumBars.scale);
    }

    statusBarWrapper.thoriumBars.bars[slot.number_slot].inner.clear();

    let percent = (100 / (slot.max_count / slot.count));
    if (percent > 100) {
      percent = 100
    }

    let width = statusBarWrapper.thoriumBars.cellWith * (percent / 100)

    let color = 0xfa7b31  //percentHP(percent);
    if (percent <= 33) {
      color = 0xff2615
    }

    statusBarWrapper.thoriumBars.bars[slot.number_slot].inner.fillStyle(color);
    statusBarWrapper.thoriumBars.bars[slot.number_slot].inner.fillRect(0, 0, width, statusBarWrapper.thoriumBars.height);
  }

  statusBarWrapper.thoriumBars.state = data
}

export {
  initUI,
  updateHP,
  updateEnergy,
  statusBarWrapper,
  removeAvatar,
  percentHP,
  updateExistReady,
  updateVioStatus,
  updateRank,
  drawConnectState,
  percentEnergy,
  setUnrepairableDamage,
  thoriumBars,
  removeThoriumBars,
  resetStatusBar,
}
