import {gameStore} from "../store";
import {Scene} from "../create";
import {ShortDirectionRotateTween} from "../utils/utils";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {AddMoveBufferData} from "../utils/add_move_buffer_data";
import {setPositionObject} from "../interface/mini_map";
import {UpdatePosBars} from "../interface/status_layer";

function AddBoxMoveBufferData(data) {
  let box = gameStore.boxes[data.id];
  if (box) {
    AddMoveBufferData(data, box, 4)
  }
}

function BoxMove(box, ms) {

  let path = box.bufferMoveTick.shift();
  if (!path) {
    return;
  }

  let pos = GetGlobalPos(path.x, path.y, gameStore.map.id);
  path.x = pos.x;
  path.y = pos.y;

  MoveSprite(box.sprite, path.x, path.y, ms, null, false, false, boxProcess(box));
  if (box.sprite.shadow) MoveSprite(box.sprite.shadow, path.x + Scene.shadowXOffset / 5, path.y + Scene.shadowYOffset / 5, ms, null);

  ShortDirectionRotateTween(box.sprite, path.r, ms);
  if (box.sprite.shadow) ShortDirectionRotateTween(box.sprite.shadow, path.r, ms);
}

function boxProcess(box) {
  return function () {
    if (box.gameCache) {
      return;
    }

    UpdatePosBars(box.sprite, box.max_hp, box.hp, 4, null, Scene, 'box', box.id, 'hp', box.max_hp / 5);
    setPositionObject('structure', box.sprite, 150, 150)
  }
}


export {BoxMove, AddBoxMoveBufferData}
