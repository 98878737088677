<template>
  <div class="WarehousesTabs cursor_hover"
       @mouseover="playSound('select_sound.mp3', 0.3)"
       @click="switchWarehouse(id)"
       v-bind:class="{active: id === inventory.warehouse, remote: remote, path: path, scanner: id.includes('scanner'),
        aInv: aInv, sAInv: sAInv, office: office, disable: rights&& !rights.view, green_animate: green_animate}">

    <div style="position: relative; z-index: 2">{{ title }}</div>

    <div class="WarehousesTabBackground"
         :class="{
            active: id === inventory.warehouse,
            remote: remote,
            path: path,
            scanner: id.includes('scanner'),
            green_animate: green_animate
         }"
         :style="{
            width: (id === 'squadInventory' || id.includes('aInv') || id.includes('office_stock') || id.includes('hangar')) ? fill + '%' : '100%',
         }"/>

    <div v-if="rights" style="position: absolute; top:0; right: 0; z-index: 2">
      <div v-if="!rights.view" class="no_view"></div>
      <!--      <div v-if="!rights.take" class="no_read"></div>-->
      <!--      <div v-if="!rights.put" class="no_write"></div>-->
    </div>

    <div v-if="lock" style="position: absolute; top:0; right: 0; z-index: 2">
      <div class="lock"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WarehouseTab",
  props: ['title', 'id', 'remote', 'aInv', 'sAInv', 'office', 'rights', 'lock', 'green_animate'],
  data() {
    return {
      checkValidBox: null,
    }
  },
  destroyed() {
    if (this.checkValidBox) {
      clearInterval(this.checkValidBox)
    }
  },
  created() {
    let app = this;

    let house = app.inventory.warehouses[app.$props.id];
    if (house.meta && house.meta.type === 'box') {
      app.checkValidBox = setInterval(function () {
        app.$store.dispatch("sendSocketData", JSON.stringify({
          service: "inventory",
          data: {
            event: "checkBox",
            id: house.meta.id,
          }
        }));
      }, 100)
    }
  },
  mounted() {
    let app = this;

    $(app.$el).droppable({
      tolerance: "pointer",
      over: function (event, ui) {
        console.log(22222)
      },
      drop: function (event, ui) {

        $('.ui-selected').removeClass('ui-selected');

        let draggable = ui.draggable;

        // если родитель конструктор то это снятие эквипа с тушки, там свои методы
        if (draggable.data("slotData").parent === "Constructor") {
          app.$store.dispatch("sendSocketData", JSON.stringify({
            service: "inventory",
            data: {
              event: draggable.data("slotData").event,
              equip_slot: Number(draggable.data("slotData").equipSlot),
              equip_slot_type: Number(draggable.data("slotData").equipType),
              destination: app.$props.id,
            }
          }));
        } else {
          if (!draggable.data("selectedItems")) return;

          let slots = {}
          for (let slotNumber of draggable.data("selectedItems").slotsNumbers) {
            slots[slotNumber] = 0
          }

          app.$store.dispatch("sendSocketData", JSON.stringify({
            service: "inventory",
            data: {
              event: "itemsToDst",
              source: draggable.data("slotData").parent,
              destination: app.$props.id,
              storage_slots: slots,
              strategy: location.href.includes('global') ? 'global' : '',
            }
          }));
        }
      }
    });
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    switchWarehouse(warehouse) {
      if (this.$props.remote && !this.inventory.warehouses[warehouse].inventory) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "inventory",
          data: {
            event: "OpenInventory",
            name: warehouse,
          }
        }));
      }

      this.playSound('button_press.mp3', 0.3)
      this.$store.commit({
        type: 'setCurrentWarehouse',
        warehouse: warehouse,
      });
    },
  },
  computed: {
    inventory() {
      return this.$store.getters.getInventory
    },
    globalPath() {
      return this.$store.getters.getGlobalPath
    },
    size() {

      if (!this.inventory.warehouses || !this.inventory.warehouses[this.$props.id] || !this.inventory.warehouses[this.$props.id].size) {
        return 0
      }

      return this.inventory.warehouses[this.$props.id].size
    },
    capacity() {
      if (!this.inventory.warehouses || !this.inventory.warehouses[this.$props.id] || !this.inventory.warehouses[this.$props.id].size) {
        return 0
      }

      return this.inventory.warehouses[this.$props.id].capacity
    },
    fill() {
      let size = this.size;
      let cap = this.capacity;

      if (cap === 0) {
        return 0
      }

      return 100 / (cap / size)
    },
    path() {
      let n = this.$props.id.split(':');
      if (n.length > 1 && n[0] === 'storage') {
        return this.globalPath.baseID === Number(n[1])
      }
    },
  },
}
</script>

<style scoped>
.WarehousesTabs {
  text-align: left;
  display: block;
  width: calc(100% - 7px);
  padding-left: 5px;
  background: rgba(136, 68, 34, 0.5);
  box-shadow: 0 0 2px rgb(0 0 0);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 3px 0 0 3px;
  margin: 2px 0 0 2px;
  font-size: 12px;
  line-height: 16px;
  height: 15px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}

.WarehousesTabs.active {
  background: rgba(221, 112, 52, 0.4);
  color: rgba(255, 255, 255, 1);
}

.WarehousesTabs:hover {
  box-shadow: 0 0 4px 0 #20fffd;
}

/*.office {*/
/*  background: rgb(59, 183, 12);*/
/*}*/

.WarehousesTabs.scanner {
  margin-top: 4px;
  margin-bottom: 4px;
}

.aInv {
  position: relative;
  left: 15px;
  width: calc(100% - 22px);
}

.sAInv {
  margin: -2px 0 0 2px;
}

.disable {
  opacity: 0.5;
}

.no_view, .no_read, .no_write, .lock {
  float: right;
  background-image: url('../../assets/icons/not_see.png');
  height: 16px;
  width: 16px;
  background-size: contain;
}

.lock {
  background-image: url('../../assets/icons/lock.png');
  filter: drop-shadow(0px 0px 1px black);
}

.WarehousesTabBackground {
  background: rgb(136, 68, 34);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  box-shadow: 0 0 2px black
}

.WarehousesTabBackground.active {
  background: rgb(221, 112, 52);
}

.remote {
  background: rgb(128, 128, 128);
}

.path {
  background: rgba(227, 152, 22, 0.9);
}

.WarehousesTabBackground.scanner {
  background: rgba(255, 0, 0, 0.9) !important;
}

.green_animate {
  box-shadow: inset 0 0 4px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: inset 0 0 2px 1px greenyellow;
  }
  50% {
    box-shadow: inset 0 0 6px 2px greenyellow;
  }
  100% {
    box-shadow: inset 0 0 2px 1px greenyellow;
  }
}
</style>
