import {Scene} from "../create";
import {gameStore} from "../store";
import {GetGlobalPos} from "../map/gep_global_pos";
import {CreateSpawn} from "../map_editor/spawn";
import {statusBarWrapper} from "./status_bar";
import {drawFrameAndBackground} from "./mini_map_input";

let minimap = {
  backRenderTexture: null,
  frame: {
    backGround: null,
    frame: null,
    buttons: {
      zoomp: null,
      zoomm: null,
      on: null,
      off: null,
      battle_view: null,
      init: false,
      zoomChange: false,
    }
  },
  nominalSize: 2048,
  mainMapRectangle: null,
  background: null,
  size: 200,
  init: false,
  mapPoints: {},
  radarRing: null,
  globalPath: {
    line: null,
  },
  missionPath: {},
  removeMissionPathTask: null,
  signals: {},
  scale: 2,
}

function initMiniMap() {
  if (minimap.backRenderTexture) return;

  // if ($(window).width() < 1000) {
  //   minimap.size = 118
  // }
  //
  // if ($(window).width() === 1000) {
  //   minimap.size = 150
  // }

  let x = Scene.cameras.main.width - minimap.size - 38;
  let y = 18;

  minimap.init = true;
  minimap.backRenderTexture = Scene.cameras.add(x, y, minimap.size, minimap.size).setName('mini');
  updateMiniMap(true)

  let wait = setInterval(function () {
    if (statusBarWrapper.scene) {
      drawFrameAndBackground()
      clearInterval(wait)
    }
  })
}

function setPositionObject(type, sprite, sizeX, sizeY, ownerID, warrior, fraction, corpID, work) {
  if (!sprite || !gameStore.gameReady || !type || fraction === 5) return;

  if (!sprite.RadarMark) {

    let offsetSize = gameStore.map.XSize / minimap.nominalSize

    let mark = Scene.make.image({
      x: sprite.x,
      y: sprite.y,
      key: "radar",
      frame: type + "_mini",
      add: true,
    });
    sprite.RadarMark = mark
    sprite.RadarMark.setOrigin(0.5);
    sprite.RadarMark.setDisplaySize((sizeX * offsetSize) / minimap.scale, (sizeY * offsetSize) / minimap.scale);
    sprite.RadarMark.setDepth(1100);

    mark.viewCallback = function (v) {
      if (mark.gameCache) return;
      mark.visible = v
    }

    if (type !== 'bullet' && type !== 'map_item') {
      let color = getObjectColorByUserID(ownerID, warrior, fraction, corpID, work)
      sprite.RadarMark.setTint(color);
      sprite.RadarMark.markColor = color;
    }

    Scene.cameras.main.ignore(sprite.RadarMark)
  }

  if (sprite.RadarMark && sprite.RadarMark.NeedUpdate) {
    let color = getObjectColorByUserID(ownerID, warrior, fraction, corpID, work)
    let offsetSize = gameStore.map.XSize / minimap.nominalSize
    sprite.RadarMark.setDisplaySize((sizeX * offsetSize) / minimap.scale, (sizeY * offsetSize) / minimap.scale);
    sprite.RadarMark.setTint(color);
    sprite.RadarMark.markColor = color;
    sprite.RadarMark.NeedUpdate = false
  }

  sprite.RadarMark.gameCache = false
  sprite.RadarMark.setPosition(sprite.x, sprite.y)
}

function setPositionMapRectangle() {
  if (minimap.mainMapRectangle) {
    minimap.mainMapRectangle.setPosition(Scene.cameras.main.worldView.centerX, Scene.cameras.main.worldView.centerY)
    minimap.mainMapRectangle.setDisplaySize(Scene.cameras.main.displayWidth, Scene.cameras.main.displayHeight)
  }
}

function SetPositionRadarRing(unit) {
  if (minimap.radarRing && unit && unit.sprite) {
    minimap.radarRing.setPosition(unit.sprite.x, unit.sprite.y)
    minimap.radarRing.setDisplaySize(unit.body.range_radar * 2, unit.body.range_radar * 2)
  }
}

function RenderGlobalPathInMiniMap(unit) {
  if (gameStore.globalPath.points && gameStore.globalPath.points.length > 0 && unit && unit.sprite) {
    for (let p of gameStore.globalPath.points) {
      if (p.map_id === gameStore.map.id) {

        let pos = GetGlobalPos(p.x, p.y, gameStore.map.id);
        if (!minimap.globalPath.line) {
          minimap.globalPath.line = Scene.add.line(0, 0, unit.sprite.x, unit.sprite.y, pos.x, pos.y, 0xE39816, 0.75);
          minimap.globalPath.line.setOrigin(0);
          minimap.globalPath.line.setLineWidth(25);
          Scene.cameras.main.ignore(minimap.globalPath.line);
          minimap.globalPath.line.setDepth(1100);
        } else {
          minimap.globalPath.line.setTo(unit.sprite.x, unit.sprite.y, pos.x, pos.y);
        }

        break
      }
    }
  } else {
    if (minimap.globalPath.line) {
      minimap.globalPath.line.destroy();
      minimap.globalPath.line = null;
    }
  }
}

function addIgnoreObject(sprite) {
  if (!minimap.backRenderTexture) {
    let wait = setInterval(function () {
      if (minimap.backRenderTexture) {
        minimap.backRenderTexture.ignore(sprite)
        clearInterval(wait)
      }
    }, 1000)
    return
  }

  minimap.backRenderTexture.ignore(sprite)
}

function getObjectColorByUserID(ownerID, warrior, fraction, corpID, work) {
  if (gameStore.playerID === ownerID) {
    return 0x00ff00
  }

  if (fraction === 4 || fraction === 6) {
    return 0xff0000
  }

  if (gameStore.playerCorporationID > 0 && gameStore.playerCorporationID === corpID) {
    if (work) {
      return 0x00f1fd
    } else {
      return 0x04838a
    }
  }

  if (gameStore.groupState.state[ownerID]) {
    return 0x00c6ff
  }

  if (gameStore.map.possible_battle) {
    if (warrior && fraction === 1) {
      return 0xbb5153
    }

    if (warrior && fraction === 2) {
      return 0x50c878
    }

    if (warrior && fraction === 3) {
      return 0x8095d9
    }
  }

  return 0xffffff
}

function updateMiniMap(updateMask) {

  // if ($(window).width() < 1000) {
  //   minimap.size = 118
  // } else if ($(window).width() === 1000) {
  //   minimap.size = 150
  // } else {
  //   minimap.size = 200
  // }

  // let miniMapWrapper = document.getElementById("miniMap")
  // if (miniMapWrapper) {
  //   miniMapWrapper.style.width = (minimap.size - 5) + 'px'
  //   miniMapWrapper.style.height = (minimap.size - 22) + 'px'
  //   miniMapWrapper.style.display = 'block'
  // }

  if (minimap.backRenderTexture) {
    minimap.backRenderTexture.setZoom(1 / (gameStore.map.XSize / (minimap.size * minimap.scale)))
    minimap.backRenderTexture.setBounds(0, 0, gameStore.map.XSize, gameStore.map.XSize);
    minimap.backRenderTexture.setBackgroundColor(0x002244);
    minimap.backRenderTexture.scrollX = gameStore.map.XSize / 2;
    minimap.backRenderTexture.scrollY = gameStore.map.YSize / 2;
    minimap.backRenderTexture.disableCull = false
    minimap.backRenderTexture.inputEnabled = false
    minimap.backRenderTexture.setViewport(Scene.cameras.main.width - minimap.size - 38, 18, minimap.size, minimap.size);
  }

  for (let id in minimap.mapPoints) {
    minimap.mapPoints[id].sprite.tween.remove();
    minimap.mapPoints[id].sprite.destroy();
    delete minimap.mapPoints[id]
  }

  if (!minimap.mainMapRectangle) {
    let mainMapRectangle = Scene.add.graphics({x: 0, y: 0, add: true});
    mainMapRectangle.lineStyle(15, 0xaaaa00);
    mainMapRectangle.strokeRect(0, 0, Scene.cameras.main.displayWidth / 2, Scene.cameras.main.displayHeight / 2);
    mainMapRectangle.generateTexture("main_map_rectangle", Scene.cameras.main.displayWidth / 2, Scene.cameras.main.displayHeight / 2);
    mainMapRectangle.destroy()

    minimap.mainMapRectangle = Scene.make.image({
      x: 0, y: 0, key: "main_map_rectangle", add: true
    });
    minimap.mainMapRectangle.setDepth(1000)
    Scene.cameras.main.ignore(minimap.mainMapRectangle)
  }

  if (!minimap.radarRing) {
    minimap.radarRing = Scene.make.image({
      key: "select_sprite",
      add: true
    });
    minimap.radarRing.setDepth(1000)
    minimap.radarRing.setTint(0x87cefa)
    minimap.radarRing.setAlpha(1)
    Scene.cameras.main.ignore(minimap.radarRing)
  }
}

function setUserUnit(unit) {
  if (minimap.backRenderTexture) {
    minimap.backRenderTexture.startFollow(unit.sprite);
    return
  }

  let waite = setInterval(function () {
    if (minimap.backRenderTexture) {
      minimap.backRenderTexture.startFollow(unit.sprite);
      clearInterval(waite)
    }
  }, 100)
}

function removeMissionPath() {
  for (let i in minimap.missionPath) {
    if (minimap.missionPath[i].line) {
      minimap.missionPath[i].line.destroy();
    }

    if (minimap.missionPath[i].points) {
      for (let j in minimap.missionPath[i].points) {

        if (minimap.missionPath[i].points[j].point) {
          minimap.missionPath[i].points[j].point.destroy();
          minimap.missionPath[i].points[j].point.tween.remove();
        }

        if (minimap.missionPath[i].points[j].map) minimap.missionPath[i].points[j].map.destroy();
        if (minimap.missionPath[i].points[j].miniMap) minimap.missionPath[i].points[j].miniMap.destroy();
      }
    }
  }

  minimap.missionPath = {};
}

function getPos(x, y, mapID, offset) {
  let pos = GetGlobalPos(x, y, mapID);

  if (pos.x < minimap.backRenderTexture.worldView.left + offset) {
    pos.x = minimap.backRenderTexture.worldView.left + offset
  }

  if (pos.x > minimap.backRenderTexture.worldView.right - offset) {
    pos.x = minimap.backRenderTexture.worldView.right - offset
  }

  if (pos.y < minimap.backRenderTexture.worldView.top + offset) {
    pos.y = minimap.backRenderTexture.worldView.top + offset
  }

  if (pos.y > minimap.backRenderTexture.worldView.bottom - offset) {
    pos.y = minimap.backRenderTexture.worldView.bottom - offset
  }

  return pos
}

function DrawMissionPath(unit) {

  let update = function () {

    if (!unit || !unit.sprite) return;

    for (let i in gameStore.missionPath.actions) {

      let action = gameStore.missionPath.actions[i];
      if (!minimap.missionPath.hasOwnProperty(i)) {
        minimap.missionPath[i] = {
          line: null,
          points: {},
        }
      }

      let currentMapPoints = [];
      for (let p of action) {
        if (p.map_id === gameStore.map.id) {
          currentMapPoints.push(p);
        }
      }

      if (currentMapPoints.length > 0) {

        let pos = GetGlobalPos(currentMapPoints[0].x, currentMapPoints[0].y, gameStore.map.id);
        if (currentMapPoints[0].line && !minimap.missionPath[i].line) {
          minimap.missionPath[i].line = Scene.add.line(0, 0, unit.sprite.x, unit.sprite.y, pos.x, pos.y, 0x00FF00, 0.75);
          minimap.missionPath[i].line.setOrigin(0);
          minimap.missionPath[i].line.setLineWidth(25 / (6144 / gameStore.map.XSize));
          Scene.cameras.main.ignore(minimap.missionPath[i].line);
          minimap.missionPath[i].line.setDepth(1100);
        } else if (minimap.missionPath[i].line && currentMapPoints.length > 0) {
          minimap.missionPath[i].line.setTo(unit.sprite.x, unit.sprite.y, pos.x, pos.y);
        }

        for (let p of currentMapPoints) {
          let pos = getPos(p.x, p.y, gameStore.map.id, 64 / (6144 / gameStore.map.XSize));

          if (minimap.missionPath[i].points.hasOwnProperty(p.x + ":" + p.y)) {
            minimap.missionPath[i].points[p.x + ":" + p.y].point.setPosition(pos.x, pos.y)
            continue
          }

          let scale = 12 / (6144 / gameStore.map.XSize)
          if (!p.radius) scale = scale / 1.5;

          let point = Scene.make.image({
            x: pos.x,
            y: pos.y,
            key: "radar",
            frame: "map_point",
            add: true,
          });
          point.setOrigin(0.5);
          point.setScale(scale);
          point.setDepth(1110);

          point.tween = Scene.tweens.add({
            targets: point,
            props: {
              scale: {value: scale - 2, duration: 750},
            },
            yoyo: true,
            repeat: -1,
          });
          Scene.cameras.main.ignore(point)

          if (!p.nc && p.radius > 0) {
            let map = CreateSpawn({x: p.x, y: p.y, radius: p.radius}, "mission")
            minimap.missionPath[i].points[p.x + ":" + p.y] = {
              point: point,
              map: map.captureCircle,
              miniMap: map.captureCircleMiniMap
            }
          } else {
            minimap.missionPath[i].points[p.x + ":" + p.y] = {
              point: point,
            }
          }
        }
      }
    }
  }

  if (!minimap.removeMissionPathTask) {
    minimap.removeMissionPathTask = Scene.time.addEvent({
      delay: 750 * 2,
      callback: function () {
        removeMissionPath();
        update();
        minimap.removeMissionPathTask.remove();
        minimap.removeMissionPathTask = null;
      }
    })
  }
}

function updateSignals() {
  for (let uuid in gameStore.distressSignals) {
    if (!minimap.signals[uuid]) {
      let pos = GetGlobalPos(gameStore.distressSignals[uuid].x, gameStore.distressSignals[uuid].y, gameStore.map.id);
      minimap.signals[uuid] = Scene.make.image({
        x: pos.x,
        y: pos.y,
        key: "radar",
        frame: "distress_signal",
        add: true
      });
      minimap.signals[uuid].setDepth(1001)
      minimap.signals[uuid].setTint(0xFF0000)
      minimap.signals[uuid].setAlpha(1)
      minimap.signals[uuid].setScale(9 / minimap.scale);
      Scene.cameras.main.ignore(minimap.signals[uuid])

      minimap.signals[uuid].tween = Scene.tweens.add({
        targets: minimap.signals[uuid],
        props: {
          scale: {value: 5, duration: 500},
        },
        yoyo: true,
        repeat: -1,
      });
    }
  }

  for (let uuid in minimap.signals) {
    if (!gameStore.distressSignals[uuid]) {
      minimap.signals[uuid].tween.remove();
      minimap.signals[uuid].destroy();
      delete minimap.signals[uuid]
    }
  }
}

function updateMinimapLabels() {
  let offset = 256

  if (gameStore.map && gameStore.map.handlers_coordinates && minimap.backRenderTexture) {
    for (let h of gameStore.map.handlers_coordinates) {
      if (h.sprite) {
        let pos = getPos(h.realX, h.realY, gameStore.map.id, offset / 2);
        h.sprite.setPosition(pos.x, pos.y)
      }
    }

    for (let key in gameStore.spawns) {
      if (key.includes("mini_label")) {
        let pos = getPos(gameStore.spawns[key].RealX, gameStore.spawns[key].RealY, gameStore.map.id, offset);
        gameStore.spawns[key].setPosition(pos.x, pos.y)
      }
    }
  }
}

function initMinimapLabels() {
  if (gameStore.map && gameStore.map.handlers_coordinates) {
    for (let i of gameStore.map.handlers_coordinates) {
      if (i.sprite) i.sprite.destroy();
    }
  }

  let bases = {}

  for (let i in gameStore.map.handlers_coordinates) {
    let icon = "back_to_base"
    let x, y

    let h = gameStore.map.handlers_coordinates[i]
    if (h.handler === 'sector') {
      icon = 'teleport'
      x = h.x
      y = h.y
    }

    if (h.handler === 'base') {
      if (bases[h.to_base_id]) {
        // icon = "base_icon"
        // x = h.x;
        // y = h.y;
        continue
      }

      //if (gameStore.bases[h.to_base_id].type === "base") {
      icon = "base_icon"
      //}

      if (gameStore.bases[h.to_base_id]) {
        if (gameStore.bases[h.to_base_id].type === "prefabricated_plant") {
          icon = "prefabricated_plant_icon"
        }

        if (gameStore.bases[h.to_base_id].type === "processing_plant") {
          icon = "processing_plant_icon"
        }

        if (gameStore.bases[h.to_base_id].type === "mining_installation") {
          icon = "mining_installation_icon"
        }

        if (gameStore.bases[h.to_base_id].type === "replic_bunker" ||
          gameStore.bases[h.to_base_id].type === "reverses_terraforming_generator" ||
          gameStore.bases[h.to_base_id].type === "explores_science_center") {
          icon = "uniq_base_icon"
        }
      }

      for (let h2 of gameStore.map.handlers_coordinates) {
        if (h.to_base_id === h2.to_base_id && h.x !== h2.x && h.y !== h2.y) {
          x = (h.x + h2.x) / 2;
          y = (h.y + h2.y) / 2;

          bases[h.to_base_id] = true
          break
        }
      }

      if (!bases[h.to_base_id]) {
        x = h.x;
        y = h.y;
        bases[h.to_base_id] = true
      }
    }

    h.realX = x
    h.realY = y
    let pos = GetGlobalPos(x, y, gameStore.map.id);
    let iconSprite = Scene.make.image({
      x: pos.x,
      y: pos.y,
      add: true,
      key: "radar",
      frame: icon,
    });
    iconSprite.setOrigin(0.5);
    iconSprite.setDepth(999);
    iconSprite.setDisplaySize(300, 300);
    // iconSprite.setAlpha(1)
    Scene.cameras.main.ignore(iconSprite);
    gameStore.map.handlers_coordinates[i].sprite = iconSprite
  }
}

export {
  initMiniMap,
  setPositionObject,
  addIgnoreObject,
  updateMiniMap,
  setPositionMapRectangle,
  minimap,
  SetPositionRadarRing,
  RenderGlobalPathInMiniMap,
  DrawMissionPath,
  updateSignals,
  setUserUnit,
  initMinimapLabels,
  updateMinimapLabels,
  removeMissionPath,
}
