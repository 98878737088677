import {gameStore} from "../store";
import {AddMoveBufferData} from "../utils/add_move_buffer_data";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {ShortDirectionRotateTween} from "../utils/utils";
import {setPositionObject} from "../interface/mini_map";

function AddItemMoveBufferData(data) {
  let item = gameStore.mapItems[data.id];
  if (item) {
    AddMoveBufferData(data, item, 6)
  }
}

function ItemMove(item, ms) {
  let path = item.bufferMoveTick.shift();
  if (!path) {
    return;
  }
  let pos = GetGlobalPos(path.x, path.y, gameStore.map.id);

  MoveSprite(item.sprite, pos.x, pos.y, ms, null);
  MoveSprite(item.shadow, pos.x + 1, pos.y + 1, ms, null, false, false, function () {
    if (item.gameCache) {
      return;
    }

    setPositionObject('map_item', item.sprite, 64, 64)
  });

  MoveSprite(item.light, pos.x, pos.y, ms, null);

  ShortDirectionRotateTween(item.sprite, path.r, ms);
  ShortDirectionRotateTween(item.shadow, path.r, ms);
  ShortDirectionRotateTween(item.light, path.r, ms);
}

export {AddItemMoveBufferData, ItemMove}
