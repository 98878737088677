import {gameStore} from "../store";
import {Scene} from "../create";
import store from "../../store/store";
import {zoomMinMax} from "../interface/mini_map_input";

function ViewGhostBullet(id) {
  let b = gameStore.bullets[id]
  if (!b || !b.sprite) return;

  if (!gameStore.OtherFollowSprite) {
    gameStore.OtherFollowSprite = true
    // zoom
    Scene.cameras.main.setZoom(1.25 * zoomMinMax.k);
    store.dispatch('changeSettings', {
      name: "ZoomCamera",
      count: 1.5 * 100
    });

    store.commit({
      type: 'setZoomCamera',
      zoom: 1.5,
    });

    Scene.cameras.main.pan(b.sprite.x, b.sprite.y, 256, Phaser.Math.Easing.Linear, true, function (camera, progress, x, y) {
      if (progress === 1) {
        Scene.cameras.main.startFollow(b.sprite);
      }
    })

    let checker = Scene.time.addEvent({
      delay: 128,
      callback: function () {
        let b = gameStore.bullets[id]
        if (!b || !b.sprite) {
          checker.remove();
          gameStore.OtherFollowSprite = false
        }
      },
      loop: true,
    });
  }
}

export {ViewGhostBullet}
