import {UnitHeight} from "../unit/unit";
import {Scene} from "../create";
import {gameStore} from "../store";
import {GetGlobalPos} from "../map/gep_global_pos";

let renderState = {
  rtUp: null,
  rtDown: null,
  objects: {},
  timeOut: 512,
  rerenderTask: {
    rtUp: {
      timer: null,
      lastRun: 0
    },
    rtDown: {
      timer: null,
      lastRun: 0
    },
  },
  addNewTimer: null,
  newObjects: {
    rtUp: [],
    rtDown: [],
  },
  cacheObject: {},
}

function InitRenderWorld() {
  return
  let init = function (layer) {

    if (renderState[layer]) {
      renderState[layer].clear();
      renderState[layer].destroy();
    }

    let pos = GetGlobalPos(0, 0, gameStore.map.id);
    let rt = Scene.add.renderTexture(pos.x, pos.y, gameStore.map.XSize, gameStore.map.YSize);
    renderState[layer] = rt

    if (layer === 'rtDown') {
      rt.setDepth(-2);
      rt.setOrigin(0);
    } else {
      rt.setDepth(300);
      rt.setOrigin(0);
    }
  }

  init('rtUp')
  init('rtDown')
}

function AddRenderWorld(obj, x, y, atlasName) {
  obj.needShadow = obj.shadow && obj.shadow_intensity > 0
  obj.pos = {x: x, y: y}
  obj.atlasName = atlasName
  obj.layer = obj.height > UnitHeight ? 'rtUp' : 'rtDown'

  renderState.newObjects[obj.layer].push(obj)

  let rt = renderState[obj.layer]
  if (!rt) {
    InitRenderWorld()
  }

  if (!renderState.addNewTimer) {
    renderState.addNewTimer = Scene.time.addEvent({
      delay: 128,
      callback: function () {
        renderState.addNewTimer = null;
        addNew()
      }
    })
  }
}

function addNew() {
  let renderNew = function (k) {
    let rt = renderState[k]
    if (!rt) return;

    let removes = [];
    rt.beginDraw();

    for (let obj of renderState.newObjects[k]) {
      renderState.objects[obj.id] = obj
      render(obj, function (o) {
        rt.batchDraw(o)
        removes.push(o)
      })
    }

    rt.endDraw();
    for (let r of removes) {
      r.visible = false
    }
  }

  renderNew('rtUp')
  renderNew('rtDown')
}

function allRerenderByObject(id) {
  let obj = renderState.objects[id]
  delete renderState.objects[id]
  if (!obj) return;

  if (renderState.rerenderTask[obj.layer].timer) {
    return;
  }

  renderState.rerenderTask[obj.layer].timer = Scene.time.addEvent({
    delay: renderState.timeOut,
    callback: function () {
      renderState.rerenderTask[obj.layer].timer = null;
      rerender(obj.layer)
    }
  })
}

function rerender(layer) {
  let rt = renderState[layer]
  if (!rt) return;

  let removes = [];
  rt.clear();

  rt.beginDraw();
  for (let i in renderState.objects) {
    if (renderState.objects[i].layer === layer) {
      render(renderState.objects[i], function (o) {
        rt.batchDraw(o)
        removes.push(o)
      })
    }
  }
  rt.endDraw();

  for (let r of removes) {
    r.visible = false
  }
}

function render(obj, method) {
  if (obj.needShadow) {

    let shadow
    if (!renderState.cacheObject.hasOwnProperty(obj.texture + 'shadow')) {
      shadow = Scene.make.image({
        x: 0,
        y: 0,
        frame: obj.texture,
        add: true,
        key: obj.atlasName,
      });
      renderState.cacheObject[obj.texture + 'shadow'] = shadow
    } else {
      shadow = renderState.cacheObject[obj.texture + 'shadow']
      shadow.visible = true
    }

    shadow.setPosition(obj.pos.x + obj.x_shadow_offset, obj.pos.y + obj.y_shadow_offset)
    shadow.setOrigin(0.5);
    shadow.setScale(obj.scale / 100);
    shadow.setAngle(obj.rotate);
    shadow.setAlpha(obj.shadow_intensity / 100);
    shadow.setTint(0x000000);

    method(shadow);
  }

  let sprite
  if (!renderState.cacheObject.hasOwnProperty(obj.texture)) {
    sprite = Scene.make.image({
      x: 0,
      y: 0,
      frame: obj.texture,
      add: true,
      key: obj.atlasName,
    });
    renderState.cacheObject[obj.texture] = sprite
  } else {
    sprite = renderState.cacheObject[obj.texture]
    sprite.visible = true
  }

  sprite.setPosition(obj.pos.x, obj.pos.y)
  sprite.setOrigin(0.5);
  sprite.setScale(obj.scale / 100);
  sprite.setAngle(obj.rotate);

  method(sprite);
}

export {AddRenderWorld, allRerenderByObject, InitRenderWorld, renderState}
