import {Scene} from "../create";
import {gameStore} from "../store";
import {GetOffsetShadowByMapLvl, GetSpriteSizeByMapLvl} from "../map/height_offset";
import {MoveSprite} from "../utils/move_sprite";
import {addIgnoreObject} from "../interface/mini_map";

let meteoritesStore = {
  bullet: [],
  shadow: [],
};

function FlyMeteorites(data, bullet, mpID) {
  if (!bullet.sprite) {
    return
  }

  bullet.sprite.start()

  // MoveSprite(bullet.sprite, data.x, data.y, data.ms, null);
  Scene.tweens.add({ // если двигать плагином, то партиклы не оставляют хвост
    targets: bullet.sprite,
    duration: data.ms,
    x: data.x,
    y: data.y,
  });

  // bullet.setEmitterAngle({min: 180 + data.r, max: 180 + data.r});
  bullet.sprite.ops.angle.start = 180 + data.r;
  bullet.sprite.ops.angle.end = 180 + data.r;

  if (bullet.shadow) {
    bullet.shadow.start()
    let shadowPos = GetOffsetShadowByMapLvl(data.z, data.x, data.y, 0, 2.5, mpID);
    MoveSprite(bullet.shadow, shadowPos.x, shadowPos.y, data.ms, null);

    //bullet.shadow.emitter.setEmitterAngle({min: 180 + data.r, max: 180 + data.r});
    bullet.shadow.ops.angle.start = 180 + data.r;
    bullet.shadow.ops.angle.end = 180 + data.r;

    //bullet.shadow.emitter.setScale({start: GetSpriteSizeByMapLvl(data.z, 1 / 5, 0.05).x, end: 0});
    bullet.shadow.ops.scaleX.start = GetSpriteSizeByMapLvl(data.z, 1 / 5, 0.05).x;
    bullet.shadow.ops.scaleX.end = 0;
  }
}

function CreateFireBullet(data, infoBullet) {
  if (infoBullet.type === "meteorite") return createFireBullet(data, "yellow")
  if (infoBullet.name === "ballistics_artillery_bullet_2" || infoBullet.name === "big_lens_1_additional") return createFireBullet(data, "blue", infoBullet)
}

function createFireBullet(data, color, infoBullet) {
  let scale = 0.4
  let shadow = true
  let lifespan = 750
  let speed = {min: 100, max: 500}

  let bullet = gameStore.bullets[data.id];
  if (!bullet) {
    bullet = {};
    bullet.bufferMoveTick = [];
    gameStore.bullets[data.id] = bullet;
  }

  if (infoBullet && infoBullet.name === "big_lens_1_additional") {
    scale = 0.2
    shadow = false
    lifespan = 300
    speed = {min: 100, max: 100}
  }

  bullet.type_bullet = "meteorite";
  if (meteoritesStore.bullet.length === 0) {
    bullet.sprite = Scene.add.particles(0, 0, 'flares', {
      frame: color + "_mini",
      x: 0,
      y: 0,
      lifespan: lifespan,
      speed: speed,
      angle: {min: data.r - 5, max: data.r + 5},
      gravityY: 0,
      scale: {start: scale * 4, end: 0},
      quantity: 4,
      blendMode: 'SCREEN',
      alpha: {start: 1, end: 0},
    });
    bullet.sprite.stop()
    bullet.sprite.setDepth(data.z);
  } else {
    bullet.sprite = meteoritesStore.bullet.shift()
    bullet.sprite.setDepth(data.z);
    bullet.sprite.setVisible(true);

    bullet.sprite.setEmitterFrame(color + "_mini");

    bullet.sprite.ops.angle.start = data.r - 5;
    bullet.sprite.ops.angle.end = data.r + 5;

    bullet.sprite.ops.scaleX.start = scale * 4;
    bullet.sprite.ops.scaleX.end = 0;

    bullet.sprite.ops.lifespan.current = lifespan
    bullet.sprite.ops.lifespan.propertyValue = lifespan

    bullet.sprite.ops.speedX.start = speed.min;
    bullet.sprite.ops.speedX.end = speed.max;
    bullet.sprite.ops.speedX.propertyValue = speed;
  }

  if (shadow) {
    if (meteoritesStore.shadow.length === 0) {
      bullet.shadow = Scene.add.particles(0, 0, 'flares', {
        frame: color + "_mini",
        x: 0,
        y: 0,
        lifespan: 750,
        speed: {min: 50, max: 250},
        angle: {min: data.r - 5, max: data.r + 5},
        gravityY: 0,
        scale: {start: scale * 4, end: 0},
        quantity: 2,
        alpha: {start: 0.1, end: 0},
      });
      bullet.shadow.stop()
      bullet.shadow.setDepth(data.z - 1);
      bullet.shadow.setParticleTint(0x000000);
    } else {
      bullet.shadow = meteoritesStore.shadow.shift()
      bullet.shadow.setDepth(data.z - 1);
      bullet.shadow.setVisible(true);

      bullet.shadow.setEmitterFrame(color + "_mini");

      bullet.shadow.ops.angle.start = data.r - 5;
      bullet.shadow.ops.angle.end = data.r + 5;
    }
  }

  bullet.x = data.x;
  bullet.y = data.y;

  addIgnoreObject(bullet.sprite)
  if (bullet.shadow) {
    addIgnoreObject(bullet.shadow)
    bullet.shadow.x = GetOffsetShadowByMapLvl(data.z, data.x, data.y, 0, 2.5, gameStore.map.id).x;
    bullet.shadow.y = GetOffsetShadowByMapLvl(data.z, data.x, data.y, 0, 2.5, gameStore.map.id).y;
  }

  return bullet
}

export {FlyMeteorites, CreateFireBullet, meteoritesStore}
