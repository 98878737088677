import {GrabCamera, LazyCamera} from './interface/grabCamera'
import {FocusUnit} from './interface/focus_unit'
import {UpdateFogBack} from './interface/fog_of_war'
import {CreateAllMaps} from './map/createMap'
import {gameStore} from './store'
import store from '../store/store'
import {Scene} from "./create";
import {MoveTo} from "./unit/move";
import {updateAimBoxPos} from "./interface/server_gun_designator";
import {FlyBullet} from "./bullet/fly";
import {DroneTo} from "./drone/fly";
import {BoxMove} from "./box/move";
import {ObjectTo} from "./map/move_object";
import {
  initMiniMap,
  setPositionMapRectangle,
  updateMinimapLabels
} from "./interface/mini_map";
import {initUI} from "./interface/status_bar";
import {initPanel} from "./interface/equip_bar";
import {ItemMove} from "./map_items/move";
import {initGroupBar} from "./interface/group_bar";
import {initJoystick} from "./interface/joystick";
import {placeStructure} from "./build/select_place_build";
import {ParseMsg} from "./messages_queue";
import {UpdatePosPumpMiniGame} from "./reservoir/pump_mini_game";
import {MovePollen} from "./pollen/move";
import {sendInputsToBack} from "./send_input_data";
import {UpdateShieldPos} from "./shield/shield";
import {ServerMoveState} from "./utils/add_move_buffer_data";
import {UpdateWatch} from "./radar/client_watcher";
import {zoomMinMax} from "./interface/mini_map_input";

let connect = null;
let tickTime = 64;
let serverMoveLag = 0; // благодаря этой задержки мы убиваем "рывки" в движениях

function gameInit(scene) {
  try {

    if (!(location.href.includes('global') || location.href.includes('map_editor'))) {
      gameStore.reload = true;
      gameStore.unitReady = false;
      return
    }

    if (!gameStore.gameDataInit.sendRequest) {
      gameStore.gameDataInit.sendRequest = true;
      gameStore.radarWork = false;

      if (!gameStore.mapEditor) {
        console.log("SEND INIT GAME")
        store.dispatch("sendSocketData", JSON.stringify({
          service: "global",
          data: {
            event: "InitGame",
          }
        }));
      }
    }

    if (!gameStore.mapEditor) {
      if (gameStore.reload || !gameStore.gameDataInit.data || !gameStore.init.items ||
        !gameStore.init.interface || !gameStore.init.inventory) return;
    }

    if (!gameStore.gameReady) {
      if (!gameStore.mapEditor) {
        connect = store.getters.getNodeWS;
      }

      if (!connect || !connect.ws || !connect.connect) return;

      // todo ксотыль без которого карта при переходе из сектра в сектор артефачит
      if (!gameStore.mapDrawing) {
        gameStore.mapDrawing = true;
        setTimeout(function () {
          CreateAllMaps(scene);
          gameStore.gameReady = true;
          gameStore.mapDrawing = false;

          let currentZoom = store.getters.getSettings.ZoomCamera;
          if (!gameStore.mapEditor) Scene.cameras.main.setZoom(currentZoom * zoomMinMax.k);
        })
      }

      if (!gameStore.mapEditor) {
        connect.ws.postMessage(JSON.stringify({
          event: "StartLoad",
        }));
      }
    }
  } catch (e) {
    store.commit({
      type: 'addSystemError',
      error: e + '<br>' + e.stack,
    });

    throw e
  }
}

function update() {
  try {

    ParseMsg()

    if (gameStore.exitTab) {
      return;
    }

    if (!gameStore.gameReady) {
      gameInit(this)
      return;
    }

    if (!gameStore.OtherFollowSprite) {
      if (!gameStore.gameSettings.follow_camera || gameStore.mapEditor) {
        GrabCamera(this);  // функцуия для перетаскивания карты мышкой /* Магия */
      }

      if (gameStore.unitReady && gameStore.gameSettings.follow_camera && gameStore.gameSettings.lazy_camera) { // todo вынести в настройки
        LazyCamera(this)
      }
    }

    if (!connect || !connect.ws || !connect.connect) return;

    if (!gameStore.unitReady && gameStore.radarWork) {
      let unit = gameStore.units[gameStore.user_squad_id];
      if (unit && unit.sprite) {
        setUnitReady(unit.id)
      }
    }

    if (placeStructure) {
      if (this.wasd.left.isDown) {
        placeStructure.objectSprite.angle++;
      } else if (this.wasd.right.isDown) {
        placeStructure.objectSprite.angle--;
      }
    }

    if (!gameStore.mapEditor) {
      processMove();

      sendInputsToBack(this, connect);
      UpdateFogBack(this);

      updateAimBoxPos(this);
      updateMinimapLabels();
      UpdatePosPumpMiniGame();
      initJoystick();
      UpdateShieldPos();

      setPositionMapRectangle();

      initUI();
      initMiniMap();
      initPanel();
      initGroupBar();

      // testGlitch();
    } else {
      if (gameStore.mapEditorState.placeObj) {
        if (this.wasd.left.isDown) {
          gameStore.mapEditorState.placeObj.angle++;
        } else if (this.wasd.right.isDown) {
          gameStore.mapEditorState.placeObj.angle--;
        }
      }
    }

    // if (!decalSprite) {
    //   decalSprite = Scene.make.image({
    //     key: 'd' + 8, x: 0, y: 0, add: true
    //   });
    //   decalSprite.setOrigin(0.5);
    //   decalSprite.setAngle(0);
    //   decalSprite.setAlpha(0.3)
    //   decalSprite.setDepth(999)
    // } else {
    //   decalSprite.setPosition(
    //     Scene.input.mousePointer.x / Scene.cameras.main.zoom + Scene.cameras.main.worldView.x,
    //     Scene.input.mousePointer.y / Scene.cameras.main.zoom + Scene.cameras.main.worldView.y)
    // }
  } catch (e) {
    store.commit({
      type: 'addSystemError',
      error: e + '<br>' + e.stack,
    });

    throw e
  }
}

function processMove() {

  for (let i in ServerMoveState.messages) {
    let obj = ServerMoveState.messages[i].object
    if (ServerMoveState.messages[i].type === 1) { // unit
      if (obj.updaterPos && (!obj.sprite.moveTween || !obj.sprite.moveTween.isPlaying() || obj.bufferMoveTick.length > serverMoveLag)) {
        MoveTo(obj, tickTime);
        UpdateWatch(obj, obj.sprite, ServerMoveState.messages[i].type, 1)
        ServerMoveState.messages[i] = false
      }
    }

    if (ServerMoveState.messages[i].type === 2) { // drone
      if (!obj.moveTween || !obj.moveTween.isPlaying() || obj.bufferMoveTick.length > serverMoveLag) {
        if (obj.updaterPos) {
          DroneTo(obj, tickTime, i)
          UpdateWatch(obj, obj.sprite, ServerMoveState.messages[i].type, 1)
        } else {
          obj.speed = 0
        }
        ServerMoveState.messages[i] = false
      }
    }

    if (ServerMoveState.messages[i].type === 3) { // bullet
      if ((!obj.moveTween || !obj.moveTween.isPlaying() || obj.bufferMoveTick.length > serverMoveLag) && obj.updaterPos) {
        FlyBullet(obj, tickTime)
        ServerMoveState.messages[i] = false
      }
    }

    if (ServerMoveState.messages[i].type === 4) { // box
      if (!obj.moveTween || !obj.moveTween.isPlaying() || obj.bufferMoveTick.length > serverMoveLag) {
        if (obj.updaterPos) {
          BoxMove(obj, tickTime)
          UpdateWatch(obj, obj.sprite, ServerMoveState.messages[i].type, 1)
          ServerMoveState.messages[i] = false
        }
      }
    }

    if (ServerMoveState.messages[i].type === 5) { // object
      if (!obj.moveTween || !obj.moveTween.isPlaying() || obj.bufferMoveTick.length > serverMoveLag) {
        if (obj.updaterPos) {
          ObjectTo(obj, tickTime)
          UpdateWatch(obj, obj.sprite, ServerMoveState.messages[i].type, 1)
          ServerMoveState.messages[i] = false
        }
      }
    }

    if (ServerMoveState.messages[i].type === 6) { // map_item
      if (!obj.moveTween || !obj.moveTween.isPlaying() || obj.bufferMoveTick.length > serverMoveLag) {
        if (obj.updaterPos) {
          ItemMove(obj, tickTime)
          UpdateWatch(obj, obj.sprite, ServerMoveState.messages[i].type, 1)
          ServerMoveState.messages[i] = false
        }
      }
    }

    if (ServerMoveState.messages[i].type === 7) { // pollens
      if ((!obj.moveTween || !obj.moveTween.isPlaying() || obj.bufferMoveTick.length > serverMoveLag) && obj.updaterPos) {
        MovePollen(obj, tickTime)
        UpdateWatch(obj, obj.sprite, ServerMoveState.messages[i].type, 1)
        ServerMoveState.messages[i] = false
      }
    }
  }

  ServerMoveState.messages = ServerMoveState.messages.filter(Boolean);
}

// let decalSprite
// function testGlitch() {
//   let pos = GetGlobalPos(5299, 2696, gameStore.map.id);
//   for (let i in gameStore.units) {
//     if (gameStore.playerID === gameStore.units[i].owner_id) {
//       let unit = gameStore.units[gameStore.units[i].id];
//
//       let d = Phaser.Math.Distance.Between(pos.x, pos.y, unit.sprite.x, unit.sprite.y);
//       if (d < 1000) {
//         let percent = (100 - ((d / 1000) * 100)) / 20
//         store.commit({
//           type: 'setGlitch',
//           f: percent,
//         });
//         console.log(percent)
//       } else {
//         store.commit({
//           type: 'setGlitch',
//           f: 0,
//         });
//       }
//     }
//   }
// }

function setUnitReady(focusUnit) {
  gameStore.unitReady = true;
  gameStore.playerUnitID = focusUnit;
  Scene.game.input.activePointer.moveTime = 0;
  gameStore.focusUnitID = 0;

  if (focusUnit) FocusUnit(focusUnit, true, true);
  store.commit({
    type: 'setVisibleLoader',
    visible: false,
  });

  //setTimeout(function () {
  connect.ws.postMessage(JSON.stringify({
    event: "LoadComplete",
  }));
  //}, 1000)
}

function checkView(ownerId, warrior, fraction, corporationID) {
  if (ownerId === gameStore.playerID || gameStore.groupState.state[ownerId]) {
    return true
  }

  if (corporationID > 0 && gameStore.mapCorporationID === gameStore.playerCorporationID && gameStore.mapCorporationID === corporationID) {
    return true
  }

  return !!(gameStore.map.possible_battle && gameStore.userUnit && warrior && gameStore.userUnit.warrior && gameStore.userUnit.fraction === fraction);
}

export {update, checkView, serverMoveLag, tickTime}
