const getters = {
  getWS: function (state) {
    return state.ws
  },
  getNodeWS: function (state) {
    return state.node_ws
  },
  getCheckViewPort: function (state) {
    return state.CheckViewPort
  },
  getSkins: function (state) {
    return state.Skins
  },
  getSkills: function (state) {
    return state.mySkills
  },
  getEndLoad: function (state) {
    return state.EndLoad
  },
  getPlayer: function (state) {
    return state.Player
  },
  getUsers: function (state) {
    return state.UsersStat
  },
  getUserTraining: function (state) {
    return state.training
  },
  getChatState: function (state) {
    return state.Chat
  },
  getGlitch: function (state) {
    return state.Glitch
  },
  getTrailer: function (state) {
    return state.Trailer
  },
  getChatGroup: state => type => {
    for (let i in state.Chat.groups) {
      if (state.Chat.groups[i].type === type) {
        return state.Chat.groups[i]
      }
    }
  },
  getAvatars: function (state) {
    return state.Chat.avatars
  },
  getCorpLogo: function (state) {
    return state.Chat.corporationsLogo
  },
  getInterfaceState: function (state) {
    if (!state.Interface.state) {
      return null
    }
    return state.Interface.state[state.Interface.resolution]
  },
  getNeedOpenComponents: function (state) {
    return state.NeedOpenComponents
  },
  getDamage: function (state) {
    return state.Damage
  },
  getCriticalDamage: function (state) {
    return state.CriticalDamage
  },
  getInventory: function (state) {
    return state.Inventory
  },
  getWorldMapState: function (state) {
    return state.WorldMap
  },
  getPriorityWarMap: function (state) {
    return state.PriorityWarMap
  },
  getGlobalPath: function (state) {
    return state.WorldMap.global_path
  },
  getSecureHandlers: function (state) {
    return state.WorldMap.secureHandlers
  },
  getOpenDialog: function (state) {
    return state.OpenDialog
  },
  getMissions: function (state) {
    return state.Missions
  },
  getCurrentActions: function (state) {
    return state.Missions.currentActions
  },
  getFullAllMissions: function (state) {
    return state.AllFullMissions
  },
  getBaseStatus: function (state) {
    return state.Lobby.base
  },
  getBaseProductStatus: function (state) {
    return state.Lobby.base.products
  },
  getWorkbenchState: function (state) {
    return state.Lobby.workbench
  },
  getRecyclerState: function (state) {
    return state.Lobby.recycler
  },
  getPrefabricatedMenuState: function (state) {
    return state.Lobby.prefabricated_menu
  },
  getThoriumSlots: function (state) {
    return state.Game.thorium_slots
  },
  getAnomalies: function (state) {
    return state.Game.anomalies
  },
  getUnit: function (state) {
    return state.Inventory.unit
  },
  getUnitSpeed: function (state) {
    return state.Game.current_speed
  },
  getLowPower: function (state) {
    return state.Game.lowPower
  },
  getUnitState: function (state) {
    return state.Inventory.stateMS
  },
  getSectorState: function (state) {
    return state.Game.sector
  },
  getCountTransports: function (state) {
    return state.Game.sector.transports
  },
  getBuildMenu: function (state) {
    return state.BuildMenu
  },
  getNotifications: function (state) {
    return state.Notifications
  },
  getOpenObjects: function (state) {
    return state.Game.open_objects
  },
  getMarket: function (state) {
    return state.Market
  },
  getOrders: function (state) {
    return state.Market.orders
  },
  getMarketFilter: function (state) {
    return state.Market.filters
  },
  getAvailableAmmo: function (state) {
    return state.Game.availableAmmo
  },
  getSecureZone: function (state) {
    return state.Game.secure_zone
  },
  getGlobalTarget: function (state) {
    return state.Game.global_target
  },
  GetColorDamage: state => percentHP => {
    if (percentHP >= 80) {
      return "#48FF28"
    } else if (percentHP < 80 && percentHP >= 75) {
      return "#fff326"
    } else if (percentHP < 75 && percentHP >= 50) {
      return "#fac227"
    } else if (percentHP < 50 && percentHP >= 25) {
      return "#fa7b31"
    } else if (percentHP < 25) {
      return "#ff2615"
    }
  },
  getMapEditorData: function (state) {
    return state.MapEditor
  },
  getDialogEditorData: function (state) {
    return state.DialogEditor
  },
  getMissionEditorData: function (state) {
    return state.MissionEditor
  },
  getGameUser: function (state) {
    return state.GameUser
  },
  getUserPlayers: function (state) {
    return state.UserPlayers
  },
  getWaitGame: function (state) {
    return state.WaitGame
  },
  getGameRole: function (state) {
    return state.Game.role
  },
  getBattleState: function (state) {
    return state.Battle
  },
  getKillsNotify: function (state) {
    return state.KillsNotify
  },
  getPointsNotify: function (state) {
    return state.PointsNotify
  },
  getGroupState: function (state) {
    return state.Chat.group
  },
  getErrors: function (state) {
    return state.Errors
  },
  getGroupSessionState: function (state) {
    return state.Chat.sessionGroup
  },
  getSettings: function (state) {
    return state.Settings
  },
  getZoom: function (state) {
    return (100 + state.Settings.ZoomInterface) / 100
  },
  getSFXVolume: function (state) {
    return state.Settings.SFXVolume
  },
  getVolume: function (state) {
    return state.Settings.volume
  },
  getServerState: function (state) {
    return state.ServerState
  },
  getHandBook: function (state) {
    return state.HandBook
  },
  getInterfaceHandBook: state => (window) => {
    return state.InterfaceHandBook[window]
  },
  getFractionStoreState(state) {
    return state.FractionStore
  },
  getFractionNews(state) {
    return state.Chat.fractionNews
  },
  getBaseTypeName: state => (baseType, language) => {
    // todo затолкать это в хендбук

    if (language === 'RU') {
      if (baseType === 'base') return 'Главная база';
      if (baseType === 'mining_installation') return 'Добывающая установка';
      if (baseType === 'processing_plant') return 'Перерабатывающий завод';
      if (baseType === 'prefabricated_plant') return 'Завод расходных материалов';
      if (baseType === 'corporation') return 'Корпорация';
    }

    return "";
  },
  getSessionGamePlayersStatus(state) {
    return state.SessionGame.playersStatus
  },
  getSessionGamePlayers(state) {
    return state.SessionGame.players
  },
  getSessionGameReward(state) {
    return state.SessionGame.reward
  },
  getSessionGameLoses(state) {
    return state.SessionGame.loses
  },
  getBotsState(state) {
    return state.BotsState.bots
  },
  getBotState(state) {
    return state.BotsState.bot
  },
  getSearch(state) {
    return state.Search
  },
  getProducts(state) {
    return state.Products
  },
  getInit(state) {
    return state.init
  },
  getRelateBonuses(state) {
    return state.RelateBonuses
  },
  getHelpSelect(state) {
    return state.HelpSelect
  },
  getOpenBuildObject(state) {
    return state.OpenBuildObject
  },
  getCorporationBaseNames(state) {
    return state.Chat.corporationBaseNames
  },
  getAttackDialog(state) {
    return state.AttackDialog
  },
  getWars(state) {
    return state.Wars
  },
  getHeadquartersNames(state) {
    return state.HeadquartersNames
  },
  getCorporationLogs(state) {
    return state.CorporationLogs
  },
  getSynchronizationState(state) {
    return state.SynchronizationState
  },
  getTechnologyBodyConfig(state) {
    let language = state.Settings.Language;
    let texts = state.InterfaceHandBook['TechnologyMenu']

    return {
      Replics: {
        bodies: [
          {
            ids: [29],
            category: texts['lu'][language],
            pos: {x: 2, y: 1},
            linesTo: [{x: 3, y: 1}, {x: 5, y: 2}, {x: 5, y: 3}],
          }, {
            ids: [9],
            category: texts['bmm'][language],
            pos: {x: 6, y: 2},
            linesTo: [{x: 7, y: 2}],
          }, {
            ids: [8],
            category: texts['bhm'][language],
            pos: {x: 8, y: 2},
            linesTo: [],
          }, {
            ids: [10],
            category: texts['blf'][language],
            pos: {x: 4, y: 1},
            linesTo: [{x: 5, y: 1}],
          }, {
            ids: [32],
            category: texts['bmf'][language],
            pos: {x: 6, y: 1},
            linesTo: [{x: 7, y: 1}],
          }, {
            ids: [12],
            category: texts['bhf'][language],
            pos: {x: 8, y: 1},
            linesTo: [],
          }, {
            ids: [11],
            category: texts['bmt'][language],
            pos: {x: 6, y: 3},
            linesTo: [{x: 7, y: 3}],
          }, {
            ids: [35],
            category: texts['bht'][language],
            pos: {x: 8, y: 3},
            linesTo: [],
          },
        ]
      },
      Explores: {
        bodies: [
          {
            ids: [28],
            category: texts['blu'][language],
            pos: {x: 2, y: 1},
            linesTo: [{x: 3, y: 1}, {x: 5, y: 2}, {x: 5, y: 3}],
          }, {
            ids: [14],
            category: texts['bmm'][language],
            pos: {x: 6, y: 2},
            linesTo: [{x: 7, y: 2}],
          }, {
            ids: [13],
            category: texts['bhm'][language],
            pos: {x: 8, y: 2},
            linesTo: [],
          }, {
            ids: [15],
            category: texts['blf'][language],
            pos: {x: 4, y: 1},
            linesTo: [{x: 5, y: 1}],
          }, {
            ids: [31],
            category: texts['bmf'][language],
            pos: {x: 6, y: 1},
            linesTo: [{x: 7, y: 1}],
          }, {
            ids: [17],
            category: texts['bhf'][language],
            pos: {x: 8, y: 1},
            linesTo: [],
          }, {
            ids: [16],
            category: texts['bmt'][language],
            pos: {x: 6, y: 3},
            linesTo: [{x: 7, y: 3}],
          }, {
            ids: [34],
            category: texts['bht'][language],
            pos: {x: 8, y: 3},
            linesTo: [],
          },
        ]
      },
      Reverses: {
        bodies: [
          {
            ids: [30],
            category: texts['blu'][language],
            pos: {x: 2, y: 1},
            linesTo: [{x: 3, y: 1}, {x: 5, y: 2}, {x: 5, y: 3}],
          }, {
            ids: [19],
            category: texts['bmm'][language],
            pos: {x: 6, y: 2},
            linesTo: [{x: 7, y: 2}],
          }, {
            ids: [18],
            category: texts['bhm'][language],
            pos: {x: 8, y: 2},
            linesTo: [],
          }, {
            ids: [20],
            category: texts['blf'][language],
            pos: {x: 4, y: 1},
            linesTo: [{x: 5, y: 1}],
          }, {
            ids: [33],
            category: texts['bmf'][language],
            pos: {x: 6, y: 1},
            linesTo: [{x: 7, y: 1}],
          }, {
            ids: [22],
            category: texts['bhf'][language],
            pos: {x: 8, y: 1},
            linesTo: [],
          }, {
            ids: [21],
            category: texts['bmt'][language],
            pos: {x: 6, y: 3},
            linesTo: [{x: 7, y: 3}],
          }, {
            ids: [36],
            category: texts['bht'][language],
            pos: {x: 8, y: 3},
            linesTo: [],
          },
        ]
      },
      APD: {
        bodies: [
          {
            ids: [23, 24],
            category: texts['bl'][language],
            pos: {x: 2, y: 1},
            linesTo: [{x: 3, y: 1}],
          }, {
            ids: [25],
            category: texts['bm'][language],
            pos: {x: 4, y: 1},
            linesTo: [{x: 5, y: 1}],
          }, {
            ids: [26, 27],
            category: texts['bh'][language],
            pos: {x: 6, y: 1},
            linesTo: [],
          }
        ]
      }
    }
  },
  getFillUpState(state) {
    return state.FillUp
  },
  getThrowItemsState(state) {
    return state.ThrowItems
  },
  getArmState(state) {
    return state.Arm
  },
  getTargetData(state) {
    return state.TargetData
  },
  getCountNotOpenMails(state) {
    return state.Mails.notOpenMails
  },
  getUpdateMails(state) {
    return state.Mails.update
  },
  getIndexLanguage(state) {
    if (!state.Lang) {
      let userLang = navigator.language || navigator.userLanguage;
      if (userLang.includes('ru')) {
        state.Lang = 'RU'
      } else {
        state.Lang = 'EN'
      }
    }

    return state.Lang
  },
  getSteam(state) {
    return state.Steam
  },
  getDamageLog(state) {
    return state.DamageLog
  },
  openPrefabricated(state) {
    let baseStatus = state.Lobby.base
    if (!baseStatus.state) return;

    if (baseStatus.state.type === 'corporation') {
      return baseStatus.base_attr.hasOwnProperty('prefabricated') && baseStatus.base_attr.prefabricated
    }

    return baseStatus.state.type === 'prefabricated_plant'
  },
  openWorkbench(state) {
    let baseStatus = state.Lobby.base
    if (!baseStatus.state) return;

    if (baseStatus.state.type === 'corporation') {
      return baseStatus.base_attr.hasOwnProperty('prefabricated') && baseStatus.base_attr.prefabricated
    }

    return baseStatus.state.type === 'base'
  },
  openProcessing(state) {
    let baseStatus = state.Lobby.base
    if (!baseStatus.state) return;

    if (baseStatus.state.type === 'corporation') {
      return baseStatus.base_attr.hasOwnProperty('processing') && baseStatus.base_attr.processing
    }

    return baseStatus.state.type === 'processing_plant'
  },
  openFractionMarket(state) {
    let baseStatus = state.Lobby.base
    let currentPlayer = state.Player
    if (!baseStatus.state || !currentPlayer) return;

    return baseStatus.fraction === currentPlayer.fraction && (baseStatus.state.type === 'replic_bunker' || baseStatus.state.type === 'reverses_terraforming_generator' || baseStatus.state.type === 'explores_science_center')
  },
  openNPCTrade(state) {
    let baseStatus = state.Lobby.base
    if (!baseStatus.state) return;

    return baseStatus.state.type !== 'corporation'
  },
  getSystemErrors(state) {
    return state.systemErrors
  }
};

export default getters;
