z
<template>
  <div id="settings" ref="settings" class="noSelect" @mousedown="toUp">

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'settings'"
                 v-bind:noHeight="true"
                 v-bind:noWidth="true"/>

    <div class="wrapper">
      <div class="option">
        <h3 class="head">{{ texts['sound'][language] }}</h3>

        <table>
          <tr>
            <td class="head_td">{{ texts['music_volume'][language] }}</td>
            <td class="value_td">{{ Math.round(volume * 100) }}</td>
            <td>
              <div class="range">
                <input @input="changeVolume($event)" class="cursor_hover" type="range" min="0" max="1" step="0.01"
                       :value="volume">
              </div>
            </td>
          </tr>
          <tr>
            <td class="head_td">{{ texts['sfx_Volume'][language] }}</td>
            <td class="value_td">{{ Math.round(SFXVolume * 100) }}</td>
            <td>
              <div class="range">
                <input @input="changeSFXVolume($event)" class="cursor_hover" type="range" min="0" max="1" step="0.01"
                       :value="SFXVolume">
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="option">
        <h3 class="head">{{ texts['graphics'][language] }}</h3>

        <table>
          <tr>
            <td class="head_td">{{ texts['traces'][language] }}</td>
            <td class="value_td"></td>
            <td>
              <input type="checkbox" :checked="UnitTrack" class="cursor_hover" @input="changeUnitTrack($event)">
            </td>
          </tr>
          <tr>
            <td class="head_td">Внешнее масштабирование</td>
            <td class="value_td"></td>
            <td>
              <div>
                <select @input="changeZoomInterface($event, '')" :value="interfaceZoomCurrentValue">
                  <option v-for="o in interfaceOptions" :value="o">{{ o }}%</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td class="head_td">Внутреннее масштабирование</td>
            <td class="value_td"></td>
            <td>
              <div>
                <select @input="changeZoomInterface($event, 'In')" :value="interfaceInZoomCurrentValue">
                  <option v-for="o in interfaceOptions" :value="o">{{ o }}%</option>
                </select>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="reload_page" v-if="need_reload_page">
      Рекомендуется перезагрузить страницу.

      <input class="button cursor_hover"
             type="button"
             value="Перезагрузить"
             @click="reload">
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import {resetEquipBarState} from "../../game/interface/equip_bar";
import {drawFrameAndBackground} from "../../game/interface/mini_map_input";
import {resetStatusBar} from "../../game/interface/status_bar";
import {resetGroupBar} from "../../game/interface/group_bar";

export default {
  name: "Settings",
  data() {
    return {
      need_reload_page: false,
      interfaceOptions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150],
    }
  },
  methods: {
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    reload() {
      window.location.reload();
    },
    changeVolume(e) {
      this.$store.dispatch('changeSettings', {
        name: "VolumeMusic",
        count: e.target.value * 100
      });

      this.$store.commit({
        type: 'setVolume',
        volume: e.target.value,
      });
    },
    changeSFXVolume(e) {

      this.$store.dispatch('changeSettings', {
        name: "VolumeSfx",
        count: e.target.value * 100
      });

      this.$store.commit({
        type: 'setSFXVolume',
        SFXVolume: e.target.value,
      });
    },
    changeUnitTrack(e) {
      this.$store.dispatch('changeSettings', {
        name: "UnitTrack",
        count: e.target.checked ? 1 : 0
      });

      this.$store.commit({
        type: 'setUnitTrack',
        UnitTrack: e.target.checked,
      });
    },
    changeZoomInterface(e, type) {
      let val = e.target.value
      if (val < 0) val = 0;
      if (val > 150) val = 150;

      this.$store.dispatch('changeSettings', {
        name: "ZoomInterface" + type,
        count: Number(val),
      });

      this.$store.commit({
        type: 'setZoomInterface' + type,
        zi: Number(val),
      });

      this.need_reload_page = true
      resetEquipBarState();
      drawFrameAndBackground();
      resetStatusBar();
      resetGroupBar()
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Settings')
    },
    volume() {
      return this.$store.getters.getSettings.volume
    },
    SFXVolume() {
      return this.$store.getters.getSettings.SFXVolume
    },
    UnitTrack() {
      return this.$store.getters.getSettings.UnitTrack
    },
    ZoomInterface() {
      return this.$store.getters.getSettings.ZoomInterface
    },
    interfaceZoomCurrentValue() {
      return this.$store.getters.getSettings.ZoomInterface
    },
    interfaceInZoomCurrentValue() {
      return this.$store.getters.getSettings.ZoomInterfaceIn
    },
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
#settings {
  position: absolute;
  top: 30%;
  left: calc(50% - 150px);
  z-index: 11;
  min-height: 57px;
  width: 300px;
  padding: 20px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.wrapper {
  margin: 0 auto 0;
  overflow-y: scroll;
  height: 100%;
  border-radius: 5px;
  background: rgba(137, 150, 156, 0.3);
  pointer-events: auto;
  overflow-x: hidden;
  padding: 0;
  box-shadow: inset 0 0 2px black;
}

.option {
  margin: 4px;
  width: calc(100% - 8px);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.head {
  margin: 0 0 3px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  box-shadow: 0 0 2px rgb(0 0 0);
  padding-left: 10px;
  text-align: left;
  clear: both;
}

.option table {
  color: white;
  letter-spacing: 0;
  word-wrap: break-word;
  font-weight: bold;
  text-shadow: 1px 1px 1px black, 0 0 0 black;
  font-size: 10px;
  text-align: justify;
  line-height: 10px;
}

.range {
  height: 9px;
  width: 100px;
  line-height: 5px;
}

.range input[type=range] {
  -webkit-appearance: none;
  width: calc(100% - 10px);
  height: 8px;
  box-shadow: none;
}

.range input[type=range]:focus {
  outline: none;
}

.range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

.range input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 6px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
}

.range input[type=range]:focus::-webkit-slider-runnable-track {
}

.range input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

.range input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 4px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
}

.head_td {
  width: 154px;
}

.value_td {
  width: 15px;
  text-align: right;
}

.reload_page {
  padding: 4px 5px 4px 5px;
  background: rgb(255, 0, 0, 0.3);
  margin-top: 3px;
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  color: white;
  font-weight: bold;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
}

.button {
  display: block;
  width: 90px;
  margin: 4px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}
</style>
