import {minimap} from "./mini_map";
import {gameStore} from "../store";
import {statusBarWrapper} from "./status_bar";
import {Scene} from "../create";
import {SetDayCycle} from "../day_cycle/day_cycle";

let state = {
  oldString: "",
  sprite: null,
  back: null,
  offsetX: -78,
  offsetY: 18,
  textureWrapper: null,
  scale: 0.3,
  backScale: 1,
  heightText: 13,
  backOffsetX: 4,
  bracketOffsetX: 10,
  textOffset: 3,
}

function SetGameTime(h, m) {
  if (!minimap.backRenderTexture || !gameStore.gameReady || !statusBarWrapper.scene) return;

  if (gameStore.time.h !== h || !gameStore.time.init) {
    gameStore.time.h = h
    SetDayCycle(!gameStore.time.init)
    gameStore.time.init = true
  }

  gameStore.time.m = m

  if (h < 10) {
    h = "0" + h
  }

  if (m < 10) {
    m = "0" + m
  }

  let str = h + ":" + m
  if (str === state.oldString) return;
  state.oldString = str

  if (state.sprite) state.sprite.destroy();
  if (!state.back) {

    state.back = statusBarWrapper.scene.add.renderTexture(
      Scene.cameras.main.width + state.offsetX - state.backOffsetX,
      minimap.size + state.offsetY,
      40,
      15);
    state.back.setOrigin(0)
    let back = statusBarWrapper.scene.add.graphics({
      x: 0, y: 0, add: true,
    });

    back.fillStyle(0x000000, 0.5);
    back.fillRect(0, 0, 50, 20);

    state.back.draw(back);
    back.destroy();
  } else {
    state.back.setPosition(
      Scene.cameras.main.width + state.offsetX - state.backOffsetX,
      minimap.size + state.offsetY,
    )
    state.back.setScale(state.backScale);
  }

  if (!state.textureWrapper) {
    state.textureWrapper = statusBarWrapper.scene.make.image({
      x: Scene.cameras.main.width + state.offsetX - state.bracketOffsetX,
      y: minimap.size + state.offsetY - 1,
      key: "radar",
      frame: "time_bracket",
      add: true
    });
    state.textureWrapper.setOrigin(0)
    state.textureWrapper.setDepth(1002)
  } else {
    state.textureWrapper.setPosition(
      Scene.cameras.main.width + state.offsetX - state.bracketOffsetX,
      minimap.size + state.offsetY - 1,
    )
    state.textureWrapper.setScale(state.scale);
  }

  state.sprite = statusBarWrapper.scene.add.bitmapText(
    Scene.cameras.main.width + state.offsetX + state.textOffset,
    minimap.size + state.offsetY + state.textOffset,
    'bit_text', str, state.heightText);
  state.sprite.setLetterSpacing(1)
  state.sprite.setTint(0xcccccc)
  state.sprite.setOrigin(0)
}

export {SetGameTime, state}
