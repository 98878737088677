import {gameStore} from "../store";
import {UpdateObjectFog} from "../interface/fog_of_war";
import {UpdatePosBars} from "../interface/status_layer";
import {setPositionObject} from "../interface/mini_map";
import {rotatePoint} from "../utils/utils";
import {checkView} from "../update";
import {Scene} from "../create";

function processDrones(d) {
  return function () {
    if (d.gameCache) {
      return;
    }

    let fractionView = function (d) {
      if (d.fraction === 5 || d.owner_id !== 0) return false;
      if (d.fraction === 1 && gameStore.relationBonuses.Replics.fraction_drone_view) return true; // Replics
      if (d.fraction === 2 && gameStore.relationBonuses.Explores.fraction_drone_view) return true; // Explores
      if (d.fraction === 3 && gameStore.relationBonuses.Reverses.fraction_drone_view) return true; // Reverses
    }

    if (checkView(d.owner_id, d.warrior, d.fraction, d.corporation_id) || fractionView(d)) {
      UpdateObjectFog(Scene, d, d.sprite, "drone", d.range_view);
    }

    if (d.max_hp !== 99990) {
      UpdatePosBars(d.sprite, d.max_hp, d.hp, 0, null, Scene, 'drone', d.id, 'hp', 1);
      if (d.life_percent <= 100) {
        UpdatePosBars(d.sprite, 100, d.life_percent, 6, 0x00ffd6, Scene, 'drone', d.id, 'energy', 20)
      }
    }

    setPositionObject('fly', d.sprite, 150, 150, d.owner_id, d.warrior, d.fraction, d.corporation_id, true)
    if (d.sprite.engages) processDroneEngages(d)
  }
}

function processDroneEngages(d) {
  for (let k in d.sprite.engages) {

    let e = d.sprite.engages[k];
    let newRightPos = rotatePoint(e.real_x_attach, e.real_y_attach, d.sprite.angle)
    e.emitter.setPosition(
      d.sprite.x + (newRightPos.x * d.sprite.scale),
      d.sprite.y + (newRightPos.y * d.sprite.scale),
    )

    if (d.sprite.engages[k].typeEngage === "2" || d.sprite.engages[k].typeEngage === "3") {
      let newAngle = {min: 180 + d.sprite.angle + e.offsetRotate, max: 180 + d.sprite.angle + e.offsetRotate}
      if (!(e.emitter.ops.angle.start === newAngle.min && e.emitter.ops.angle.end === newAngle.max)) {
        e.emitter.ops.angle.start = newAngle.min;
        e.emitter.ops.angle.end = newAngle.max;
      }

      let speed = 5
      if (d.sprite.engages[k].typeEngage === "3") {
        speed = 3
      }

      let offsetSpeed = 1
      if (e.offsetRotate > 0 && d.angularVelocity < 0) offsetSpeed = 0.5;
      if (e.offsetRotate < 0 && d.angularVelocity > 0) offsetSpeed = 0.5;

      e.emitter.ops.speedX.end = (d.speed * speed) * offsetSpeed;
      e.emitter.ops.speedY.end = (d.speed * speed) * offsetSpeed;

      e.emitter.ops.scaleX.start = ((((d.speed * 0.02) + 0.05) * e.offsetScale)) * e.kScale;
      e.emitter.ops.scaleX.end = 0;
    }

    if (e.depth !== (d.sprite.depth - 2) + e.offsetHeight) e.emitter.setDepth(d.sprite.depth - 2 + e.offsetHeight);
  }
}

export {processDrones}
