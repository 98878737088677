import {Scene} from "../create";

function MoveSprite(sprite, x, y, ms, size, forceAnimate, smooth, updateCallback) {

  if (size && sprite.scale !== size) {
    sprite.setScale(size)
  }

  if (!updateCallback && sprite.x === x && sprite.y === y) {
    return
  }

  if (forceAnimate || sprite.visible) {
    movePlugin(sprite, x, y, ms, updateCallback)
    //moveTween(sprite, x, y, ms, updateCallback)
  } else {
    sprite.setPosition(x, y);
    if (updateCallback) updateCallback()
  }
}

function moveTween(sprite, x, y, ms, updateCallback) {
  sprite.moveTween = Scene.tweens.add({
    targets: sprite,
    duration: ms,
    x: x,
    y: y,
    onUpdate: updateCallback ? updateCallback : null
  });
}

function movePlugin(sprite, x, y, ms, updateCallback) {
  if (!sprite.moveToPlugin) {
    sprite.moveToPlugin = true
    Scene.plugins.get('rexeasemoveplugin').injectMethods(sprite);
  }

  sprite.moveTo(ms, x, y, undefined, undefined, updateCallback)
}

export {MoveSprite, movePlugin}
