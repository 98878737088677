import {rotatePoint} from "../utils/utils";
import {Scene} from "../create";
import {SetDayCycleUnit} from "../day_cycle/day_cycle";
import {addIgnoreObject, setPositionObject, SetPositionRadarRing, setUserUnit} from "../interface/mini_map";
import {getBarScale, PosUpdateUnitHP} from "../interface/status_layer";
import {gameStore} from "../store";
import {UpdateObjectFog, UpdateUnitFog} from "../interface/fog_of_war";
import {checkView} from "../update";
import {colorName} from "./unit";
import {ClientWatch} from "../radar/client_watcher";

function processUnit(unit, force) {

  unit.lastPosition = {x: unit.sprite.x, y: unit.sprite.y, a: unit.sprite.angle}

  return function () {

    if (unit.gameCache) {
      return;
    }

    if (!force && (unit.lastPosition.x === unit.sprite.x && unit.lastPosition.y === unit.sprite.y && unit.lastPosition.a === unit.sprite.angle)) {
      return
    }

    if (unit.id === gameStore.user_squad_id && (unit.lastPosition.x !== unit.sprite.x || unit.lastPosition.y !== unit.sprite.y)) {
      ClientWatch()
    }

    unit.lastPosition = {x: unit.sprite.x, y: unit.sprite.y, a: unit.sprite.angle}

    processUnitWeapon(unit)
    processUnitEquip(unit)
    processUnitBottom(unit)
    processLights(unit)
    unitProcessLabels(unit)

    setPositionObject('ground', unit.sprite, unit.sprite.displayWidth * 4, unit.sprite.displayHeight * 4,
      unit.owner_id, unit.warrior, unit.fraction, unit.corporation_id, true)

    if (unit.sprite.damageText) {
      if (unit.sprite.damageText.sprite) {
        unit.sprite.damageText.sprite.setPosition(unit.sprite.x + unit.sprite.damageText.x_offset, unit.sprite.y + unit.sprite.damageText.y_offset)
      }

      if (unit.sprite.damageText.damageTypeSprite) {
        unit.sprite.damageText.damageTypeSprite.setPosition(unit.sprite.x + unit.sprite.damageText.x_offset, unit.sprite.y + unit.sprite.damageText.y_offset - 13)
      }
    }

    if (unit.sprite.userMessage) {
      if (unit.sprite.userMessage.text) unit.sprite.userMessage.text.setPosition(unit.sprite.x, unit.sprite.y - 45);
    }

    if (unit.body.max_hp > 50) {
      PosUpdateUnitHP(unit)
    }

    if (unit.id === gameStore.user_squad_id) {
      UpdateUnitFog(Scene, unit);
      SetPositionRadarRing(unit);
      setUserUnit(unit)
    }

    if (unit.id !== gameStore.user_squad_id && checkView(unit.owner_id, unit.warrior, unit.fraction, unit.corporation_id)) {
      UpdateObjectFog(Scene, unit, unit.sprite, "unit", unit.body_state.range_view);
    } else if (gameStore.FogOfWar.back && gameStore.FogOfWar.objBrush.unit[unit.id]) {
      gameStore.FogOfWar.objBrush.unit[unit.id].destroy();
      delete gameStore.FogOfWar.objBrush.unit[unit.id];
    }

    if (unit.sprite.passUnitSelectSprite) {
      unit.sprite.passUnitSelectSprite.setPosition(unit.sprite.x, unit.sprite.y)
    }

    if (unit.sprite.noPassUnitSelectSprite) {
      unit.sprite.noPassUnitSelectSprite.setPosition(unit.sprite.x, unit.sprite.y)
    }

    unit.sprite.explode_mask.graphics.x = unit.sprite.x;
    unit.sprite.explode_mask.graphics.y = unit.sprite.y;
  }
}

function unitProcessLabels(unit) {

  if (unit.sprite.userName) {
    if (unit.sprite.userName.login) {
      unit.sprite.userName.login.setPosition(unit.sprite.x, unit.sprite.y - (20 + (7 * getBarScale())));
      unit.sprite.userName.login.setScale(getBarScale());
    }

    if (unit.sprite.userName.pointer) {
      unit.sprite.userName.pointer.setPosition(unit.sprite.x, unit.sprite.y - 17);
      unit.sprite.userName.pointer.setScale(0.2 * getBarScale());
    }

    if (unit.sprite.userName.attitude) {
      if (unit.sprite.userName.login && unit.sprite.userName.login.active) {
        unit.sprite.userName.attitude.setPosition(unit.sprite.x - (unit.sprite.userName.login.width / 2) - (9 * getBarScale()), unit.sprite.y - (20 + (7 * getBarScale())));
      } else {
        unit.sprite.userName.attitude.setPosition(unit.sprite.x, unit.sprite.y - (20 + (7 * getBarScale())));
      }

      unit.sprite.userName.attitude.setDisplaySize(12 * getBarScale(), 12 * getBarScale());
    }
  }

  processColorName(unit)
}

function processColorName(unit) {
  if (unit.body.max_hp > 50) {
    colorName(unit)
  }
}

function processUnitWeapon(unit) {
  for (let i in unit.weapons) {

    let newPos = rotatePoint(unit.weapons[i].weapon.xAttach, unit.weapons[i].weapon.yAttach, unit.sprite.angle)

    unit.weapons[i].weapon.setPosition(unit.sprite.x + (newPos.x * unit.sprite.scale), unit.sprite.y + (newPos.y * unit.sprite.scale));
    if (unit.weapons[i].weapon.scale !== unit.sprite.scale) unit.weapons[i].weapon.setScale(unit.sprite.scale)
    if (unit.weapons[i].weapon.depth !== unit.sprite.depth + 2) unit.weapons[i].weapon.setDepth(unit.sprite.depth + 2)
  }
}

function processUnitEquip(unit) {
  for (let i in unit.equips) {
    let newPos = rotatePoint(unit.equips[i].xAttach, unit.equips[i].yAttach, unit.sprite.angle)

    unit.equips[i].sprite.setPosition(unit.sprite.x + (newPos.x * unit.sprite.scale), unit.sprite.y + (newPos.y * unit.sprite.scale));
    if (unit.equips[i].sprite.scale !== unit.sprite.scale) unit.equips[i].sprite.setScale(unit.sprite.scale)
    if (unit.equips[i].sprite.depth !== unit.sprite.depth + 1) unit.equips[i].sprite.setDepth(unit.sprite.depth + 1)
  }
}

function processUnitBottom(unit) {
  let shadowXOffset = Scene.shadowXOffset / 3;
  let shadowYOffset = Scene.shadowXOffset / 3;
  if (unit.body.fraction === "Fauna") {
    shadowXOffset = 1;
    shadowYOffset = 1;
  }

  if (unit.sprite.damage) {
    unit.sprite.damage.setPosition(unit.sprite.x, unit.sprite.y);
    unit.sprite.damage.setAngle(unit.sprite.angle);
  }

  if (unit.body.chassis_type === "wheels") {
    unit.sprite.bodyBottom.setPosition(unit.sprite.x, unit.sprite.y);
    if (unit.sprite.bodyBottom.angle !== unit.sprite.angle) unit.sprite.bodyBottom.setAngle(unit.sprite.angle)
    if (unit.sprite.bodyBottom.scale !== unit.sprite.scale) unit.sprite.bodyBottom.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottom.depth !== unit.sprite.depth - 1) unit.sprite.bodyBottom.setDepth(unit.sprite.depth - 1)

    let newRightPos = rotatePoint(unit.body.wheel_anchors["right_front_wheel_attach"].real_x_attach, unit.body.wheel_anchors["right_front_wheel_attach"].real_y_attach, unit.sprite.angle)

    unit.sprite.bodyBottomRight.setPosition(unit.sprite.x + (newRightPos.x * unit.sprite.scale), unit.sprite.y + (newRightPos.y * unit.sprite.scale))

    let newLeftPos = rotatePoint(unit.body.wheel_anchors["left_front_wheel_attach"].real_x_attach, unit.body.wheel_anchors["left_front_wheel_attach"].real_y_attach, unit.sprite.angle)

    unit.sprite.bodyBottomLeft.setPosition(unit.sprite.x + (newLeftPos.x * unit.sprite.scale), unit.sprite.y + (newLeftPos.y * unit.sprite.scale))

    unit.sprite.bodyBottomRightShadow.setPosition(unit.sprite.bodyBottomRight.x + shadowXOffset, unit.sprite.bodyBottomRight.y + shadowYOffset);
    if (unit.sprite.bodyBottomRightShadow.angle !== unit.sprite.angle) unit.sprite.bodyBottomRightShadow.setAngle(unit.sprite.bodyBottomRight.angle)
    if (unit.sprite.bodyBottomRightShadow.scale !== unit.sprite.scale) unit.sprite.bodyBottomRightShadow.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottomRightShadow.depth !== unit.sprite.depth - 2) unit.sprite.bodyBottomRightShadow.setDepth(unit.sprite.depth - 2)

    unit.sprite.bodyBottomLeftShadow.setPosition(unit.sprite.bodyBottomLeft.x + shadowXOffset, unit.sprite.bodyBottomLeft.y + shadowYOffset);
    if (unit.sprite.bodyBottomLeftShadow.angle !== unit.sprite.angle) unit.sprite.bodyBottomLeftShadow.setAngle(unit.sprite.bodyBottomLeft.angle)
    if (unit.sprite.bodyBottomLeftShadow.scale !== unit.sprite.scale) unit.sprite.bodyBottomLeftShadow.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottomLeftShadow.depth !== unit.sprite.depth - 2) unit.sprite.bodyBottomLeftShadow.setDepth(unit.sprite.depth - 2)
  }

  if (unit.body.chassis_type === "caterpillars") {
    unit.sprite.bodyBottomLeft.setPosition(unit.sprite.x, unit.sprite.y);
    if (unit.sprite.bodyBottomLeft.angle !== unit.sprite.angle) unit.sprite.bodyBottomLeft.setAngle(unit.sprite.angle)

    unit.sprite.bodyBottomRight.setPosition(unit.sprite.x, unit.sprite.y);
    if (unit.sprite.bodyBottomRight.angle !== unit.sprite.angle) unit.sprite.bodyBottomRight.setAngle(unit.sprite.angle)
  }

  if (unit.body.chassis_type === "caterpillars" || unit.body.chassis_type === "wheels") {
    if (unit.sprite.bodyBottomLeft.scale !== unit.sprite.scale) unit.sprite.bodyBottomLeft.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottomLeft.depth !== unit.sprite.depth - 1) unit.sprite.bodyBottomLeft.setDepth(unit.sprite.depth - 1)

    if (unit.sprite.bodyBottomRight.scale !== unit.sprite.scale) unit.sprite.bodyBottomRight.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottomRight.depth !== unit.sprite.depth - 1) unit.sprite.bodyBottomRight.setDepth(unit.sprite.depth - 1)
  }

  if (unit.body.chassis_type === "antigravity") {
    unit.sprite.antigravityPlace.setPosition(unit.sprite.x, unit.sprite.y);
    if (unit.sprite.antigravityPlace.angle !== unit.sprite.angle) unit.sprite.antigravityPlace.setAngle(unit.sprite.angle)
    if (unit.sprite.antigravityPlace.scale !== unit.sprite.scale) unit.sprite.antigravityPlace.setScale(unit.sprite.scale)
    if (unit.sprite.antigravityPlace.depth !== unit.sprite.depth - 1) unit.sprite.antigravityPlace.setDepth(unit.sprite.depth - 1)

    // BACK ENGAGE
    let newLeftPos = rotatePoint(unit.body.wheel_anchors["back_engine"].real_x_attach, unit.body.wheel_anchors["back_engine"].real_y_attach, unit.sprite.angle)
    unit.sprite.backEngine.setPosition(unit.sprite.x + (newLeftPos.x * unit.sprite.scale), unit.sprite.y + (newLeftPos.y * unit.sprite.scale))

    if (unit.sprite.backEngine.angle !== unit.sprite.angle) unit.sprite.backEngine.setAngle(unit.sprite.angle)
    if (unit.sprite.backEngine.scale !== unit.sprite.scale) unit.sprite.backEngine.setScale(unit.sprite.scale)
    if (unit.sprite.backEngine.depth !== unit.sprite.depth - 1) unit.sprite.backEngine.setDepth(unit.sprite.depth - 1)

    // Right ENGAGE
    newLeftPos = rotatePoint(unit.body.wheel_anchors["right_engine"].real_x_attach, unit.body.wheel_anchors["right_engine"].real_y_attach, unit.sprite.angle)
    unit.sprite.rightEngine.setPosition(unit.sprite.x + (newLeftPos.x * unit.sprite.scale), unit.sprite.y + (newLeftPos.y * unit.sprite.scale))

    if (unit.sprite.rightEngine.angle !== unit.sprite.angle) unit.sprite.rightEngine.setAngle(unit.sprite.angle)
    if (unit.sprite.rightEngine.scale !== unit.sprite.scale) unit.sprite.rightEngine.setScale(unit.sprite.scale)
    if (unit.sprite.rightEngine.depth !== unit.sprite.depth - 1) unit.sprite.rightEngine.setDepth(unit.sprite.depth - 1)

    // Left ENGAGE
    newLeftPos = rotatePoint(unit.body.wheel_anchors["left_engine"].real_x_attach, unit.body.wheel_anchors["left_engine"].real_y_attach, unit.sprite.angle)
    unit.sprite.leftEngine.setPosition(unit.sprite.x + (newLeftPos.x * unit.sprite.scale), unit.sprite.y + (newLeftPos.y * unit.sprite.scale))

    if (unit.sprite.leftEngine.angle !== unit.sprite.angle) unit.sprite.leftEngine.setAngle(unit.sprite.angle)
    if (unit.sprite.leftEngine.scale !== unit.sprite.scale) unit.sprite.leftEngine.setScale(unit.sprite.scale)
    if (unit.sprite.leftEngine.depth !== unit.sprite.depth - 1) unit.sprite.leftEngine.setDepth(unit.sprite.depth - 1)
  }

  unit.sprite.shadow.setPosition(unit.sprite.x + shadowXOffset, unit.sprite.y + shadowYOffset);
  if (unit.sprite.shadow.angle !== unit.sprite.angle) unit.sprite.shadow.setAngle(unit.sprite.angle)
  if (unit.sprite.shadow.scale !== unit.sprite.scale) unit.sprite.shadow.setScale(unit.sprite.scale)
  if (unit.sprite.shadow.depth !== unit.sprite.depth - 2) unit.sprite.shadow.setDepth(unit.sprite.depth - 2)
}

function processLights(unit) {
  if (unit.light) {
    // если нет создаем, если есть обновляем позиции
    if (!unit.lights) {
      unit.lights = {}

      for (let i in unit.body.wheel_anchors) {
        if (i.includes('light')) {
          let newPos = rotatePoint(unit.body.wheel_anchors[i].real_x_attach, unit.body.wheel_anchors[i].real_y_attach, unit.sprite.angle);

          if (i.includes('out')) {
            let r = 50
            if (i === 'light_out_2') r = 65;
            if (i === 'light_out_3') r = 75;
            if (i === 'light_out_4') r = 85;
            if (i === 'light_out_5') r = 90;

            unit.lights[i] = Scene.lights.addLight(
              unit.sprite.x + (newPos.x * unit.sprite.scale), unit.sprite.y + (newPos.y * unit.sprite.scale),
              r, 0xffffff, 0.5);
            // unit.lights[i] = Scene.add.pointlight(
            //   (newPos.x * unit.sprite.scale),
            //   (newPos.y * unit.sprite.scale),
            //   0xffff99, r, 0.1, 0.2);
            // unit.lights[i].setBlendMode(Phaser.BlendModes.MULTIPLY)

            addIgnoreObject(unit.lights[i])
          } else {
            unit.lights[i] = Scene.add.pointlight(
              unit.sprite.x + (newPos.x * unit.sprite.scale),
              unit.sprite.y + (newPos.y * unit.sprite.scale),
              0xffffff, 3, 0.75, 0.04);

            unit.lights[i].setDepth(unit.sprite.depth + 4)
            addIgnoreObject(unit.lights[i])
          }
        }
      }

      SetDayCycleUnit(unit)
    }

    for (let i in unit.lights) {
      let newPos = rotatePoint(unit.body.wheel_anchors[i].real_x_attach, unit.body.wheel_anchors[i].real_y_attach, unit.sprite.angle);
      unit.lights[i].setPosition(unit.sprite.x + (newPos.x * unit.sprite.scale), unit.sprite.y + (newPos.y * unit.sprite.scale));
    }
  }
}

export {processUnit, processUnitWeapon, processUnitEquip, processUnitBottom, unitProcessLabels, processColorName}
