import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import store from './store/store'
import router from './router/router'

// jquery init
require('jquery-ui/themes/base/all.css')
require('jquery-ui/ui/widgets/sortable')
require('jquery-ui/ui/widgets/resizable');
require('jquery-ui/ui/widgets/droppable');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/selectable');

require('../fix_jquery/fix_jquery');

// axios init
let AxiosClient = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

// инструмент для http запросов
Vue.use({
  install(Vue) {
    Vue.prototype.$api = AxiosClient
  }
});

let VueEx = new Vue({
  el: '#app',
  router: router,
  store,
  render: h => h(App),
});

export {VueEx, AxiosClient}
