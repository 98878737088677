import {GetGlobalPos} from "../map/gep_global_pos";
import {Scene} from "../create";
import {gameStore} from "../store";
import {addIgnoreObject} from "../interface/mini_map";

let smokers = {}
let smokersStore = [];

function SmokeScreen(x, y, r, startScale = 0.75) {
  let kScale = 4

  let key = x + ":" + y
  if (smokers[key]) return;
  smokers[key] = true;

  let pos = GetGlobalPos(x, y, gameStore.map.id);
  let shape1 = new Phaser.Geom.Circle(0, 0, r);

  let emitter
  if (smokersStore.length === 0) {
    emitter = Scene.add.particles(0, 0, 'flares', {
      frame: 'smoke_puff_mini',
      speed: getRandomArbitrary(0, 40),
      lifespan: 10000,
      scale: {start: startScale * kScale, end: 2 * kScale, ease: "Quart.easeOut"},
      alpha: {start: 1, end: 0, ease: "Quad.easeInOut"},
      blendMode: 'SCREEN',
      moveToX: {min: getRandomArbitrary(pos.x - 100, pos.x + 100), max: getRandomArbitrary(pos.x - 100, pos.x + 100)},
      moveToY: {min: getRandomArbitrary(pos.y - 100, pos.y + 100), max: getRandomArbitrary(pos.y - 100, pos.y + 100)},
      emitZone: {type: 'random', source: shape1}
    });
    emitter.setDepth(400);
    addIgnoreObject(emitter);
  } else {
    emitter = smokersStore.shift();

    emitter.ops.scaleX.start = startScale * kScale;

    emitter.ops.moveToX.start = getRandomArbitrary(pos.x - 100, pos.x + 100);
    emitter.ops.moveToX.end = getRandomArbitrary(pos.x - 100, pos.x + 100);

    emitter.ops.moveToY.start = getRandomArbitrary(pos.y - 100, pos.y + 100);
    emitter.ops.moveToY.end = getRandomArbitrary(pos.y - 100, pos.y + 100);
  }

  emitter.explode(10, pos.x, pos.y);
  Scene.time.addEvent({
    delay: 10000,
    callback: function () {
      emitter.stop()
      smokersStore.push(emitter);
    },
    loop: false
  });

  let circle = Scene.add.circle(pos.x, pos.y, r);
  circle.setAlpha(0.3)
  circle.setStrokeStyle(2, 0xff0000);

  Scene.time.addEvent({
    delay: 3000,
    callback: function () {
      circle.destroy();
      delete smokers[key]
    },
    loop: false
  });
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export {SmokeScreen}
