import {gameStore} from "../store";
import {NoCompleteStructure} from "../map/structures/no_complete_structure";
import {Scene} from "../create";
import {UpdateShield} from "../shield/shield";
import {ClearBars, CreateMapBar, createObjectBars, CreateUnitHP, PosUpdateUnitHP} from "../interface/status_layer";
import {MoveSprite} from "../utils/move_sprite";
import {intFromBytes} from "../../utils";
import {UpdateReservoirStatus} from "../interface/reservoir_count";
import {GetMapLvl} from "../map/height_offset";
import {Crack} from "../reservoir/reservoir";
import {pollenMaxCount, UpdatePollen} from "../pollen/pollen";
import {processObject} from "../map/move_object";

function UpdateObject(type, id, updateMsg) {
  if (type === "box") {
    UpdateBox(id, updateMsg);
  }

  if (type === "unit") {
    UpdateUnit(id, updateMsg);
  }

  if (type === "object" || type === "dynamic_objects") {
    UpdateDynamicObject(id, updateMsg);
    createObjectBars(id);
    processObject(gameStore.objects[id])()
  }

  if (type === "shield") {
    UpdateShield(id, updateMsg)
  }

  if (type === "drone") {
    UpdateDrone(id, updateMsg)
  }

  if (type === "reservoir") {
    let r = gameStore.reservoirs[id];
    if (r) {

      r.count = intFromBytes(updateMsg.slice(0, 4))
      r.crack = intFromBytes(updateMsg.slice(4, 5)) === 1
      r.crackX = intFromBytes(updateMsg.slice(5, 6)) - 128
      r.crackY = intFromBytes(updateMsg.slice(6, 7)) - 128

      Crack(r)
      UpdateReservoirStatus('reservoir_' + id, r.count, 60);
    }
  }

  if (type === "pollen_cloud") {
    UpdatePollen(id, updateMsg)
  }

  if (type === "item") {
    let i = gameStore.mapItems[id];
    if (i) {
      i.quantity = intFromBytes(updateMsg.slice(0, 4))
      UpdateReservoirStatus('item_' + id, i.quantity);
    }
  }
}

function UpdateBox(id, update) {
  let box = gameStore.boxes[id];
  if (box) {
    box.hp = intFromBytes(update.slice(0, 4));
    CreateMapBar(box.sprite, box.max_hp, box.hp, 4, null, Scene, 'box', box.id, 'hp', box.max_hp / 5);
  }
}

function UpdateUnit(id, update) {
  let unit = gameStore.units[id];
  if (unit) {

    if (unit.body.max_hp <= 50) {
      return
    }

    unit.hp = intFromBytes(update.slice(0, 4));
    unit.body.range_view = intFromBytes(update.slice(4, 8));
    unit.body.range_radar = intFromBytes(update.slice(8, 12));
    unit.ghost = intFromBytes(update.slice(12, 13)) === 1;
    unit.light = intFromBytes(update.slice(13, 14)) === 1;
    unit.shield_hp = intFromBytes(update.slice(14, 18));

    for (let i in unit.lights) {
      unit.lights[i].visible = unit.light && unit.sprite.visible
    }

    CreateUnitHP(unit)
    PosUpdateUnitHP(unit)
  }
}

function UpdateDrone(id, update) {
  let d = gameStore.drones[id];
  if (d) {

    if (d.max_hp === 99990) {
      return
    }

    d.hp = intFromBytes(update.slice(0, 4));
    d.life_percent = intFromBytes(update.slice(4, 5));
    CreateMapBar(d.sprite, d.max_hp, d.hp, 0, null, Scene, 'drone', d.id, 'hp', 1);
    if (d.life_percent <= 100) {
      CreateMapBar(d.sprite, 100, d.life_percent, 6, 0x00ffd6, Scene, 'drone', d.id, 'energy', 20);
    } else {
      ClearBars('drone', d.id, 'energy');
    }
  }
}

function UpdateDynamicObject(id, update) {

  // obj.dynamic_object.hp
  // console.log(store.getters.getOpenObjects)

  let obj = gameStore.objects[id];
  if (obj && obj.objectSprite) {
    let oldScale = obj.scale;

    obj.work = intFromBytes(update.slice(0, 1)) === 1;
    obj.scale = intFromBytes(update.slice(1, 2));
    obj.hp = intFromBytes(update.slice(4, 8));
    obj.max_hp = intFromBytes(update.slice(8, 12));
    obj.current_energy = intFromBytes(update.slice(12, 16));
    obj.max_energy = intFromBytes(update.slice(16, 20));
    obj.owner_id = intFromBytes(update.slice(20, 24));

    let xs = intFromBytes(update.slice(2, 3));
    let ys = intFromBytes(update.slice(3, 4));
    let gt = intFromBytes(update.slice(24, 28));

    obj.height = intFromBytes(update.slice(28, 29));
    obj.range_view = intFromBytes(update.slice(29, 33));
    obj.complete = intFromBytes(update.slice(33, 34));

    let lengthGeoData = intFromBytes(update.slice(34, 35));
    if (lengthGeoData > 0) {

      let stopByte = 35;
      let geoData = [];

      for (; stopByte < update.length;) {

        geoData.push({
          x: intFromBytes(update.slice(stopByte, stopByte + 4)),
          y: intFromBytes(update.slice(stopByte + 4, stopByte + 8)),
          radius: intFromBytes(update.slice(stopByte + 8, stopByte + 12)),
        })

        stopByte += 12
      }

      obj.geo_data = geoData;
      gameStore.updateGeoData = true;
    }

    NoCompleteStructure(obj);
    if (oldScale !== obj.scale) {

      obj.objectSprite.setDepth(obj.height + GetMapLvl(obj.x, obj.y, gameStore.map.id));
      MoveSprite(obj.objectSprite, obj.objectSprite.x, obj.objectSprite.y, gt, null, false, true);

      Scene.tweens.add({
        targets: obj.objectSprite,
        duration: gt,
        scale: obj.scale / 100,
      });

      if (obj.objectSprite.shadow) {
        obj.objectSprite.shadow.setDepth(obj.height - 2 + GetMapLvl(obj.x, obj.y, gameStore.map.id));
        MoveSprite(obj.objectSprite.shadow, obj.objectSprite.x + xs, obj.objectSprite.y + ys, gt, null, false, true);

        Scene.tweens.add({
          targets: obj.objectSprite.shadow,
          duration: gt,
          scale: obj.scale / 100,
        });
      }
    }
  }
}

export {UpdateObject}
