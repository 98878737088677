import {gameStore} from "../store";
import {Scene} from "../create";
import store from '../../store/store'
import {CreateMapBar} from "../interface/status_layer";
import {GetGlobalPos} from "../map/gep_global_pos";
import {intFromBytes} from "../../utils";
import {GetMapLvl} from "../map/height_offset";
import {SetDayCycleBox} from "../day_cycle/day_cycle";
import {UpdateWatch} from "../radar/client_watcher";

function parseData(data) {
  let mapBox = {
    id: intFromBytes(data.slice(0, 4)),
    x: intFromBytes(data.slice(4, 8)),
    y: intFromBytes(data.slice(8, 12)),
    underground: intFromBytes(data.slice(12, 13)) === 1,
    rotate: intFromBytes(data.slice(13, 17)),
    max_hp: intFromBytes(data.slice(17, 21)),
    hp: intFromBytes(data.slice(21, 25)),
  }

  let textureLength = intFromBytes(data.slice(25, 26))
  mapBox.texture = String.fromCharCode.apply(String, data.subarray(26, 26 + textureLength))
  return mapBox
}

function CreateBox(data) {
  if (!gameStore.gameReady) return;
  let postFxPlugin = Scene.plugins.get('rexoutlinepipelineplugin');

  let mapBox = parseData(data)
  let oldBox = gameStore.boxes[mapBox.id];
  if (oldBox) return;

  let depth = 25 + GetMapLvl(mapBox.x, mapBox.y, gameStore.map.id)
  let pos = GetGlobalPos(mapBox.x, mapBox.y, gameStore.map.id);
  mapBox.x = pos.x;
  mapBox.y = pos.y;

  let boxShadow;
  if (!mapBox.underground) {

    boxShadow = Scene.make.sprite({
      x: mapBox.x + Scene.shadowXOffset / 5,
      y: mapBox.y + Scene.shadowYOffset / 5,
      key: "radar",
      frame: mapBox.texture,
      add: true
    });

    boxShadow.setOrigin(0.5);
    boxShadow.setScale(0.15);
    boxShadow.setAngle(mapBox.rotate);
    boxShadow.setAlpha(0.4);
    boxShadow.setTint(0x000000);
    mapBox.shadow = boxShadow;
    boxShadow.setDepth(depth - 1);
  }

  let box = Scene.make.image({
    x: mapBox.x,
    y: mapBox.y,
    key: mapBox.texture,
    add: true
  });

  box.setOrigin(0.5);
  box.setScale(0.15);
  box.setAngle(mapBox.rotate);
  box.setDepth(depth);
  mapBox.sprite = box;

  if (boxShadow) {
    box.shadow = boxShadow;
  }

  box.setInteractive();

  box.on('pointerover', function (event) {
    if (gameStore.equipPanel.selectApplicable === 'empty')
      document.body.classList.add("cursor_box");

    postFxPlugin.add(box, {
      thickness: 1,
      outlineColor: 0xffffff
    });
  });

  box.on('pointerout', function (event) {
    if (gameStore.equipPanel.selectApplicable === 'empty')
      document.body.classList.remove("cursor_box");

    postFxPlugin.remove(box);
  });

  box.on('pointerdown', function (pointer, gameObject) {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "OpenBox",
        id: mapBox.id,
      }
    }));
  });

  gameStore.boxes[mapBox.id] = mapBox;
  UpdateWatch(mapBox, mapBox.sprite, 4, 1)

  SetDayCycleBox(gameStore.boxes[mapBox.id])
  CreateMapBar(mapBox.sprite, mapBox.max_hp, mapBox.hp, 4, null, Scene, 'box', mapBox.id, 'hp', mapBox.max_hp / 5);
}

export {CreateBox}
