import {statusBarWrapper} from "./status_bar";
import store from "../../store/store";
import {gameStore} from "../store";
import {FocusMS} from "./focus_unit";
import {Scene} from "../create";
import {minimap, updateMiniMap} from "./mini_map";
import {createObjectBars, getBar, getBarScale, PosUpdateUnitHP} from "./status_layer";
import {reservoirStatus} from "./reservoir_count";
import {selector} from "./target_info";
import {state} from "./game_time";
import {unitProcessLabels} from "../unit/move_process";
import {ClientWatch} from "../radar/client_watcher";
import {UpdateShieldPos} from "../shield/shield";

function playSound(sound, k) {
  if (sound === "button_press.mp3") k = 0.2
  if (sound === "select_sound.mp3") k = 0.1

  store.dispatch('playSound', {
    sound: sound,
    k: k,
  });
}

function drawFrameAndBackground() {
  if (!minimap.backRenderTexture || !statusBarWrapper.scene) return;

  let percent = 0
  if (store) {
    percent = store.getters.getSettings.ZoomInterfaceIn
  }

  function getPercentVal(min, max) {
    return min + (percent / 100 * (max - min))
  }

  minimap.size = getPercentVal(200, 300)
  minimap.backRenderTexture.setSize(minimap.size, minimap.size)
  minimap.backRenderTexture.setPosition(Scene.cameras.main.width - minimap.size - getPercentVal(38, 38), getPercentVal(18, 15))

  let x = minimap.backRenderTexture.x
  let y = minimap.backRenderTexture.y

  if (minimap.frame.frame) minimap.frame.frame.destroy();
  minimap.frame.frame = statusBarWrapper.scene.make.image({
    x: Scene.cameras.main.width - 224,
    y: -36,
    key: "radar",
    frame: 'minimap_frame',
    add: true
  });
  minimap.frame.frame.setDisplaySize(250, 250)
  minimap.frame.frame.setOrigin(0)
  minimap.frame.frame.setDepth(10)

  if (minimap.frame.backGround) minimap.frame.backGround.destroy();
  minimap.frame.backGround = statusBarWrapper.scene.make.image({
    x: x - getPercentVal(7, 9),
    y: y - getPercentVal(7, 7),
    key: "radar",
    frame: 'minimap_back',
    add: true
  });
  minimap.frame.backGround.setDisplaySize(minimap.size + getPercentVal(13, 18), minimap.size + getPercentVal(13, 18))
  minimap.frame.backGround.setOrigin(0)
  minimap.frame.backGround.setDepth(9)

  let initButton = function (b, callbackdown, callbackup) {
    b.setDisplaySize(getPercentVal(24, 36), getPercentVal(24, 36))
    b.setOrigin(0)
    b.setDepth(10)
    b.setAlpha(0.7)
    b.setInteractive();

    b.on('pointerover', function () {
      gameStore.HoldAttackMouse = true;
      b.setAlpha(1)
    })
    b.on('pointerout', function () {
      b.setAlpha(0.7)
    })
    b.on('pointerdown', function () {
      if (callbackdown) callbackdown()
    })
    b.on('pointerup', function () {
      if (callbackup) callbackup()
    })
  }

  if (minimap.frame.buttons.zoomp) minimap.frame.buttons.zoomp.destroy();
  minimap.frame.buttons.zoomp = statusBarWrapper.scene.make.image({
    x: x - getPercentVal(24, 36),
    y: y + getPercentVal(4, 6),
    key: "radar",
    frame: 'but_zoom_plus',
    add: true
  });
  initButton(minimap.frame.buttons.zoomp, function () {
    mouseZoomPress(+0.15)
  }, function () {
    minimap.frame.buttons.zoomChange = false;
  })

  if (minimap.frame.buttons.zoomm) minimap.frame.buttons.zoomm.destroy();
  minimap.frame.buttons.zoomm = statusBarWrapper.scene.make.image({
    x: x - getPercentVal(24, 36),
    y: y + getPercentVal(28, 42),
    key: "radar",
    frame: 'but_zoom_minus',
    add: true
  });
  minimap.frame.buttons.zoomm.setDisplaySize(24, 24)
  initButton(minimap.frame.buttons.zoomm, function () {
    mouseZoomPress(-0.15)
  }, function () {
    minimap.frame.buttons.zoomChange = false;
  })

  // if (minimap.frame.buttons.on) minimap.frame.buttons.on.destroy();
  // minimap.frame.buttons.on = statusBarWrapper.scene.make.image({
  //   x: x - getPercentVal(24, 36),
  //   y: y + getPercentVal(52, 78),
  //   key: 'but_on',
  //   add: true
  // });
  // initButton(minimap.frame.buttons.on, function () {
  //   focusMS()
  //   processButton()
  // })
  //
  // if (minimap.frame.buttons.off) minimap.frame.buttons.off.destroy();
  // minimap.frame.buttons.off = statusBarWrapper.scene.make.image({
  //   x: x - getPercentVal(24, 36),
  //   y: y + getPercentVal(52, 78),
  //   key: 'but_off',
  //   add: true
  // });
  // initButton(minimap.frame.buttons.off, function () {
  //   focusMS()
  //   processButton()
  // })

  if (minimap.frame.buttons.battle_view) minimap.frame.buttons.battle_view.destroy();
  minimap.frame.buttons.battle_view = statusBarWrapper.scene.make.image({
    x: x - getPercentVal(24, 36),
    y: y + getPercentVal(52, 78),
    key: "radar",
    frame: 'but_battle_view',
    add: true
  });
  initButton(minimap.frame.buttons.battle_view, function () {
    lazyCamera()
    processButton()
  })

  processButton()
  updateTimeState(percent)
  updateMiniMap(true);
}

function updateTimeState(percent) {
  function getPercentVal(min, max) {
    return min + (percent / 100 * (max - min))
  }

  state.offsetX = getPercentVal(-78, -117)
  state.offsetY = getPercentVal(18, 17)
  state.scale = getPercentVal(0.3, 0.45)
  state.backScale = getPercentVal(1, 1.55)
  state.heightText = getPercentVal(13, 19)
  state.backOffsetX = getPercentVal(3, 4.5)
  state.bracketOffsetX = getPercentVal(10, 15)
  state.textOffset = getPercentVal(3, 4.5)
}

function processButton() {
  let settings = store.getters.getSettings

  // minimap.frame.buttons.on.setVisible(settings.FollowCamera)
  // minimap.frame.buttons.off.setVisible(!settings.FollowCamera)

  minimap.frame.buttons.battle_view.setVisible(settings.FollowCamera)
  if (settings.LazyCamera) {
    minimap.frame.buttons.battle_view.setTint(0x55ff55)
  } else {
    minimap.frame.buttons.battle_view.setTint(0xff5555)
  }
}

function focusMS() {
  playSound('button_press.mp3', 0.3)

  gameStore.gameSettings.follow_camera = !gameStore.gameSettings.follow_camera
  store.dispatch('changeSettings', {
    name: "FollowCamera",
    count: gameStore.gameSettings.follow_camera
  });

  store.commit({
    type: 'setFollowCamera',
    follow: gameStore.gameSettings.follow_camera,
  });

  if (gameStore.gameSettings.follow_camera) {
    FocusMS();
  } else {
    Scene.cameras.main.stopFollow();
  }
}

function lazyCamera() {
  playSound('button_press.mp3', 0.3)

  gameStore.gameSettings.lazy_camera = !gameStore.gameSettings.lazy_camera
  store.dispatch('changeSettings', {
    name: "LazyCamera",
    count: gameStore.gameSettings.lazy_camera
  });

  store.commit({
    type: 'setLazyCamera',
    lazy_camera: gameStore.gameSettings.lazy_camera,
  });

  FocusMS();
}

function mouseZoomPress(size) {
  playSound('button_press.mp3', 0.3)

  minimap.frame.buttons.zoomChange = true;
  let zoomer = setInterval(function () {
    if (minimap.frame.buttons.zoomChange) {
      zoom(size)
    } else {
      clearInterval(zoomer);
    }
  });
}

let zoomMinMax = {
  min: 0.8,
  max: 2,
  k: 1,
}

function zoom(size) {
  let zoom = Scene.cameras.main.zoom + (size * Scene.cameras.main.zoom)

  let max = zoomMinMax.max * zoomMinMax.k
  let min = zoomMinMax.min * zoomMinMax.k

  if (zoom < min) {
    zoom = min
  } else if (zoom > max) {
    zoom = max
  }

  Scene.tweens.add({
    targets: Scene.cameras.main,
    zoom: zoom,
    duration: 256,
    onComplete: function () {
      scaleBars()
    }
  });

  store.dispatch('changeSettings', {
    name: "ZoomCamera",
    count: zoom * 100
  });

  store.commit({
    type: 'setZoomCamera',
    zoom: zoom,
  });
}

function SetMinMaxZoom(k) {
  if (k > 1) {
    k = 1 + ((k - 1) / 2);
  }

  zoomMinMax.k = k
}

function scaleBars() {
  for (let i in gameStore.objects) {
    if (!gameStore.objects[i]) continue;
    createObjectBars(i)
  }

  for (let i in gameStore.reservoirs) {
    let b = getBar('reservoir', gameStore.reservoirs[i].id, 'progress');
    if (b && b.bar) {
      b.bar.setScale(0.5 * getBarScale())
    }
  }

  for (let i in gameStore.units) {
    unitProcessLabels(gameStore.units[i])
    PosUpdateUnitHP(gameStore.units[i])
  }

  UpdateShieldPos(true)
  if (reservoirStatus.texture) reservoirStatus.texture.setScale(0.4 * getBarScale())
  if (selector) selector.setDisplaySize(32 + (48 * getBarScale() / 2), 32 + (48 * getBarScale() / 2));

  ClientWatch()
}

export {drawFrameAndBackground, scaleBars, zoom, SetMinMaxZoom, zoomMinMax}
